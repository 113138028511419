.popup-body img{
    margin:0;
    max-width:100%;
    max-height:300px;
}

.cropper-view-box{
    border-radius:50%;
}

.cropper-face{
    background-color:transparent;
}

.cropper-view-box{
    outline:none;
}

.popup-body .cropper-line{
    opacity: 0.01;
}

.cropper-point:not(.point-ne){
    display:none;
}

.point-ne{
    width:20px;
    height:20px;
    opacity:1;
} 