@import '../variables';

.is-hidden {
  display: none !important;
}
/* access group */
/* alert group */
.is-warrning {
  color: #db0011 !important;
}
/* alert group */

.is-bold {
  font-weight: bold;
}

/* color green */
.is-green {
  color: $green;
}
.is-greey{
  color: #616161;
}
.is-red {
  color: $icon-red !important;
  transition: 0.5s;

  &.pointer:hover {
    color: $nav-red !important;
  }
}

.is-yellow {
  color: $yellow;
}

a.is-red {
  &:hover {
    color: $light-red;
  }
}
.is-blue{
  color: #1152C6;
}
// input error
.has-error {
  border: 1px solid $light-red !important;
}

// background colors
.green-bg {
  background: $green;
}

.red-bg {
  background: $nav-red;
}

.white-bg {
  background: $white;
}
