// Slider effect
.slider-overflow {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: $borderRadius;
  margin-bottom: 40px;
  min-height: 210px;
}

.lb-slider-block {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.sl-right-enter {
  transform: translateX(100%);
  // margin-left: 100%;
  transition: all 500ms ease-in-out;

  & + .sl-left-exit {
    transform: translateX(-100%);
    // margin-left: -100%;
    transition: all 500ms ease-in-out;
  }
}

.sl-left-enter {
  transform: translateX(-100%);
  // margin-left: -100%;
  transition: all 500ms ease-in-out;

  & + .sl-right-exit {
    transform: translateX(100%);
    // margin-left: 100%;
    transition: all 500ms ease-in-out;
  }
}

.sl-left-enter-active,
.sl-right-enter-active {
  transform: translateX(0);
  // margin-left: 0;
  transition: all 500ms ease-in-out;
}

.sl-right-exit {
  transform: translateX(-100%);
  // margin-left: -100%;
  transition: all 500ms ease-in-out;
}

.sl-left-exit {
  // margin-left: 100%;
  transform: translateX(100%);
  transition: all 500ms ease-in-out;
}
// Slider effect end

/* ========== loading ========== */

// old
.loader-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-ring {
  position: relative;
  margin: 1rem auto;
  width: 3rem;
  height: 3rem;
}

.loader-ring-light {
  width: 3rem;
  height: 3rem;
  -moz-border-radius: 3rem;
  -webkit-border-radius: 3rem;
  border-radius: 3rem;
  -moz-box-shadow: 0 4px 0 $light-red inset;
  -webkit-box-shadow: 0 4px 0 $light-red inset;
  box-shadow: 0 4px 0 $light-red inset;
  animation: rotate-360 2s linear infinite;
}

.loader-ring-track {
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  -moz-border-radius: 3rem;
  -webkit-border-radius: 3rem;
  border-radius: 3rem;
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3) inset;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3) inset;
}

@keyframes rotate-360 {
  from {
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
//   old end

//  Main Loader

// use if need a space for loading
.loader-container {
  min-height: 200px;
  width: 100%;
  position: relative;
}

.loader-container-min {
  min-height: 60px;
  width: 100%;
  position: relative;
  margin-top: 20px;
}

// on dashboard
.lb-dashboard {
  .loader-container {
    min-height: 50px;
  }
}

.loader-out {
  &.full-size {
    position: absolute;
    top: 0;
    z-index: 3;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
  }

  &.fixed {
    position: fixed;
    top: 116px;
  }

  &.decimal-loader {
    height: 50px !important;
  }
}

.main-loader {
  width: 100px;
  height: 100px;
  position: relative;
}

.main-loader .dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.31, 1) infinite;
}

.main-loader .dot:nth-child(1) {
  animation-delay: 0.2s;
}

.main-loader .dot:nth-child(2) {
  animation-delay: 0.35s;
}

.main-loader .dot:nth-child(3) {
  animation-delay: 0.45s;
}

.main-loader .dot:nth-child(4) {
  animation-delay: 0.55s;
}

.main-loader .dot:after,
.main-loader .dot .first {
  content: '';
  position: absolute;
  width: 10%;
  height: 10%;
  background: $light-red;
  border-radius: 50%;
  left: 50%;
  margin-left: -5%;
}

.main-loader .dot .first {
  background: $light-red;
  margin-top: -5%;
  animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.31, 1) infinite;
  animation-delay: 0.2s;
}

@keyframes dotrotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes dotscale {
  0%,
  10% {
    width: 20%;
    height: 20%;
    margin-left: -10%;
    margin-top: -5%;
  }
  50% {
    width: 10%;
    height: 10%;
    margin-left: -5%;
    margin-top: 0;
  }
  90%,
  100% {
    width: 20%;
    height: 20%;
    margin-left: -10%;
    margin-top: -5%;
  }
}
//  Main Loader End

.loader {
  width: 100px;
  height: 100px;
  margin: 0;
  background: transparent;
  border-top: 4px solid $light-red;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;

  &.loader-xxs {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }

  &.loader-xs {
    width: 30px;
    height: 30px;
    border-width: 2px;
  }
  &.loader-xsm {
    width: 40px;
    height: 40px;
    border-width: 3px;
  }

  &.loader-sm {
    width: 60px;
    height: 60px;
    border-width: 3px;
  }
}

// loader colors
.loader-out {
  &.loader-white .loader {
    border-top: 4px solid $white;

    &.loader-xxs {
      border-top: 2px solid $white;
    }

    &.loader-xs {
      border-top: 2px solid $white;
    }

    &.loader-xsm {
      border-top: 3px solid $white;
    }

    &.loader-sm {
      border-top: 3px solid $white;
    }
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.LoadingMask {
  .loader {
    margin: auto;
  }
}
.error-box {
  @include fontFamily('Liberty-Nus Medium');
  @include fontSize(14px);
  line-height: 1.2rem;

  .icn.icn-warning {
    color: $icon-red;
    margin-right: 15px;
    width: 25px;
    height: 22px;
  }

  .error-icon {
    padding-top: 5px;
    color: $white;
    text-align: left;
    display: flex;
    align-items: center;

    .icn.icn-warning {
      color: $white;
      margin-right: 15px;
      width: 25px;
      height: 22px;
    }
  }
  .error-currency {
    @include fontSize(12px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #db0011;
    padding: 15px;
    padding: 0 5px 0 15px;
    min-height: 20vh;
    .icn.icn-warning {
      margin-top: 4px;

      svg {
        width: 23px;
        height: 23px;
      }
    }
  }

  .error-accoount {
    padding: 30px 40px;
    display: flex;
    align-items: center;
    color: #db0011;
  }

  .error-lg {
    padding: 40px 25px;
    display: flex;
    align-items: center;
    color: #db0011;
    justify-content: center;
    min-height: 20vh;
    height: 20vh;
  }

  .error-md {
    display: flex;
    align-items: center;
    color: #db0011;
    justify-content: flex-start;
    margin-top: -25px;
    .icn.icn-warning {
      color: #db0011;
      margin-right: 5px;
      width: 19px;
      height: 22px;
    }
  }

  .error-modal {
    padding: 40px;
    display: inline-block;
    align-items: center;
    color: #db0011;
    justify-content: center;
    min-height: 20vh;
    height: 20vh;
    text-align: center;
    width: 100%;

    .icn.icn-warning {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }

  .error-quick {
    padding: 10px 0;
    display: flex;
    align-items: center;
    color: #db0011;
    justify-content: center;
    @include fontSize(13px);

    svg {
      width: 23px;
      height: 23px;
    }

    .icn.icn-warning {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .error-sm {
    @include fontSize(12px);
    display: flex;
    align-items: flex-start;
    color: $white;
    padding-top: 10px;
    .icn.icn-warning {
      margin-top: 4px;
      color: $white;
      svg {
        width: 23px;
        height: 23px;
      }
    }
  }

  .error-DecimalInput {
    @include fontSize(13px);
    width: 85%;
    display: flex;
    align-items: center;
    text-align: left;
    color: #db0011;
    padding-top: 10px;
    //margin-left: 56px;
    .icn.icn-warning {
      //color: $white;
      margin-left: 0;
      margin-right: 5px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.transfers-block.active {
  .error-quick {
    padding: 10px 0;
    display: flex;
    align-items: center;
    color: $white;
    position: absolute;
    left: 0;
    top: 55px;
    transform: translate(0, 0);
    text-align: left;

    svg {
      width: 23px;
      height: 23px;
    }

    .icn.icn-warning {
      color: $white;
    }
  }
}

@keyframes circle-loader-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
// Secondary loader end
/* ========== loading end ========== */

/* ========== liberty block's modules ========== */
.lb-card {
  background-color: $white;
  box-shadow: 0px 2px 6px #00000014;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  padding: 48px;
}

.lb-card-block {
  .lb-block-body {
    padding: 32px 0;
  }

  .lb-loan-pdf {
    padding: 25px 32px 0;
  }

  .lb-card-details {
    max-width: 695px;
    min-height: 40vh;
    margin: 0 auto;
    box-shadow: 0px 2px 6px #00000014;
    border: 1px solid #e6e8eb;
    border-radius: 8px;

    .agree-rules {
      position: relative;
      background-color: #eff1f4;
      border: none;
      font-size: 0.6875rem;
      padding: 18px 24px;
      border-radius: 12px;
    }

    .icn-close {
      position: absolute;
      right: 16px;
      top: 16px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
    .icn-tick {
      width: 40px;
      height: 40px;
    }
    .lb-lbslider-block {
      margin-top: 23px;
    }

    .lb-form-group {
      width: 58%;

      .lb-select {
        height: 48px;
      }

      .lb-label {
        @include fontFamily('LIberty-Nus Medium');
      }
    }

    .lb-form-group .lb-input-err {
      display: block !important;
    }
  }

  .lb-foot-form {
    padding: 0 32px 32px !important;
  }
}

.lb-card-info {
  display: flex;
  align-items: center;

  .lb-image-out {
    height: 40px;
    width: 65px;
    margin-right: 1rem;
    position: relative;

    // if two cards
    &.lb-image-2 {
      .lb-card-image {
        position: absolute;

        &:first-child {
          top: -5px;
          left: -5px;
        }

        &:last-child {
          top: 5px;
          left: 5px;
        }
      }
    }

    // if three or more cards
    // &.lb-image-3{
    //     .lb-card-image{
    //         position: absolute;

    //         &:first-child{
    //             top: -5px;
    //             left: -5px;
    //         }

    //         &:nth-child(2){
    //             top: 0px;
    //             left: 0px;
    //         }

    //         &:nth-child(3){
    //             top: 5px;
    //             left: 5px;
    //         }
    //     }
    // }
  }

  .lb-card-image {
    width: 100%;
    height: 100%;
    // border-radius: 6px;
    // background: $icon-red;
    display: flex;
    align-items: center;
    justify-content: center;

    .lb-card-img {
      max-width: 60%;
      max-height: 55%;
    }
  }

  .lb-card-image + div {
    width: calc(100% - 1rem - 65px);
  }

  i.lb-card-image {
    color: #fff;

    svg {
      height: 1.125rem;
      max-width: 1.5rem;
    }
  }
}

.lb-block-subhead.block-pointer {
  & > :last-child {
    padding-right: 35px;
  }
}

.lb-block .lb-block-subhead .w-100 {
  padding-right: 0;
}

.lb-block-row {
  &.lb-blocked-row {
    cursor: default;
  }

  .lb-block-inrow {
    display: flex;
    align-items: center;
  }
  &.pointer-default {
    cursor: default;

    & > :nth-last-child(2) {
      padding-right: 35px;
    }

    .icn-arr-right:last-child {
      position: absolute;
      top: 50%;
      right: 1.875rem;
      transform: translateY(-50%);
      text-align: right;
      height: 13px;
      width: 9px;
    }
  }
  &.block-pointer {
    cursor: pointer;

    & > :nth-last-child(2) {
      padding-right: 35px;
    }

    .icn-arr-right:last-child {
      position: absolute;
      top: 50%;
      right: 1.875rem;
      transform: translateY(-50%);
      text-align: right;
      height: 13px;
      width: 9px;
    }
  }

  & > .icn-tick {
    text-align: left;

    svg {
      height: 10px;
      width: 14px;
      margin-left: 7%;
    }

    .tooltip-parent {
      margin-left: 7%;
    }
  }

  & > .icn-circle-tick {
    text-align: left;

    svg {
      height: 20px;
      width: 20px;
      margin-left: 7%;
    }

    .tooltip-parent {
      margin-left: 7%;
    }
  }

  & > .icn-circle-close {
    text-align: left;

    svg {
      height: 20px;
      width: 20px;
      margin-left: 7%;
    }

    .tooltip-parent {
      margin-left: 7%;
    }
  }

  & > .icn-circle-clock {
    text-align: left;

    svg {
      height: 20px;
      width: 20px;
      margin-left: 7%;
    }

    .tooltip-parent {
      margin-left: 7%;
    }
  }

  & > .icn-clock {
    text-align: left;

    svg {
      height: 16px;
      width: 16px;
      margin-left: 7%;
    }
  }

  .icn-circle-clock {
    text-align: left;

    svg {
      height: 20px;
      width: 20px;
      margin-left: 7%;
    }

    .tooltip-parent {
      margin-left: 7%;
    }
  }

  // default values
  .is-name {
    // friendlyname
    @include fontSize(15px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  .is-subname {
    @include fontSize(12px);
    @include fontFamily('Liberty-Nus');
    color: $txt-light-grey;
  }

  .is-iban {
    //@include fontSize(13px);
    font-size: 13px;
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  .is-amount {
    @include fontSize(17px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  .is-amount-sm {
    @include fontSize(15px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  .is-amount-root {
    @include fontSize(15px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  // month and payments quantity
  .is-info {
    @include fontSize(15px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  .is-subamount {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $medium-grey;
  }

  .is-date {
    // format 11/11/2018
    @include fontSize(13px);
    @include fontFamily('Liberty-Nus Medium');
    color: $medium-grey;
  }

  .is-subdate {
    // format 11/11/2018
    @include fontSize(13px);
    @include fontFamily('Liberty-Nus Medium');
    color: $medium-grey;
  }

  .is-longdate {
    // 23 მაისი 2018
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  .is-description {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }
  .is-category {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }
  .is-amount-n {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }
  .is-cardtype {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }
  .is-reason {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;
  }

  .is-admin {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus bold');
    color: $basic-grey;
  }

  .is-simple {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: #555c63;
  }

  .is-blocked {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $simple-red;
  }

  .is-deleted {
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus Medium');
    color: $delet-color;
  }

  .is-green {
    color: $new-green !important;
  }
}

.rows-wrap {
  padding: 1.5625rem 1.875rem;

  & + .lb-block-subhead {
    border-top: 1px solid $border-grey-2;
  }
}

.lb-show {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lb-show span {
  color: #74777a;
}

.lb-show .is-red {
  @include fontFamily('Liberty-MT Bold');
}

.lb-show div {
  cursor: pointer;
  margin: 0 0 0 15px;
  position: relative;
  width: 16px;

  &:hover {
    color: $light-red;
  }
}

.lb-show .is-red:after {
  content: '';
  position: absolute;
  width: 14px;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 18px);
  height: 2px;
  background: $light-red;
}

.lb-block .lb-block-subhead + .lb-block-subhead {
  border-top: 0;
}

.lb-block-title {
  @include fontSize(16px);
  line-height: normal;
  @include fontFamily('Liberty-MT Bold');
  min-height: 25px;

  .fa-pencil-alt {
    opacity: 0;
    transition: 0.2s;
  }

  &:hover .fa-pencil-alt {
    opacity: 1;
  }
}

.lb-block-mixed .lb-block-title.block-title-pointer {
  cursor: pointer;
}

.lb-block-row {
  .lb-image-circle {
    height: 36px;
    width: 36px;
    background: $icon-red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    svg {
      height: 1.125rem;
      max-width: 1.5rem;
    }
  }
}

// page products - list page
.product-list .lb-block-row {
  padding: 1.5625rem 1.875rem;
}
// page products - list page end

// if no rows are comming
.lb-block .lb-block-row.no-info {
  padding: 2rem 25px;
  justify-content: center;
}

.lb-block-expand .fnt-13 {
  color: $basic-grey;
}

.lb-block-expand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 25px 0 30px;
  cursor: pointer;

  .icn.icn-wallet2 {
    width: 26px;
    height: 21px;
  }

  .icn.icn-credits {
    width: 30px;
    height: 30px;
  }

  .icn.icn-loans {
    width: 32px;
  }
  .icn.icn-deposit {
    width: 25px;
    height: 25px;
  }

  .icn.icn-warnings-red {
    width: 17px;
  }
}

// --------------- ROW STYLES --------------- //

// default styles of detail pages - 2 columns
.lb-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :first-child {
    width: 75%;
    padding-right: 10px;
  }

  & > :last-child {
    width: 25%;
    text-align: right;
  }

  .is-card-info {
    @include fontSize(14px);

    @include fontFamily('Liberty-Nus');
    color: $basic-grey;
  }
}

// default styles of loan prepayment pages - 2 columns
.half-row {
  display: flex;
  justify-content: space-between;

  & > :first-child {
    width: 50%;
    padding-right: 10px;
  }

  & > :last-child {
    width: 50%;
    text-align: right;
  }

  .is-card-info {
    @include fontSize(14px);

    @include fontFamily('Liberty-Nus');
    color: $basic-grey;
  }
}

.checkbox-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.875rem;

  &:first-child {
    margin-top: 0.625rem;
  }

  &:last-child {
    margin-bottom: 0.625rem;
  }
}

// --------------- ROW STYLES END --------------- //

/* ========== END liberty block's modules ========== */

/* ========== Header top elements ========== */
.lb-header-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding-right: 0.6875rem;
  // white-space: nowrap;
  @include fontSize(12px);
  @include fontFamily('Liberty-MT Medium');
  // border-right: 1px solid #464E57;

  &:after {
    content: '';
    display: inline-block;
    height: 50%;
    width: 1px;
    background: #464e57;
    margin-left: 0.6875rem;
  }

  // span{
  //     white-space: nowrap;
  // }

  .icn-arrow-down {
    color: $icon-grey;
    height: 7px;
    width: 10px;
  }

  .lb-sub-navbar {
    min-width: 170px;
    box-shadow: none;
    background: #f6f6f6;
    border: 1px solid $nav-border;
    padding: 0.5rem 0.3rem;
    left: 13px;
    @include fontSize(13px);
    @include fontFamily('Liberty-MT');
  }

  &:hover .lb-sub-navbar {
    opacity: 1;
    visibility: visible;
  }

  .lb-subnav-item {
    display: block;
    color: $black;
    border-left: 1px solid $white;
    transition: 0.3s;
    @include animatedBorder(1px);

    &:not(.disabled):hover {
      background: transparent;
      color: $nav-red;
      border-left: 1px solid $nav-red;
    }

    &.is-notify {
      background: transparent;
      color: $nav-red;
      border-left: 1px solid $nav-red;
    }
  }
  .new-wrapper {
    display: inline-block;
    position: relative;
    .newnotif-block {
      top: -16px;
      right: -21px;
    }
  }
}

.lb-head-avatar {
  @include iconSize(20px);
  margin: 0 0.625rem;
  border-radius: 50%;
  color: #ffffff;
  transition: 0.5s;
}

.head-avatar-circle {
  width: 21px;
  height: 21px;
  color: #fff;
  border-radius: 50%;
  //border: 1px solid #ffffff;
  background: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 13px;
    color: #000;
  }
}
.lb-head-tips {
  padding: 0 1.25rem 0 1.5625rem;
  border-left: 1px solid #464e57;
  line-height: 1rem;
  display: flex;
  align-items: center;

  i {
    color: $icon-grey;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $light-red;
    }
  }

  .icn-mail {
    height: 0.75rem;
    position: relative;
  }

  i:not(:last-child) {
    margin: 0 1.125rem 0 0;
  }

  .mail-counter {
    position: absolute;
    bottom: 55%;
    left: 55%;
    border-radius: 50%;
    @include iconSize(16px);
    @include fontSize(10px);
    text-align: center;
    line-height: 1rem;
    background: #ca2827;
    color: $white;
  }
}

.lb-faq {
  height: 100%;
  padding-right: 0.6875rem;
  display: flex;
  align-items: center;
  color: #b2afb2;
  cursor: pointer;
  transition: 0.3s;

  &:after {
    content: '';
    display: inline-block;
    height: 50%;
    width: 1px;
    background: #464e57;
    margin-left: 0.6875rem;
  }

  .icn-question {
    transition: 0.3s;
    width: 20px;
    height: 20px;

    &:hover {
      color: $white;
    }
  }
}

.lb-logout {
  @include fontFamily('Liberty-MT Medium');
  @include fontSize(12px);
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
  color: #b2afb2;

  .icn-exit {
    height: 1.25rem;
    width: 1.25rem;
    color: #b2afb2;
    margin-right: 0.5rem;
    transition: 0.3s;
  }

  &:hover {
    color: $white;
    .icn-exit {
      color: $white;
    }
  }
}
/* ========== END of Header top elements ========== */

/* ========== Header bottom elements ========== */
.lb-bottom-header .lb-subnav-item,
.lb-container .lb-subnav-item {
  padding: 0 0.3125rem;
  height: 100%;
  margin-right: 0.9375rem;
  position: relative;
  display: flex;
  align-items: center;
  @include fontSize(14px);

  @include fontFamily('Liberty-MT Medium');

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    transition: 0.2s;
  }

  &:hover {
    background: transparent;
    color: $nav-red;
  }

  &.active {
    color: $nav-red;

    @include fontFamily('Liberty-MT Medium');

    &:after {
      background: $nav-red;
    }
  }
}

/* ========== END of Header bottom elements ========== */

/* ========== Logos ========== */
.lb-logo-main {
  margin: 0 2.8125rem 0 0;
  font-size: 0;
  cursor: pointer;
  // width: 107px; old logo width
  width: 163px;
}
/* ========== END of Logos ========== */

/* ========== navigation ========== */
.lb-navbar ul li {
  display: inline-block;
  margin-left: 30px;
  text-transform: uppercase;
}

.App.ka .lb-navbar ul li {
  text-transform: lowercase;
}

// main navigation
.lb-navbar {
  display: flex;

  .icn-dropdown {
    color: inherit;
    width: 10px;
    height: 5px;
    margin: -1px 0 0 0.85rem;
  }

  .lb-subnav-item {
    font-size: 0.85rem;
  }

  .lb-sub-navbar {
    top: calc(100% - 1rem);
    padding-top: 0.625rem;
  }

  .lb-navbar-item {
    &:hover {
      .lb-sub-navbar:after {
        transition: 0.15s ease-out 0.15s;
        transform: scale(1, 1);
      }
    }
  }

  .lb-subnav-item {
    @include animatedBorder(0px);

    &:not(.disabled):hover {
      color: $nav-red;
    }

    &:after {
      left: 0;
    }
  }

  .lb-subnav-item.disabled {
    opacity: 0.4 !important;

    &:after {
      content: none;
    }
  }
}
// main navigation end

.lb-header-in .lb-navbar {
  height: 100%;
}

.lb-navbar-item {
  position: relative;
  padding: 0 1.25rem;

  &:hover {
    .nav-item {
      color: $nav-red;
    }
  }

  .nav-item.active {
    color: $nav-red;
  }

  .lb-sub-navbar.navbar-show {
    opacity: 1;
    visibility: visible;
  }

  .nav-item {
    @include fontFamily('Liberty-MT Medium');
    @include fontSize(16px);
    // color: $basic-grey;
    color: #000;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    transition: 0.2s;
    position: relative;

    &.active {
      color: $nav-red;
    }
  }
}
.newnotif-block {
  position: absolute;
  top: 11px;
  right: 20px;
  z-index: 2;
  .add-new {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(11px);
    color: $nav-red;
    border: 1px solid #242424;
    border-radius: 3px;
    line-height: 10px;
    padding: 2px 4px 0;
  }

  .icn {
    height: 5px;
    position: absolute;
    bottom: -5px;
    left: 3px;
  }
}

.lb-sub-navbar {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  position: absolute;
  z-index: 1030;
  top: 100%;
  left: 15px;
  min-width: 230px;
  // padding: 0.625rem 0;
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 -15px 0 #ffffff, 0 0 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.lb-subnav-wrap:not(:last-child) {
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 0.625rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background: $border-grey-2;
  }
}

.lb-subnav-item {
  white-space: nowrap;
  padding: 0.625rem 2.2rem 0.625rem 1.25rem;
  color: #000;
  @include fontFamily('Liberty-MT');
  cursor: pointer;
}

.newnotif-block {
  position: absolute;
  top: 11px;
  right: 20px;
  z-index: 2;
  .add-new {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(11px);
    color: $nav-red;
    border: 1px solid #242424;
    border-radius: 3px;
    line-height: 10px;
    padding: 2px 4px 0;
  }

  .icn {
    height: 5px;
    position: absolute;
    bottom: -5px;
    left: 3px;
  }
}
/* ========== END of navigation ========== */

/* ========== END header exchange ========== */
.lb-exchange {
  margin: 0 0 0 auto;
  cursor: pointer;

  .icn-exchange {
    display: block;
    height: 26px;
    width: 46px;
    position: relative;

    svg {
      transition: 0.3s;
    }

    svg:last-child {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      svg:first-child {
        opacity: 0;
      }

      svg:last-child {
        opacity: 1;
      }
    }
  }
}
/* ========== END of header exchange ========== */

/* =========== icon modules ========== */
.icn {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  font-size: 0;
  align-items: center;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

// sprite icons
.icn-download {
  color: $basic-grey;
}
.icn-warning {
  color: $basic-grey;
}
.icn-warning:hover {
  color: $icon-red;
}
.icn-warning.icn-red {
  color: $icon-red;
}
.icn-exchange {
  color: $nav-red;
}
.icn-arrow-down {
  color: $darker-grey;
}
.icn-arr-right {
  color: $darker-grey;
}
.icn-eye {
  color: $eye-icon-grey;
}
.icn-eye-slash {
  color: $eye-icon-grey;
}
.icn-info {
  color: $dark;
  border-radius: 50%;
  border: 1px solid currentColor;
  font-size: 0.8rem;
  @include fontFamily('Liberty-Nus Bold');
  display: flex;
  align-items: center;
  justify-content: center;
}

// position correction
span + .icn-warning {
  position: relative;
  //top: -0.05rem;
  top: 0;
}

.deposit-date {
  overflow: visible !important;
  .icn-expires {
    position: absolute;
    top: 2px;
    right: -13px;
  }
}

.inOverdue {
  .icn-warning {
    color: #db0011;
  }
}
// warning classes
.icn-warning.warn-lg {
  height: 1.3rem;
  width: 1.3rem;
  margin-left: 1rem;
}

.icn.icn-red {
  color: $icon-red;
}

.icn-arr-right.arrow-left {
  transform: rotate(180deg);
}

.icn.icn-round {
  border-radius: 50%;
  border: 3px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finalize-block {
  margin-bottom: 0;
  width: 100%;
  height: 187px;
  // box-shadow: 0px -39px 128px -45px rgba(49, 49, 49, 0.22) inset;
  // -webkit-box-shadow: 0px -39px 128px -45px rgba(49, 49, 49, 0.22) inset;
  // -moz-box-shadow: 0px -39px 128px -45px rgba(49, 49, 49, 0.22) inset;
  .is-warrning {
    a {
      color: $icon-red;
      text-decoration: underline;
      &:hover {
        color: $bg-exchange;
      }
    }
  }
  .icon-notfoundaccount {
    width: 51px;
    height: 37px;
  }

  .icn-notfoundloan {
    width: 45px;
    height: 48px;
  }

  .icon-notfounddeposit {
    width: 51px;
    height: 37px;
  }

  .icon-notfoundcredit {
    width: 51px;
    height: 37px;
  }

  .icn-clock {
    width: 44px;
    height: 44px;
  }

  &.lb-check-template {
    box-shadow: none;
    padding: 69px 69px 69px 0;
    height: 187px;
  }
}

.finalize-block .icn.icn-round {
  height: 44px;
  width: 44px;

  svg {
    width: 45%;
    height: 45%;
  }
}

.correct-message {
  background: #fff;
  padding: 63px 0;
  text-align: center;
  color: #626a71;
  .icn.cash-error {
    width: 53px;
    height: 53px;
    color: #858b91;
  }
}

// icon in link
a .icn-arrow-left {
  position: relative;
  top: 0.05rem;
  margin-right: 10px;
  height: 10px;
  width: 13px;
}

.icn-cog {
  opacity: 0;
  transition: 0.2s;
}

.lb-block-head:hover {
  .icn-cog {
    opacity: 1;
  }
}

// icon with one width in rows + centered
.icn.icn-18 {
  height: 18px;
  width: 18px;
}
.icn.icn-20 {
  height: 20px;
  width: 20px;
}

.icn.icn-w18 {
  width: 18px;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;

  &.icn-phone svg,
  &.icn-lamp svg {
    width: 12px;
  }
}

.icn.icn-w25 {
  width: 25px;
  height: 2rem;
  line-height: 2.2rem;
  text-align: center;

  &.icn-lock svg {
    width: 16px;
  }
}

.icn.icn-15 {
  width: 15px;
  height: 15px;
}

.icn.icn-13 {
  width: 13px;
  height: 13px;
}

.icn.icn-12 {
  width: 12px;
  height: 12px;
}

// change friendly name
.change-name-out {
  height: 3.125rem;

  .lb-btn.btn-red {
    background: $nav-red;
    height: 2.5rem;
    margin-left: 0.625rem;
  }

  .lb-input {
    line-height: 50px;
    height: 2.5rem;
  }

  .hidden-input {
    @include fontSize(16px);
    @include fontFamily('liberty-MT Bold');
  }

  .credit-limit {
    @include fontSize(14px);
    margin-top: -6px;
  }

  @media screen and (max-width: 1280px) {
    .credit-limit {
      @include fontSize(13px);
    }
  }
}

.btn-transfer {
  height: 50px !important;
}

.change-name {
  .icn-pen {
    background: #e3e3e3;
    border-radius: 50%;
    color: $icn-dark;
    width: 24px;
    height: 24px;
    position: relative;
    top: 0.07rem;
    opacity: 0;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $white;
    }

    svg {
      width: 22px;
      height: auto;
      padding-top: 4px;
      padding-left: 7px;
    }
  }

  &:hover .icn-pen {
    opacity: 1;
    background: $icn-dark;

    svg {
      color: $white;
    }
  }
}

.lb-btn .icn-print {
  height: 1rem;
  width: 17px;
  color: $basic-grey;
  position: relative;
  top: 2px;
}

/* icon sizes */
.icn.icn-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.icn.icn-md {
  width: 2rem;
  height: 2rem;
}

.icn.icn-lg {
  width: 4rem;
  height: 4rem;
}
/* icon sizes END */
/* =========== END of icon modules ========== */

/* =========== button modules ========== */
.lb-btn {
  display: inline-flex;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0 1.56rem;
  height: 45px;
  min-width: 4.75rem;
  text-align: center;
  font-size: 15px;

  @include fontFamily('Liberty-MT Medium');
  border-radius: $borderRadius;
  //transition: color 0.5s, background 0.5s;
  transition: 0.3s;

  & + .lb-btn {
    margin-left: 15px;
  }

  &:disabled {
    background: #e7e7e9 !important;
    color: #fff !important;
    cursor: default;
    border: none !important;
  }
}

.lb-btn .icn.icn-arrow-left {
  margin-right: 10px;
}
.lb-btn .icn.icn-arrow-right {
  margin-left: 10px;
}
.lb-btn.btn-glassy {
  border: 1px solid #1d262f;
}
.lb-btn.btn-full {
  width: 100%;
}
.lb-btn.btn-dark {
  color: #fff;
  background-color: #1d262f;
  @include fontSize(15px);

  &:hover {
    background: $hover-dark;
  }
}

.lb-btn.btn-confirm {
  width: 90px;
}
.trust-worthy {
  .lb-confirm-text {
    padding-bottom: 7px;
  }
  .lb-btn.btn-confirm {
    width: auto;
  }
}
.lb-btn.btn-md {
  padding: 1rem 2.1875rem;
}

.lb-btn.btn-border {
  border: 1px solid #646464;
  @include fontSize(15px);

  i {
    transition: 0.3s;
  }

  &:not(.btn-link):hover {
    background: $nav-red;
    border-color: transparent;
    color: $white !important;

    i {
      color: $white;
    }
    .icn-corp-filter {
      svg {
        color: $white;
      }
    }
  }
}
.lb-btn.btn-border-grey {
  border: 1px solid $border-grey-3;
  transition: 0.3s !important;

  &:hover {
    color: #fff;
    border: 1px solid transparent;
    background: $dark;
  }
}
.lb-btn.btn-white {
  background-color: #fff;
  color: $black;
  // padding: 1rem 1.5rem;
}

.btn-download {
  display: inline-flex;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  padding: 0 0.875rem;
  height: 35px;
  min-width: 4.75rem;
  text-align: center;
  border: 1px solid #646464;
  background-color: $white;
  border-radius: $borderRadius;
  transition: 0.3s;
  @include fontSize(13px);
  @include fontFamily('LIberty-Nus Medium');

  &:hover {
    color: $nav-red;
  }
}
.lb-btn.btn-border-2 {
  border-width: 2px;
}

.lb-btn.btn-red {
  background: $icon-red;
  color: #fff;
  border: 1px solid #db0011;
  @include fontFamily('Liberty-MT Medium');
  @include fontSize(15px);
  transition: 0.3s;

  &:not(.btn-link):hover {
    background: #cc0111;
  }
}

.lb-btn.btn-black {
  background: $black;
  color: #fff;
  border: 1px solid $black;
  @include fontFamily('Liberty-MT Medium');
  @include fontSize(15px);
  transition: 0.3s;

  &:not(.btn-link):hover {
    background: $black;
  }
}

.lb-btn.btn-black-soft {
  background-color: $black;
  color: #fff;
  @include fontSize(14px);
  letter-spacing: 0.28px;
  line-height: 16px;
  padding: 8px 16px 9px 16px;
  border: none;
  height: auto;
}

.btn-add {
  @include fontFamily('Liberty-MT Medium');
  font-size: 12px !important;
  height: 30px;
  padding: 0 16px;

  svg {
    width: 8px !important;
    height: 8px !important;
  }
}
.lb-btn.btn-red:disabled {
  // color: #C6C9CB !important;
  // background: #8E9397 !important;
  color: $white !important;
  background: $disabled-grey !important;
  border: 1px solid transparent !important;
}

.lb-btn.btn-link {
  @include fontSize(14px);
  padding: 0;
  min-width: auto;
  height: auto !important;
  background: transparent;
  display: inline-flex;
  align-items: center;
  color: #1d262f;

  .icn-close {
    height: 7px;
    width: 7px;
    transition: 0s;
  }

  &:hover {
    color: $icon-red;
  }

  &.btn-red {
    color: $icon-red;
    @include fontFamily('Liberty-Nus Medium');
  }

  &.btn-white {
    color: #fff;
  }

  i {
    color: inherit;
  }

  .icn-arr-right {
    height: 7px;
    width: 4px;
    margin-right: 15px;
    position: relative;
    top: 1px;
  }
}

.btn-custom-dis:disabled {
  background: #e3e3e3 !important;
  border: 1px solid transparent !important;
  //  color: $medium-grey !important;
}

.btn-wide-35 {
  min-width: 35%;
}
.btn-wide-40 {
  min-width: 40%;
}
.btn-wide-45 {
  min-width: 45%;
}
.btn-wide-50 {
  min-width: 50%;
}

/* =========== END of button modules ========== */

/* =========== input modules ========== */
.largest-form-group {
  .input-block {
    .lb-input-out.amount-input-out {
      height: 6.25rem;
      padding: 1.25rem 1.5625rem;
      border-radius: $borderRadius;

      .lb-input {
        padding: 0 2.2rem 0 1.5625rem;
        font-size: 1rem;
      }

      i.amount-symbol {
        right: 1.5625rem;
      }
    }

    .lb-btn {
      border-radius: $borderRadius;
    }
  }

  .lb-select {
    height: 5.625rem; //100px

    &.type-select.is-open {
      height: 3.25rem;

      .Select-placeholder {
        @include fontFamily('Liberty-Nus');
        font-size: 0.9375rem;
        padding: 0 1.375rem;
      }

      .Select-input {
        padding: 0 1.375rem;
      }
    }

    .Select-value,
    .Select-input {
      padding: 0 3.5rem 0 2.5rem !important;
      font-size: 0.9375rem;

      @include fontFamily('Liberty-Nus');
    }

    .Select-placeholder {
      padding: 0 2.5rem;
      font-size: 1.25rem; //20px
    }

    &.is-open {
      .Select-input {
        padding: 0 1.375rem !important;
      }
    }
  }

  .lb-label {
    display: inline-block;
    font-size: 1rem;
    margin: 0 0 1.0625rem;
  }

  .Select-value {
    padding-right: 60px !important;
  }

  .Select-arrow-zone {
    width: 50px;
    text-align: left;

    .icn.icn-arrow-down {
      color: #fff;
      width: 15px;
      height: 8px;
    }
  }

  .lb-btn:not(.btn-link) {
    height: 4.0625rem;
  }
}

.symbol-calendar {
  position: absolute;
  top: 60% !important;
  transform: translateY(-60%) !important;
  svg {
    width: 20px;
    height: 18px;
    color: #242424;
  }
}
.larger-form-group {
  .lb-input {
    // height: 4.0625rem; // 65px
    height: 50px; // 50px
    // padding: 0 1.6875rem;
    padding: 0 1.0625rem;
    font-size: 0.9375rem;

    @include fontFamily('Liberty-Nus');
    background-color: $white;
  }

  .lb-select {
    // height: 4.0625rem;
    height: 3.125rem;
  }

  .lb-select {
    .Select-value,
    .Select-placeholder,
    .Select-input {
      // padding: 0 1.6875rem !important;
      padding: 0 1.0625rem !important;
      font-size: 0.9375rem;

      @include fontFamily('Liberty-Nus');
    }
  }

  .lb-btn:not(.btn-link) {
    // height: 4.0625rem;
    height: 50px;
  }

  .type-select .Select-input {
    //height: 3.375rem;
  }
}

.middle-form-group {
  .lb-input {
    height: 3.125rem; // 50px
    padding: 0 1.6875rem;
    font-size: 0.9375rem;
  }

  .lb-select {
    height: 4.0625rem;
  }

  .lb-select {
    .Select-value,
    .Select-placeholder,
    .Select-input {
      padding: 0 1.6875rem;
      font-size: 0.9375rem;

      @include fontFamily('Liberty-Nus');
    }
  }

  .lb-btn:not(.btn-link) {
    height: 3.125rem;
  }

  .treasury-code-input {
    color: $basic-grey !important;
    &::-webkit-input-placeholder {
      color: $basic-grey !important;
    }
  }
}

.hint {
  @include fontFamily('Liberty-Nus');
  @include fontSize(14px);
}

.small-form-group {
  .lb-input {
    height: 3.125rem; // 50px
    padding: 0 0.9375rem;
    font-size: 0.875rem;
  }

  .lb-select.account-select {
    height: auto;
  }

  .lb-select {
    height: 3.125rem;
  }

  .lb-select.select-transparent {
    height: 3.1875rem;
  }

  .lb-select {
    @include fontFamily('Liberty-Nus');

    .Select-input {
      height: 3.125rem;

      input {
        height: 100%;
        padding: 0;
      }
    }

    .Select-value {
      padding: 0 2rem 0 1.0625rem !important;
      font-size: 0.875rem;
    }

    .Select-input {
      padding: 0 2rem 0 1.0625rem !important;
      font-size: 0.875rem;
    }

    .Select-option {
      padding-left: 1.0625rem;
      padding-right: 1.0625rem;
      font-size: 0.875rem;
    }

    .Select-placeholder {
      padding: 0 2.2em 0 1.0625rem;
      font-size: 0.875rem;
    }
  }

  .Select-arrow-zone {
    padding-right: 1.0625rem !important;
  }

  .lb-btn:not(.btn-link) {
    height: 3.125rem;
    min-width: 50%;
  }
}

.smaller-form-group {
  .lb-input {
    height: 2.8125rem; // 45px
    padding: 0 1.0625rem;
    font-size: 0.875rem;
  }

  .lb-select {
    height: 2.8125rem !important;
    @include fontFamily('Liberty-Nus');
  }

  .lb-select {
    .Select-value,
    .Select-input {
      padding: 0 2rem 0 1.0625rem !important;
      font-size: 0.875rem;
    }

    .Select-option,
    .select-option {
      padding-left: 1.0625rem;
      padding-right: 1.0625rem;
      font-size: 0.875rem;
    }

    .Select-placeholder {
      padding: 0 2.2em 0 1.0625rem;
      font-size: 0.875rem;
    }
  }

  .lb-btn:not(.btn-link) {
    height: 2.8125rem;
  }
}

.inputs-grey {
  .lb-input:not(:disabled) {
    border-color: $grey-input-border;
  }

  .lb-input:not(:disabled),
  .lb-input:focus {
    background: $grey-input-bg !important;
    color: $grey-input-color !important;
  }

  .lb-input:focus {
    border: 1px solid #a5a5b1 !important;
  }

  .lb-input::placeholder {
    color: $grey-input-color !important;
  }
}

.lb-label {
  font-size: 0.875rem;
}

// form class
.lb-form {
  .btn-submit {
    width: 50%;
  }
}

// use group if there is several elements (ex. label + input + info text)
.lb-form-group {
  position: relative;
  text-align: left;
  margin-bottom: 1.875rem;

  &.mb-0 {
    margin-bottom: 0;
  }

  .lb-label {
    display: inline-block;
    margin-bottom: 10px;

    @include fontFamily('Liberty-Nus Medium');
  }

  .lb-input {
    margin: 0;
    transition: 0.2s;
    line-height: 3.1875rem;
  }

  .lb-input-info {
    @include fontSize(12px);
    color: $grey;
    margin-top: 5px;
  }

  .lb-input-err {
    @include fontSize(13px);
    display: none;

    @include fontFamily('Liberty-Nus');

    .error-custom {
      color: #fff;
      margin: 0.75rem 0 0.2rem;
    }
  }

  .lb-input-out {
    &.bordered-error {
      width: calc(100% + 0.875rem);
      left: -0.4375rem;
      padding: 0.4375rem;
      border-radius: $borderRadius;

      &.error {
        background: $icon-red;

        .lb-input-err {
          color: #fff;
        }

        .lb-input {
          background: #fff;
          color: $basic-grey;
        }

        .lb-input:-webkit-autofill,
        .lb-input:-webkit-autofill:hover,
        .lb-input:-webkit-autofill:focus,
        .lb-input:-webkit-autofill:active {
          background-color: transparent !important;
          -webkit-box-shadow: 0 0 0px 1000px white inset;
          -webkit-text-fill-color: #000 !important;
        }
      }
      .lb-input-err {
        display: block;
        padding: 0.625rem 0 0.125rem;
      }
    }

    &:not(.bordered-error) {
      &.error {
        .lb-input-err {
          color: $wrn-red;
          margin: 10px 0 25px;
        }
      }
    }

    &.error {
      .lb-input-err {
        display: block;
        margin: 5px 5px;
        padding-top: 0;
      }
    }
  }
}

.lb-input-out {
  position: relative;

  &.lb-input-search {
    .lb-input {
      padding-right: 35px;
      @include fontSize(12px);
      padding-right: 35px;
    }
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.icn-search {
      right: 0.9375rem;
      width: 0.875rem;
      height: 0.875rem;
      font-size: 0;
      color: #b7b6b8;

      & + .lb-input {
        height: 2.8rem;
        padding: 0 2.375rem 0 1.25rem;
      }
    }

    &.amount-symbol {
      right: 0;
      padding: 0 15px;
      color: $basic-grey;
      font-style: normal;

      & + .lb-input {
        padding-right: 40px;
      }
    }

    &.month-symbol {
      right: 0;
      padding: 0 15px;
      color: $basic-grey;
      font-style: normal;

      & + .lb-input {
        padding-right: 40px;
      }
    }
  }

  .loader-out {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.lb-input {
  display: block;
  width: 100%;
  padding: 0 1.25rem;
  height: 3.125rem;
  @include fontFamily('Liberty-Nus');
  font-size: 0.875rem;
  line-height: 1em;
  color: #5d6163 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9d9e6;
  border-radius: $borderRadius;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;

  // &:not(.b-red):focus {
  //     border: 1px solid #1A69AE;
  // }

  &::-ms-clear {
    display: none;
  }
}

.lb-input.lb-input-grey {
  background-color: $search-input;
  color: #919395;
  border: 1px solid $search-input;
  transition: 0.3s;

  &::-webkit-input-placeholder {
    color: $placeholder-grey;
  }
  &::-moz-placeholder {
    color: $placeholder-grey;
  }
  &:-ms-input-placeholder {
    color: $placeholder-grey;
  }
  &:-moz-placeholder {
    color: $placeholder-grey;
  }
}

.lb-input.input-grey-fill {
  background-color: $grey-filled-input;
  color: $dark;
  border: 1px solid $grey-filled-input;
  transition: 0.3s;

  &::-webkit-input-placeholder {
    color: $placeholder-grey;
  }
  &::-moz-placeholder {
    color: $placeholder-grey;
  }
  &:-ms-input-placeholder {
    color: $placeholder-grey;
  }
  &:-moz-placeholder {
    color: $placeholder-grey;
  }

  &:focus,
  .filled {
    background-color: $white !important;
    color: $black;
    border: 1px solid $placeholder-grey !important;
  }
}

.lb-input.b-red:focus {
  //border: 1px solid $icon-red;
}

// .lb-input.b-red.filled{
//     border: 1px solid $icon-red;
// }

.lb-input.transparent-input {
  // background: transparent;

  &:focus {
    border-color: #fff;

    & + i.amount-symbol {
      color: #5d6163;
    }
  }

  &.filled + i.amount-symbol {
    color: #5d6163;
  }

  & + i.amount-symbol {
    //color: #fff;
  }
}
::placeholder {
  color: $placeholder-grey;
}
::-webkit-input-placeholder {
  color: $placeholder-grey;
}
::-moz-placeholder {
  color: $placeholder-grey;
}
:-ms-input-placeholder {
  color: $placeholder-grey;
}
:-moz-placeholder {
  color: $placeholder-grey;
}

.datepicker-out {
  .lb-datepicker.lb-input {
    width: 110px;
    height: 40px;
    padding: 0 0.75rem;
    @include fontFamily('Liberty-MT Bold');
    @include fontSize(15px);
  }

  &.datepicker-single {
    .lb-datepicker.lb-input {
      width: 130px;
    }

    .icn-calendar {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .min-date {
    color: #db0011;
    @include fontSize(12px);
    padding-top: 3px;
    bottom: -20px;
  }
}

.additional-filters {
  .lb-input {
    width: 130px;
  }
}

.icn-calendar.pointer {
  transition: 0.3s;

  &:hover {
    //opacity: 0.7;
    color: $icon-red;
  }
}

.sms-input {
  width: 32px;
  height: 50px;
  margin-left: 10px;
  padding: 0;
  text-align: center;
  font-size: 1.3rem;
  // color: transparent !important;
  text-shadow: 0 0 0 #5d6163 !important;
  font-size: 15px !important;
  border: 1px solid #242424;

  &:focus {
    outline: none !important;
    border: 1px solid #d9d9e6;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
    border: 1px solid #242424;
  }

  &.empty:focus {
    background: $white !important;
  }

  &.lb-input.filled,
  &.lb-input:focus {
    color: transparent !important;
  }

  &::selection {
    background: transparent;
  }
}

.sms-input.empty {
  // background: url('../images/icons/dot.svg') 50% 50% no-repeat;
  // background-size: 6px 6px;
  border: 1px solid #242424;
  &:focus {
    background: #fff !important;
  }
}
/* =========== END of input modules ========== */

/* =========== Checkbox ========== */
.checkbox-wrapper {
  display: inline-flex;
  // display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ .checkmark {
      //background-color: $light-red;
      background-color: #171827;
      border-color: #171827;

      svg {
        opacity: 1;
      }
    }
  }

  &.dark-red input:checked ~ .checkmark {
    background-color: $icon-red;
    border-color: $icon-red;
  }

  &.black input:checked ~ .checkmark {
    background-color: #171827;
    border-color: #171827;
  }

  span {
    order: 2;
  }

  &.dark-red .checkmark {
    background: transparent;
  }

  .checkmark {
    position: relative;
    margin: 0 10px 0 0;
    order: 1;
    height: 20px;
    width: 20px;
    background-color: #fff;
    color: #fff;
    border: 1px solid $label-grey;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50%;
      height: 50%;
      opacity: 0;
    }
  }
}

// Bordered checkbox
.checkbox-br {
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    z-index: -10;
    transition: 0.2s;

    &:checked + .checkmark {
      border: 4px solid #db0011;
    }
  }

  .checkmark {
    display: block;
    height: 1.125rem;
    width: 1.125rem;
    background: #fff;
    border: 2px solid $label-grey;
    border-radius: $borderRadius;
  }
}
/* =========== Checkbox End ========== */

/* =========== breadcrumb ========== */
.lb-breadcrumb {
  display: flex;
  align-items: left;
}
.lb-breadcrumb li {
  margin-right: 40px;
}
.lb-breadcrumb li .icon {
  margin-right: 14px;
}
/* =========== END of breadcrumb ========== */

/* =========== tooltip ========== */
// .lb-tooltip {
//     position: absolute;
//     border-radius: $borderRadius;
//     z-index: 2;
// }
// .lb-tooltip.tooltip-gay {
//     background: #EFF1F4;
// }
// .lb-tooltip.tootip-lg {
//     padding: 20px 16px;
// }
// .lb-tooltip.tooltip-right {
//     transform: translateX(110%);
//     top: 0;
// }
// .lb-tooltip::before {
//     display: block;
//     content: "";
//     position: absolute;
//     left: -10px;
//     width: 0;
//     height: 0;
//     border-top: 10px solid transparent;
//     border-bottom: 10px solid transparent;
//     border-right:10px solid #EFF1F4;
// }
/* =========== END of tooltip ========== */

/* =========== END of language switcher ========== */

/* =========== popup ========== */

.httperror-modal {
  z-index: 20 !important;
}

.transaction-modal {
  //min-height: 452px;

  .lb-block-body {
    min-height: 440px;
  }
  .error-box {
    padding: 100px 0;
  }

  .loader-container {
    min-height: 460px;
  }
}

.currenttask-modal {
  .lb-block-body {
    min-height: 320px;
  }
  .error-box {
    padding: 100px 0;
  }

  .loader-container {
    min-height: 340px;
  }

  .transaction-details {
    .transaction-details-row {
      .transaction-details-box:last-child {
        margin-bottom: 20px;
      }
    }
  }
}

.lb-popup {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .lb-block-head {
    padding: 20px 25px;
    color: $dark;

    @include fontFamily('Liberty-MT Medium');
    @include fontSize(16px);

    .flex-1 {
      margin-top: 5px;
    }
  }

  .icn-close {
    color: #000;
    height: 0.75rem;
    width: 0.75rem;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $nav-red;
    }
  }

  .details-print {
    padding: 1.25rem 1.5rem;
    border-top: 1px solid #e9e9f1;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;

    .print-box {
      .icn.icn-print {
        transition: 0.2s;
        cursor: pointer;
      }

      &:hover {
        .icn.icn-print {
          color: $nav-red;
        }
        .icn.icon-swift {
          color: $nav-red;
        }
      }

      .loader-out.full-size {
        right: auto !important;
        left: -2px;
      }
    }
  }
}

.calculate-popup {
  min-width: 150px !important;
}

.lb-popup-body {
  width: 80%;
  max-width: 500px;
  min-width: 350px;
  background: #f7f8f9;
  border-radius: $borderRadius;
  overflow: hidden;
  outline: none;

  .lb-block-head {
    min-height: 50px;
    height: 50px;
    border-bottom: 1px solid #dedee4;
    background: $nav-grey;
  }

  &.lb-popup-slider {
    overflow: visible;
  }
}

.lb-popup-row {
  display: flex;
  align-items: center;
  padding: 0.9375rem 1.5625rem;
  @include fontFamily('Liberty-Nus Medium');

  &.popup-row-lg {
    padding: 0.9375rem 1.5625rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border-grey-2;
  }

  span {
    @include fontFamily('Liberty-Nus');
  }

  .popup-img-out {
    width: 15%;
    margin-right: 10%;
  }

  .lb-popup-img {
    max-width: 100%;
    max-height: 60px;
  }

  .popup-label {
    @include fontFamily('Liberty-Nus Bold');
    color: $basic-grey;
  }

  .popup-value {
    @include fontFamily('Liberty-Nus Medium');
    color: $basic-grey;

    &.in-span {
      span {
        white-space: nowrap;
        position: relative;
        left: 3px;
        > span {
          left: 20px;
        }
      }
    }
  }

  .send-code {
    font-family: 'Liberty-MT Bold', Arial, sans-serif !important ;
  }

  .lb-input {
    @include fontFamily('Liberty-Nus Medium');
    @include fontSize(12px);
  }
}

.sms-input-error {
  .sms-input {
    border: 1px solid #eb293e;
  }
}

.sms-input:not(.empty) {
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.sms-input {
  // -webkit-text-security: disc !important;
  // font-size: 26px !important;
}

.dot-input {
  font-family: 'dotsfont' !important;
  font-size: 10px !important;
}

.sendcode-box {
  display: block;
}
.lb-utility-modal {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .message-block-icn {
    svg {
      width: 48px;
      height: 48px;
    }
  }
}
.code-error {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: #eb293e;
  font-family: 'Liberty-NUS Bold', Arial, sans-serif !important ;
  @include fontSize(12px);

  .icn.icn-warning {
    margin-right: 1px;
    svg {
      color: #eb293e;
    }
  }
}

.lb-avatar-upload {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 30px 0;

  %img {
    width: 125px;
    height: 125px;
    margin-bottom: 6px;
    border-radius: 50%;
  }

  &.corp-avatar-upload {
    padding: 30px 0;
  }

  img {
    @extend %img;
  }

  .avatar-round {
    @extend %img;
    margin-bottom: 14px;
    margin-left: auto;
    margin-right: auto;
  }
  .avatar-round-dark {
    @extend %img;
    margin-bottom: 14px;
    margin-left: auto;
    margin-right: auto;
  }

  .or {
    font-size: 12px !important;
  }

  p {
    @include fontSize(15px);
    line-height: 25px;
    color: #000;
  }

  .avatar-upload-box {
    margin-top: 9px;
  }
}
.cropping-image-body {
  padding: 1.125rem 1.5625rem 1.875rem;
}

.cropping-image-footer {
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 1.5625rem 1.875rem;
  border-top: 1px solid $border-grey-2;
  align-items: center;
}

.lb-popup-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5625rem 1.5625rem 1.5625rem;
  //border-top: 1px solid $border-grey-2;

  .icn-refresh {
    color: #626263;
  }
}
.popup-error {
  .icn-clock {
    height: 45px;
    width: 45px;
  }
  a {
    color: #e70d2f;
    text-decoration: underline;
  }
}
.popup-success {
  color: #535a5e;
  .icn.icn-tick {
    height: 45px;
    width: 45px;
  }
  .icn-clock {
    height: 45px;
    width: 45px;
  }
  .is-green {
    color: #34ac34;
  }

  a {
    color: #e70d2f;
    text-decoration: underline;
  }
}
.popup-error {
  color: #535a5e;
  .icn.icn-round {
    height: 45px;
    width: 45px;

    svg {
      width: 45%;
      height: 45%;
    }
  }

  .is-warrning {
    color: #e70d2f;
  }
}
/* =========== END of popup ========== */

/* =========== Header Widget ========== */
.widget-container {
  align-items: unset !important;

  .icn-warning-red {
    svg {
      height: 20px;
    }
  }
}

.lb-widget {
  // display: block;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  position: relative;

  .widget-item {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    border: 0;

    &.opac-4 {
      cursor: default;

      .icn-warning {
        &:hover {
          color: #242424;
        }
      }

      &:hover {
        background: transparent;

        .icn-circle {
          color: #242424;
        }
      }
    }

    .icn-warning {
      width: 100%;
      height: 100%;
      color: $icn-dark;

      &:hover {
        color: $icon-red;
      }
    }

    &:hover {
      background: $white;

      .icn-circle {
        color: $nav-red;
      }

      .icn-widget {
        color: #242424;
      }
    }

    &.item-loading {
      &:hover {
        background: none;
      }
    }

    .loader-out + .icn-circle {
      display: none;
    }
  }

  .widget-warning {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    height: 1.1rem;
    width: 1.1rem;
    color: $white;
    background: $dark;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 55%;
    }
  }

  .icn-info {
    position: absolute;
    top: -0.2rem;
    right: -0.3rem;
    background: $dark;
    color: $white;
    border: 1px solid $dark;
  }

  .icn-widget {
    width: 100%;
    height: 100%;
    color: $icn-dark;
    transition: 0.1s;

    svg {
      width: 70%;
    }
  }

  .icn-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $icn-dark;
    transition: 0.3s;
    opacity: 0.9;
  }

  .icn-avatar-box {
    .icn-widget {
      svg {
        width: 40%;
      }
    }
  }

  .icn-phone-box {
    .icn-widget {
      svg {
        width: 33%;
      }
    }
  }

  .icn-arrows-box {
    .icn-widget {
      svg {
        width: 53%;
      }
    }
  }

  .icn-utility-box {
    .icn-widget {
      svg {
        width: 62%;
      }
    }
  }

  .icn-add-box {
    .icn-widget {
      svg {
        width: 54%;
      }
    }
  }

  .widget-img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;

    // & + .icn-circle{
    //     display: none;
    // }
  }

  .pay-abbr {
    background: transparent;
    width: 100%;
    height: 100%;
    color: $icn-dark;
    display: flex;
    align-items: center;
    justify-content: center;

    @include fontFamily('Liberty-MT Medium');
    text-transform: uppercase;
    @include fontSize(12px);
  }

  & + .lb-tooltip {
    margin-top: 16px !important;
    line-height: 15px;
  }
}
.opac-4 + .lb-tooltip {
  visibility: hidden !important;
}
.lb-widget.active {
  .widget-item {
    background: $white;

    .icn-circle {
      color: $nav-red;
    }
  }

  .widget-img {
    opacity: 0.7;
  }
}

.lb-widget:not(:first-child) {
  margin: 0 0 0 10px;
}

.delete-widget {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 75%;
  width: 75%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  svg {
    height: 40%;
    width: 40%;
  }
}

.tooltip-delet.lb-tooltip {
  margin-top: 20px !important;
}
/* =========== END of Header Widget ========== */

/* =========== Slider ========== */
.lb-slider {
  position: relative;

  .lb-block {
    margin-bottom: 0;
  }

  .lb-slider-prev,
  .lb-slider-next {
    position: absolute;
    top: 50%;
    cursor: pointer;
    background: $dark;
    border-radius: 50%;
    color: #fff;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    &:not(.disabled):hover {
      background: $nav-red;
    }

    svg {
      max-width: 0.87rem;
      max-height: 50%;
      position: relative;
      bottom: -1px;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  .lb-slider-prev {
    transform: translateY(-50%) rotate(90deg);
    right: 103%;
  }

  .lb-slider-next {
    transform: translateY(-50%) rotate(-90deg);
    left: 103%;
  }

  .slider-dots {
    position: absolute;
    top: calc(100% + 15px);
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 0;
  }

  .slider-dot {
    //border: 2px solid $eye-icon-grey;
    background-color: #b7b7b7;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;

    &:not(:last-child) {
      margin: 0 5px 0 0;
    }

    &.active {
      background: $nav-red;
    }
  }
}

/* =========== END of Slider ========== */

// delete later
// .gr-3{
//     padding: 25px;
// }

// .gr-3 > div{
//     margin:20px 0;
// }

// .gr-3 > div:nth-child(3n - 2){
//     grid-column: 1 / 5;
// }

// .gr-3 > div:nth-child(3n - 1){
//     grid-column: 5 / 10;
// }

// .gr-3 > div:nth-child(3n){
//     grid-column: 10 / -1;
// }

// .gr-3 input{
//     padding:0.5rem;
// }

// .gr-3 .Select{
//     min-width:145px;
// }

// .gr-3 input + span{
//     margin:0 0.5em;
// }

// .gr-3 + div{
//     padding:25px;
//     text-align: right;
// }
// delete later end

// currency
.round-flag {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  overflow: hidden;
  background: red;
}

/* =========== Templates - avatar  ========== */
.avatar-round {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  color: $icn-dark;
  background: $nav-grey;
  cursor: pointer;

  svg {
    width: 40%;
    height: 40%;
  }
}

.img-border {
  border: 1px solid #949494;
  background: transparent;
}

.lb-select .avatar-round {
  height: 55px;
  width: 55px;
  margin-right: 0.9375rem;
  color: $icn-dark;
}
/* =========== Templates - avatar end  ========== */

/* =========== Templates - avatar dark ========== */
.avatar-round-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  color: $icn-dark;
  background: $nav-grey;
  cursor: pointer;

  svg {
    width: 55%;
    height: 45%;
  }
}

.lb-select .avatar-round-dark {
  height: 55px;
  width: 55px;
  margin-right: 0.9375rem;
}

.avatar-text {
  margin-left: 1.25rem;
  cursor: pointer;
}
/* =========== Templates - avatar dark end  ========== */

/* =========== Templates - tooltip  ========== */

.__react_component_tooltip.tip-blocked {
  pointer-events: all;
}

.__react_component_tooltip.type-dark {
  opacity: 1;

  &.place-right:after {
    border-right-color: $medium-grey;
  }
}

// element in the row - for tooltip centering
.tooltip-parent {
  display: inline-block;
  max-width: 100%;
  height: 100%;
}

.tooltip-parent-grid {
  display: block;
  max-width: 100%;
  height: 100%;
}

.lb-tooltip {
  max-width: 50%;
  text-align: left;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;

  // &.type-dark{
  //     background: $medium-grey;
  //     opacity: 1;

  //     &.place-top:after{
  //         border-top-color: $medium-grey !important;
  //     }

  //     &.place-bottom:after{
  //         border-bottom-color: $medium-grey !important;
  //     }

  //     &.place-right:after{
  //         border-right-color: $medium-grey !important;
  //     }

  //     &.place-left:after{
  //         border-left-color: $medium-grey !important;
  //     }
  // }

  // widget
  &.type-darker {
    //background: #e6e6e6;
    background: #414141;
    color: $white;
    @include fontFamily('Liberty-Nus Medium');
    border-radius: $borderRadius;
    @include fontSize(12px);
    font-style: normal;
    opacity: 1 !important;
    &.place-top:after {
      //  border-top-color: #e6e6e6 !important;
      border-top-color: #414141 !important;
    }
    &.place-bottom:after {
      // border-bottom-color: #e6e6e6 !important;
      border-bottom-color: #414141 !important;
    }
    &.place-right:after {
      // border-right-color: #e6e6e6 !important;
      border-right-color: #414141 !important;
    }
    &.place-left:after {
      //  border-left-color: #e6e6e6 !important;
      border-left-color: #414141 !important;
    }
  }

  &.type-light {
    background: #d3d3d3;
    color: $black;
    @include fontFamily('Liberty-Nus Medium');
    border-radius: $borderRadius;
    @include fontSize(12px);
    font-style: normal;

    &.place-top:after {
      border-top-color: #d3d3d3 !important;
    }

    &.place-bottom:after {
      border-bottom-color: #d3d3d3 !important;
    }

    &.place-right:after {
      border-right-color: #d3d3d3 !important;
    }

    &.place-left:after {
      border-left-color: #d3d3d3 !important;
    }
  }

  &.tip-blocked {
    width: 430px;
    padding-right: 10px;
  }
  &.tip-waittrust {
    width: 255px;
  }
  &.tip-trust {
    width: 140px;
  }
  &.tip-trustworthy {
    width: 361px;
    padding-right: 10px;
  }
  &.tip-mminbalance {
    width: 372px;
  }

  .blocked-amounts-tooltip {
    .thumb-vertical {
      background-color: #919395;
    }

    .tip-container {
      margin-right: 20px;
    }
  }

  .tip-title {
    @include fontFamily('Liberty-MT Bold');
    border-bottom: 1px solid $icn-dark;
    padding: 0.5rem 0;
    @include fontSize(11px);
  }

  .tip-body {
    padding: 0.3rem 0;

    .thumb-vertical {
      background-color: #919395;
    }
  }

  .tip-row {
    display: flex;
    align-items: flex-start;
    padding: 0.3rem 0;
  }

  .is-red,
  .is-green {
    transition: none;
  }
}

.lb-main .lb-tooltip {
  max-width: 400px;
}

/* =========== Templates - tooltip end  ========== */

/* =========== DAYPICKER ========== */
.DayPicker {
  min-height: 295px;

  /* temporary or permanent; purpose: to visually disable outside and disabled days highlight on hover */
  div.DayPicker-Day--disabled.DayPicker-Day--outside {
    &:hover {
      background-color: rgba($color: #eff1f1, $alpha: 1) !important;
      outline: none !important;
    }
  }

  .DayPicker-wrapper {
    outline: none !important;
    padding-bottom: 20px;
    margin-top: 5px;

    .DayPicker-Months {
      min-height: 290px;
      display: table-cell !important;
    }
  }

  .DayPicker-Caption {
    padding: 0;
    margin-bottom: 0.95rem;

    & > div {
      @include fontSize(12px);

      @include fontFamily('Liberty-MT Bold');
    }
  }

  .DayPicker-Day {
    padding: 0;
    height: 40px;
    width: 40px;
    text-align: center;
    @include fontSize(12px);
    color: $dark;
    border: 1px solid $border-grey-4;
    outline: none;
    border-radius: 0;

    @include fontFamily('Liberty-MT Bold');
  }
  .DayPicker-Day.DayPicker-Day--outside {
    color: #ccc;

    &:hover {
      background-color: transparent !important;
      border-radius: 0;
      outline: 1px solid #db0011;
      border-radius: 0 !important;
    }
  }

  &:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: transparent !important;
    border-radius: 0;
    outline: 1px solid $icon-red;
    border-radius: 0 !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: $white;
    background-color: $icon-red !important;
    border-radius: 0;
  }

  .DayPicker-Day--disabled {
    background-color: #eff1f1 !important;
    border-radius: 0;
  }

  .calendar-arrow {
    height: 12px;
    width: 7px;
    color: $darker-grey;
    cursor: pointer;

    @include fontFamily('Liberty-MT Bold');
    text-transform: uppercase;
  }

  .App.ka .calendar-arrow {
    text-transform: lowercase;
  }

  .DayPicker-Day--today {
    font-weight: normal !important;
  }

  .month-block {
    width: 78px;
    text-align: center;
  }

  .year-block {
    width: 40px;
    text-align: center;
  }
}
/* =========== DAYPICKER END ========== */

// action buttons - download and print!
.icn-export-wrap {
  .icn {
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: $icon-red !important;
    }
  }
}

.icn-wrap {
  border: 1px solid #646464;
  width: 45px;
  height: 45px;
  border-radius: $borderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  &:hover {
    border: 1px solid $icon-red;
    color: $icon-red;
    cursor: pointer;
    .icn-download,
    .icn-print,
    .icon-swift {
      color: $nav-red;
    }
  }
}

.download-outer {
  width: 45px;
  height: 45px;
}

.icn-download {
  transition: 0.2s;
}

.transfers-block {
  .currency-info-block {
    position: absolute;
    left: 0;
    top: -31px;
  }
  .lb-input {
    &::selection {
      background: none;
    }
  }
  .info-txt {
    font-family: 'Liberty-Nus Regular', Arial, sans-serif !important;
    font-size: 14px;
    color: #d9d9d9;
  }
}

.currency-info-block {
  @include fontFamily('Liberty-Nus');
  @include fontSize(12px);
}

.btn-back {
  position: relative;
  z-index: 4;

  @include fontFamily('Liberty-Nus Medium');
  &.lb-btn {
    height: auto !important;
  }
}

.lb-input-err {
  @include fontFamily('Liberty-Nus');
  @include fontSize(13px);
  text-align: left;

  &.show-error {
    display: block !important;
    color: $nav-red !important;
  }
}

// popover
.popover {
  transition: opacity 0.2s;
  position: absolute;
  z-index: 1;
  top: calc(50% + 15px);
  right: 0;
  min-width: 260px;
  padding: 0.35rem 0;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: $borderRadius;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.popover-action {
  padding: 0.5rem 1rem;
  color: $grey-select-option;

  @include fontFamily('Liberty-Nus');
  cursor: pointer;
  @include fontSize(14px);
  transition: background-color 0.1s;

  &:hover {
    background: #efefef;
  }
}

//  no items on product page
.no-items {
  @include fontFamily('Liberty-MT Bold');
  @include fontSize(14px);
  text-align: center;
  background: $dark;
  color: $white;
}

.lb-confirm-text {
  padding: 1.5rem 1.5rem;
  @include fontSize(14px);
}

.home-btn {
  color: white;
  font-size: 14px;
}
.home-btn:hover {
  color: white;
}

/*dashboard banner*/

.banner-liberty img,
.Personal-banker img {
  max-width: 100%;
}

// .closing-nitialized {
//   position: absolute !important;
//   right: 66px;
//   z-index: 555;
//   top: 0 !important;
// }

// dashboard draggable elements
.draggable-block {
  outline: none;
  margin-bottom: 30px;
  max-width: 980px;

  .lb-image-out {
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: grab;
  }
}

.draggable-item {
  background: $white;
  outline: none;

  &:not(:last-child) {
    border-bottom: 1px solid $border-grey-2;
  }

  &:hover {
    background: rgba(243, 243, 243, 0.44);
  }
}

// welcome page popup
.welcome-page-out {
  //width: 100vw;
}

.welcome-page {
  width: 100%;
  min-height: 625px;
  position: relative;
  .lb-block-head {
    min-height: 280px;
    background-color: #f6f6f6;
    border-bottom: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .slidre-block {
    position: absolute;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .slider-logo {
      width: 127px;
      height: 22px;
      margin-bottom: 30px;
    }

    .todo-list {
      max-width: 680px;
      padding: 0 25px;
    }

    .slider-image {
      // max-width: 681px;
      // max-height: 365px;
    }
    .slider-title {
      @include fontSize(14px);
      @include fontFamily('Liberty-MT Bold');
      text-align: center;

      .line-dec {
        height: 1px;
        width: 38px;
        border-radius: 10px;
        background: #db0011;
        margin: 5px 0;
      }
    }

    .slider-description {
      @include fontSize(14px);
      @include fontFamily('Liberty-Nus Medium');
      text-align: center;
      height: 42px;
    }

    .slider-footer {
      width: 100%;
      padding: 25px 30px;

      .slider-num {
        div:first-child {
          width: 10px;
        }
        div:last-child {
          color: #cbcac9;
        }
      }

      .slider-dots {
        text-align: center;
        line-height: 0;

        .slider-dot {
          background-color: #e1e1e1;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-block;
          cursor: pointer;

          &:not(:last-child) {
            margin: 0 13px 0 0;
          }

          &.active {
            background: $nav-red;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .welcome-page {
    //  min-height: 500px;

    .slidre-block {
      .slider-description {
        // height: 90px;
      }

      .slider-footer {
        .slider-dots {
          .slider-dot {
            width: 12px;
            height: 12px;

            &.active {
              background: $nav-red;
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

// banner default styles
.lb-banner {
  display: block;
  border-radius: $borderRadius;
  line-height: 0;
  overflow: hidden;
}

.record-notfound {
  border: 2px dashed #e9ecf2;
  border-radius: 4px;
  padding-bottom: 55px;
  .icon-notfoundrecord {
    width: 27px;
    height: 32px;
  }
}

//404 page style
.maintenace-page {
  min-height: 550px !important;

  .icon-404 {
    width: 63px;
    height: 53px;
    margin-top: 40px;
  }

  .text-line {
    @include fontSize(18px);
    @include fontFamily('Liberty-Nus Medium');
    color: #1d262f;
  }

  .text-geo::after {
    content: '';
    background: #db0011;
    height: 1px;
    max-width: 55px;
    width: 100%;
    border-radius: 4px;
    display: inline-block;
  }

  .additional-info {
    @include fontSize(12px);
    @include fontFamily('Liberty-Nus');
    color: #464a4e;
    margin-top: 10vh;
    line-height: 18px;

    span {
      color: #db0011;
    }
  }
}

@media screen and (max-width: 1550px) {
  .lb-slider {
    .lb-slider-next,
    .lb-slider-prev {
      width: 2rem;
      height: 2rem;
    }

    .lb-slider-prev {
      right: 101.5%;
    }

    .lb-slider-next {
      left: 101.5%;
    }
  }

  .lb-block.details-block .lb-block-head .lb-subnav-item {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1366px) {
  .lb-block-title {
    font-size: 0.875rem;
  }
  .lb-block-row {
    .is-name {
      // friendlyname
      @include fontSize(14px);
    }

    .is-amount {
      @include fontSize(15px);
      @include fontFamily('Liberty-Nus Medium');
      color: $basic-grey;
    }

    .is-amount-sm {
      @include fontSize(13px);
      @include fontFamily('Liberty-Nus Medium');
      color: $basic-grey;
    }

    // month and payments quantity
    .is-info {
      @include fontSize(15px);
      @include fontFamily('Liberty-Nus Medium');
      color: $basic-grey;
    }
  }

  .lb-btn {
    height: 2.5rem;
    font-size: 0.85rem;
    padding: 0 1.2rem;

    &.btn-dark,
    &.btn-border,
    &.btn-red {
      font-size: 0.85rem;
    }
  }
  .lb-account-details {
    .lb-btn {
      padding: 0 1rem !important;
    }
  }

  .lb-slider {
    .lb-slider-prev {
      right: 100.5%;
    }

    .lb-slider-next {
      left: 100.5%;
    }
  }

  .lb-block.details-block .lb-block-head .lb-subnav-item {
    font-size: 0.75rem;
  }

  .datepicker-out .lb-datepicker.lb-input {
    //width: 117px;
  }

  .draggable-block {
    max-width: 945px;
  }
}
.lb-account-summary {
  .available-balance {
    @include fontSize(12px);
  }
  .blocked-amount {
    @include fontSize(12px);
  }
  .balance {
    @include fontSize(12px);
  }
}

.available-balance {
  @include fontSize(13px);
}
.blocked-amount {
  @include fontSize(13px);
}
.balance {
  @include fontSize(13px);
}

@media screen and (max-width: 1450px) {
  .available-balance {
    @include fontSize(12px);
  }
  .blocked-amount {
    @include fontSize(12px);
  }
  .balance {
    @include fontSize(12px);
  }

  .lb-account-summary {
    padding: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .draggable-block {
    max-width: 885px;
  }

  .lb-block-title {
    font-size: 0.8125rem;
  }
}
.block-unblock {
  .popup-row-style i {
    width: 30px;
    height: 25px;
  }
  i.top-card {
   // width: 24px;
    height: 16px;
  }
  i.limit-card {
   // width: 22px;
    height: 18px;
  }
}
.card-update {
  .popup-row-style i {
    width: 16px;
    height: 16px;
  }
}
.choose-cards-wrapper {
  display: inline-block;
  width: 100%;
  .card-row-item {
    position: relative;
    .card-img {
      width: 80px;
      position: relative;
      .label-digital {
        position: absolute;
        width: 30px;
        height: auto;
        left: -7px;
        top: -12px;
        img {
          width: 16px;
        }
      }
      img {
        width: 100%;
      }
    }
    .card-info {
      padding-left: 15px;
      width: 85%;
      display: inline-block;
      h4 {
        font-size: 14px;
        margin: 0;
        color: #484848;
        font-family: 'Liberty-Nus Medium', Arial, sans-serif;
      }
      p {
        margin-top: 3px;
        font-size: 12px;
        line-height: 1;
        color: #484848;
        font-family: 'Liberty-Nus Medium', Arial, sans-serif;
      }
      span {
        font-size: 12px;
        display: inline-flex;
        line-height: 1;
        margin: 0;
        color: #484848;
        position: relative;
        bottom: 2px;
      }
    }
  }
}

//  new popup row style
.popup-row-style {
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  transition: 0.2s;
  cursor: pointer;
  border-bottom: 1px solid #e9e9f1;
  border-left: 1px solid transparent;

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: #fff;
    border-left: 1px solid #db0011;
    cursor: pointer;
  }
  i.icn-arr-right {
    height: 14px;
    width: 10px;
    opacity: 0;
    color: $nav-red;
  }
  &:hover i.icn-arr-right {
    opacity: 1;
  }

  span {
    flex: 1;
    @include fontSize(15px);
    color: #000;
    @include fontFamily('Liberty-Nus Medium');
  }

  i {
    text-align: center;
    color: $dark;
    transition: 0.2s;
    width: 25px;
    height: 26px;
  }

  .icn-list-template {
    margin-right: 1.5625rem;
  }

  &.choose-card {
    padding: 0.75rem 1.5rem;

    .img-template {
      margin-right: 1.5625rem;
      width: 77px;
      height: 49px;
      position: relative;
      .label-digital {
        position: absolute;
        width: 30px;
        height: auto;
        left: -7px;
        top: -12px;
        img {
          width: 16px;
        }
      }
      img {
        width: 100%;
      }
    }
    .card-name {
      color: $black;
    }
    .card-iban {
      color: #484848;
    }

    &:first-child {
      border-top: 1px solid #e9e9f1;
      margin-top: 20px;
    }
    &:last-child {
      border-bottom: 1px solid #e9e9f1;
      margin-bottom: 20px;
    }
  }
}

.choose-action {
  .icn-warnings-red {
    width: 22px;
    height: 19px;
  }
}
//  new popup row style end

// fixed notification with animation
.fixed-notification {
  position: fixed;
  bottom: 3vh;
  right: 4vh;
  padding: 1.25rem 2.6rem 1.25rem 1rem;
  color: $white;
  border-radius: $borderRadius;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  z-index: 11;

  &.show {
    animation-name: animScale;
  }

  &.hide {
    animation-name: animScaleOut;
  }

  .icn.icn-close {
    position: absolute;
    top: 0.9rem;
    right: 0.75rem;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .icn.icn-status {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 1rem;
  }

  .notif-text {
    @include fontSize(14px);

    @include fontFamily('Liberty-MT Medium');
  }
}
// global-notification
.Toastify__toast-container {
  width: auto !important;
  max-width: 450px;
}
.Toastify__close-button {
  opacity: 1 !important;
  box-shadow: none !important;
  outline: none;
  border: none;
}

.Toastify__toast--warning {
  padding: 8px !important;
}
.Toastify__toast {
  min-height: 80px !important;

  border-radius: $borderRadius;
}

.Toastify__progress-bar {
  height: 10px !important;
}
.global-notification {
  padding: 5px;
  color: $white;

  .icn.icn-close {
    position: absolute;
    top: 0.9rem;
    right: 0.75rem;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .icn.icn-status {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 1rem;
  }
  .notif-text {
    @include fontSize(13px);

    @include fontFamily('Liberty-MT Medium');
  }
}

@keyframes animScale {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}

@keyframes animScaleOut {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 40px, 0) scale3d(0.1, 0.6, 1);
  }
}
// fixed notification with animation end

//globalsettings

.global-settings {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 5;
  @include fontSize(8px);
  .bb {
    border-bottom: 1px solid #969899;
  }
}

.br-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

@media screen and (max-height: 649px) {
  .utility-popup {
    .lb-popup-row {
      &.popup-row-lg {
        padding: 0.5rem 1.5625rem;
      }
    }
    .lb-popup-footer {
      padding: 0.5rem 1.5625rem 1.5625rem;
    }
  }
}
@media screen and (max-height: 550px) {
  .transfer-modal {
    .lb-block-body {
      height: 400px;
      overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 414px) {
  .never-showgain {
    flex-direction: column;
  }
  .never-showgain_btn {
    margin-top: 10px;
  }

  .lb-popup-row {
    &.popup-row-lg {
      padding: 0.9375rem 0.9375rem;
    }
  }
}

.lb-api-err {
  @include fontSize(14px);
  @include fontFamily('Liberty-Nus');
  color: $nav-red;
  text-align: left;
  line-height: 1.3;

  .icn {
    width: 30px;
    height: 30px;
  }
}
.icn.icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 21px;
  height: 21px;
  padding-top: 3px;
  cursor: pointer;
  padding-left: 4px;
  transition: 0.3s;

  svg {
    height: 14px;
  }
}
.lb-newsms-code {
  margin-bottom: 15px;
  .icn {
    width: 19px;
    height: 19px;
    color: #707070;
  }
  .lb-btn-newcode {
    @include fontSize(13px);
    @include fontFamily('Liberty-MT Medium');
    color: #626263;
    border: none;
    background-color: transparent;
    padding: 0;
    height: auto;
  }
}

.lb-btn.lb-btn-new {
  min-width: 160px;
}
.authorization-block {
  .flex-col {
    flex-direction: row !important;
    .flex {
      order: 2;
    }
    .lb-newsms-code {
      order: 1;
      margin-top: 0 !important;
      margin-right: 20px;
    }
  }
}
.icn.icn-dark-circle {
  background: #e3e3e3;
  border-radius: 50%;
  color: #242424;
  width: 21px;
  height: 21px;
  padding-top: 3px;
  cursor: pointer;
  padding-left: 4px;
  transition: 0.3s;

  svg {
    height: 14px;
  }

  &:hover {
    background: #242424;

    svg {
      color: #fff;
    }
  }
}

// restriction card transfer modal
.switch-rail {
  width: 2rem;
  height: 1rem;
  background: $lang-switcher-bg;
  border-radius: 8px;
  margin: 0 0;
  position: relative;
  cursor: pointer;

  .switch-rail-dot {
    position: absolute;
    top: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    box-shadow: 15px 13px 34px 0 rgba(0, 0, 0, 0.1);
    //transform: translateX(1rem);
  }

  &.active .switch-rail-dot {
    background: $icn-dark;
    transform: translateX(1rem);
  }
  &.not-active .switch-rail-dot {
    background: $white;
    transform: translateX(0);
  }
}

.grid-details {
  justify-content: space-between;
  padding: 0 25px;
  //margin-bottom: 20px;

  .grid-details-box {
    padding: 10px 0 3px;
    border-bottom: 1px solid #e6e6e6;

    h3 {
      @include fontSize(14px);
      @include fontFamily('Liberty-Nus Bold');
      font-weight: normal;
      margin-bottom: 6px;
    }

    &:last-child {
      border-bottom: none;
      padding: 10px 0 3px;
    }

    .grid-details-col-group {
      display: flex;
      align-items: flex-start;
      margin-bottom: 7px;

      .icn-chat {
        svg {
          width: 27px;
        }
      }
      .grid-details-col-lable {
        @include fontSize(15px);
        @include fontFamily('Liberty-Nus Medium');
        color: #242424;
      }

      .grid-details-col {
        line-height: 23px;
        color: #242424;
        overflow-wrap: break-word;
        word-wrap: break-word;
        .lb-input {
          height: 40px;
        }
        .print-box {
          padding: 1.5625rem 1.875rem;

          span {
            font-weight: bold;
            color: $dark;
            @include fontSize(14px);
          }
        }
        .lb-tooltip {
          max-width: 400px;
        }
      }
    }
  }
}

.loader-authoriz {
  position: relative;
  width: 100%;
  top: -27px;
}

/* =========== Mobile Check modal  ========== */

.lb-mobile-check {
  padding: 22px 24px;
  .lb-check-left {
    background-color: #eaeaea;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    margin: 5px 20px 0 0;

    .icn-mobile-check {
      width: 40px;
      height: 40px;
    }
  }
  .lb-check-right {
    h3 {
      @include fontFamily('libery-Nus bold');
      @include fontSize(15px);
      margin-bottom: 5px;
    }

    ul {
      @include fontSize(14px);
      line-height: 24px;
      li {
        &::before {
          content: '';
          background: #db0011;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 12px;
        }
      }
    }
  }
}

//uploade file

.lb-uploade-block {
  padding: 1.5rem;
  .dzu-dropzone {
    min-height: 163px !important;
    background-color: $white;
    border: 2px dashed #c9ced6 !important;
    overflow: hidden !important;
    margin-bottom: 2rem;

    .dzu-inputLabel {
      font-size: 14px;
      @include fontFamily('Liberty-Nus medium');
      font-weight: normal !important;
      color: $black !important;
    }

    .dzu-previewContainer {
      border: none !important;
      margin-top: auto;
      margin-bottom: auto;
      padding: 0 !important;
      justify-content: center !important;
      .dzu-previewFileName {
        font-size: 14px;
        @include fontFamily('Liberty-Nus medium');
      }
    }
  }

  .checkbox-wrapper {
    .checkmark {
      width: 16px;
      height: 16px;
    }

    .nowrap {
      white-space: normal;
      flex: 1 1;
    }
  }
}
// new transactions design
.cat-icn {
  width: 32px;
  height: 32px;
  svg {
    width: 32px;
    height: 32px;
  }
}
.lb-transactions-container {
  .lb-block-subhead {
    padding: 1rem 1.5rem;
    border-bottom: none;
    @include fontSize(12px);
    background: #f3f3f3;
  }
  .lb-block-body {
    padding: 0 !important;

    .lb-block-row {
      padding: 1rem 1.5rem;

      .icn-arr-right {
        color: #b0b0b5;

        &:last-child {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: right;
          height: 24px;
          width: 24px;
          right: 16px;
          display: flex;
          justify-content: center;
          svg {
            width: 6px;
            height: 10px;
          }
        }
      }

      &:hover,
      &.active {
        .icn-arr-right {
          color: #db0011;
        }
      }
      &:not(:last-child) {
        border-bottom: none !important;

        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #e9ecf2;
          position: absolute;
          bottom: 0;
          left: 68px;
        }
      }
    }
  }
  .lb-block-row,
  .lb-block-subhead {
    & > :not(:last-child) {
      padding-right: 0 !important;
    }
  }
}

// my Finances
.lb-progress {
  width: 100%;
  &.lb-progressfin {
    width: auto;
    .progress-bar {
      &__track {
        width: 626px;
        overflow: hidden;
        background-color: #e5e5e5;
      }
    }
  }
  .pbar-cont {
    width: 697px;
    margin-right: 12px;
  }
  .progress-bar {
    &__track {
      //width: 86%;
      height: 12px;
      background-color: #d9d9e6;
      position: relative;
      border-radius: 0px 6px 6px 0px;
    }

    &__bar {
      //width: 100%;
      height: 12px;
      position: absolute;

      &.green {
        background: transparent
          linear-gradient(270deg, #2a985a 0%, #8ecba9 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px -1px 6px #2b995c1f;
        border-radius: 0px 6px 6px 0px;
        opacity: 1;
      }
      &.grey {
        background: transparent
          linear-gradient(270deg, #3e3d3d 0%, #9a9a9a 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px -1px 6px #5453531f;
        border-radius: 0px 11px 11px 0px;
        opacity: 1;
      }
    }
  }
}

.lb-myfincat-modal {
  .lb-block-body {
    padding: 26px 0 0;
  }
  .lb-myfincat-search {
    margin-bottom: 32px;
    padding: 0 24px;
    .lb-input-grey {
      background-color: #f2f2f2 !important;
      height: 56px;
      border-radius: 6px;
      border: 1px solid #e4e8eb;
    }
    .icn-search {
      width: 24px;
      height: 24px;
      right: 24px;
    }
  }

  .lb-myfincat-row {
    padding: 0 34px 0 24px;
    margin-bottom: 16px;
    .lb-myfincat-title {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      h3 {
        @include fontSize(14px);
        @include fontFamily('Liberty-Nus Bold');
        color: #000000;
        font-weight: normal;
        padding-left: 12px;
      }
      .cat-icn {
        width: 32px;
        height: 32px;
        svg {
          width: 32px;
          height: 32px;
        }

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .lb-subcat-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      margin: 0 0 0 40px;
      padding-bottom: 8px;
      border-bottom: 1px solid #e4e8eb;
      .lb-subcat {
        width: auto;
        display: inline-block;
        border: 1px solid #dadddf;
        border-radius: 16px;
        padding: 6px 12px;
        margin-bottom: 8px;
        margin-right: 8px;
        @include fontSize(12px);
        @include fontFamily('Liberty-Nus Medium');
        color: #000000;

        &.active {
          border: 1px solid #da0110;
          background-color: #fff5f6;
          color: #da0110;
        }
      }
    }
  }
}
