@import '../variables';

.transactions-page {
  .card-select-out {
    max-width: 360px;
    width: 50%;
    z-index: 5;
  }

  & > .lb-block {
    padding-bottom: 5vh;

    .lb-block-row:last-child {
      border-bottom: 1px solid #e9ecf2;
    }
    .icn-circle-tick,
    .icn-circle-close,
    .icn-circle-clock {
      position: relative;
      top: 3px;
    }
  }

  .lb-select {
    .Select-placeholder {
      color: $grey-select-option !important;
    }
  }

  &.smaller-form-group {
    .lb-input:not(.lb-input-grey):not(.has-error),
    .Select-control {
      border: 1px solid #d9d9e6;
    }

    .amountRange-input,
    .period-input {
      .lb-input {
        background: #fafafa;
        transition: 0.2s;

        &:focus {
          background: $white;
        }

        &::placeholder {
          color: #5d6163;
        }
      }
    }

    .lb-select:not(.month-select):not(.status-select) .Select-placeholder {
      @include fontFamily('Liberty-Nus');
      color: #5d6163;
    }
  }
  .lb-trans-block {
    .icn-arr-right {
      color: #b0b0b5;

      &:last-child {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: right;
        height: 24px !important;
        width: 24px !important;
        right: 16px !important;
        display: flex;
        justify-content: center;
        svg {
          width: 6px;
          height: 10px;
        }
      }
    }

    .lb-block-row {
      &:not(:last-child) {
        border-bottom: none !important;

        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #e9ecf2;
          position: absolute;
          bottom: 0;
          left: 68px;
        }
      }
    }
  }
}
.lb-trans-block {
  .icn-arr-right {
    color: #b0b0b5;

    &:last-child {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: right;
      height: 24px !important;
      width: 24px !important;
      right: 16px !important;
      display: flex;
      justify-content: center;
      svg {
        width: 6px;
        height: 10px;
      }
    }
  }

  .lb-block-row {
    &:not(:last-child) {
      border-bottom: none !important;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #e9ecf2;
        position: absolute;
        bottom: 0;
        left: 68px;
      }     
    }
  }
}
.scroll-top {
  z-index: 99;
  position: fixed;
  right: 25px;
  bottom: 25px;
  height: 38px;
  width: 38px;
  background: transparent;
  border: 1px solid #242424;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #db0011;
    border: 1px solid #db0011;
    color: $white;
  }
}

/* ========== transactions history ========== */

// .lb-block-subhead-transactions{

//     .lb-block-right{
//         display: flex;
//         align-items: center;
//         width: 100%;

//         .select-out{
//             width:172px;
//             margin-left: auto;
//         }

//         .filter-closed-btn{
//             margin-left: auto;
//         }

//     }
// }

.transactions-page {
  .lb-block-filter {
    padding: 2.5rem 5.1875rem;
    background: $bg-light;
    border-bottom: 1px solid #e9ecf2;

    .account-select-filter {
      max-width: 310px;
      width: 100%;
      height: 3.125rem !important;
    }

    .lb-select {
      height: 3.125rem !important;
      line-height: 1.5 !important;
      &.has-error {
        border: 1px solid $nav-red !important;
      }
    }
    .lb-input {
      height: 3.125rem !important;
      line-height: 3.125rem !important;
      &.has-error {
        border: 1px solid $nav-red !important;
      }
    }

    .lb-label {
      @include fontSize(14px);
    }

    .lb-input.lb-input-grey {
      border: 1px solid $search-color;
      background: $search-color;
    }

    .trans-cat{
      .lb-select .Select-input{
        line-height: 50px;
      }
    }
  }

  // &.container{
  //     margin-top: 6vh;
  // }

  .lb-block-head {
    .lb-input-search {
      width: 50%;
      max-width: 350px;

      .lb-input-grey {
        border: 1px solid $search-color;
      }
    }
  }

  .lb-block-subhead.lb-subhead-custom {
    padding: 1.875rem 1.875rem 1.5625rem;
    background: none;
  }
  .statements-summary {
    padding: 1rem 1.875rem !important;
    &__column {
      padding-right: 60px !important;
    }
  }
  .card-select-out {
    max-width: 360px;
    width: 50%;
  }

  .currency-select {
    max-width: 130px;
    width: 130px;
    height: 50px !important;
  }

  .lb-input-grey {
    background: #fff !important;
    border: 1px solid #d9d9e6; // !important;
    transition: 0.3s;

    .filled,
    &:focus {
      background: #f3f3f7 !important;
      color: $grey-select-option;

      & + .icn-search {
        color: #1d262f;
      }
    }
  }

  .amountRange-input {
    .lb-input {
      width: 100%;
      max-width: 130px;
    }
  }

  i.icn-calendar svg {
    width: 15px;
    height: 16px;
  }

  .period-input {
    .lb-input {
      // max-width: 150px;
      // width: 150px;

      &:not([value='']) {
        background: $white;
        border: 1px solid $grey-input-border;
      }
    }
  }

  .lb-select.product-select {
    border: 0;

    .Select-value,
    .Select-input {
      padding: 0 2rem 0 1rem !important;
    }

    .Select-placeholder {
      padding: 0 2rem 0 1rem !important;
    }
  }

  .search-word {
    margin-right: 84px;
    .lb-input {
      max-width: 770px;
      width: 770px;
    }
  }

  .filtered-btn {
    margin-top: 32px;
    .lb-btn.btn-red {
      padding: 0 3.75rem;
      width: 100%;
      height: 50px !important;
    }
  }

  .month-select {
    width: 170px;
  }

  .status-select {
    width: 240px;
  }

  .lb-block-filter-result {
    padding: 1.25rem 1.875rem;
    background: $bg-light !important;
    border-bottom: 1px solid #e9ecf2;
    color: $basic-grey;
    @include fontSize(13px);
    display: flex;
    justify-content: space-between;

    .icn {
      margin: 0 10px;
    }

    .remove-filter {
      @include fontSize(14px);
      font-weight: bold;
      .icn-dark-circle {
        background: #e3e3e3;
        border-radius: 50%;
        color: #242424;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-right: 11px;
        padding-top: 0;
        padding-left: 0;
        padding-left: 0;
        padding-top: 0;

        svg {
          width: 10px;
          height: 10px;
        }

        &:hover {
          background: #242424;

          svg {
            color: #fff;
          }
        }
      }

      &:hover {
        .icn-dark-circle {
          background: #242424;
          svg {
            color: #fff;
          }
        }
      }
    }
  }

  .lb-select + .lb-select {
    margin-left: 15px;
  }

  .icn-grey-circle {
    background: #c5c5c5;
    border-radius: 50%;
    color: $white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    transition: 0.2s;
    svg {
      width: 6px;
      height: 6px;
    }

    &:hover {
      background: #db0011;
    }
  }
  .lb-btn.btn-dark,
  .lb-btn.btn-white {
    width: 158px;
    height: 40px;
    @include fontSize(15px);
    display: flex;
    align-items: center;
  }
}

/* ==========  transactions history  End========== */

/* ==========  transactions Details popup ========== */

.transaction-details {
  justify-content: space-between;
  margin-top: 20px;
  //margin-bottom: 20px;
  .transaction-details-head {
    h3 {
      font-weight: bold;
      @include fontSize(16px);
      padding-bottom: 6px;
      @include fontFamily('Liberty-Nus Bold');
    }
  }

  .transaction-details-row {
    width: 100%;

    .transaction-details-box {
      //border-bottom: 1px solid #e9e9f1;
      padding: 0 1.8125rem;

      h3 {
        border-bottom: 1px solid #e9ecf2;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }

      .transaction-details-purpose {
        @include fontSize(16px);
        @include fontFamily('Liberty-Nus Bold');
      }

      &:last-child {
        border-bottom: none;
        padding: 0 1.8125rem;
      }

      h3 {
        @include fontSize(14px);
        @include fontFamily('Liberty-Nus Medium');
        font-weight: normal;
      }
      &:last-child {
        .transaction-details-col-group {
          margin-bottom: 0;
        }
      }
      .transaction-details-col-group {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;

        .icn-chat {
          svg {
            width: 27px;
          }
        }
        .transaction-details-col-lable {
          @include fontSize(14px);
          @include fontFamily('Liberty-Nus');
          color: #242424;
          opacity: 0.7;
        }

        .transaction-details-col {
          @include fontSize(14px);
          line-height: 15px;
          @include fontFamily('Liberty-Nus Medium');
          color: #242424;
          overflow-wrap: break-word;
          word-wrap: break-word;
          .lb-input {
            height: 40px;
          }
          .print-box {
            padding: 1.5625rem 1.875rem;

            span {
              font-weight: bold;
              color: $dark;
              @include fontSize(14px);
            }
          }
          .lb-tooltip {
            max-width: 400px;
          }
        }
      }

      //new transactions designs
      &.transaction-details-analytics {
        .transaction-details-col-group {
          align-items: center;
          margin-bottom: 16px;

          .transaction-details-col-lable {
            @include fontSize(14px);
            @include fontFamily('Liberty-Nus Medium');
            color: #7b7e80;
            opacity: 1;
          }
        }
        .transaction-details-cat-box {
          width: fit-content;
          background-color: #f7f8f9;
          border: 1px solid #eaeaeb;
          border-radius: 24px;
          padding: 8px;

          display: flex;
          align-items: center;

          .icn {
            width: 32px;
            height: 32px;
            svg {
              width: 32px;
              height: 32px;
            }
          }

          img {
            width: 32px;
            height: 32px;
          }
          span {
            @include fontSize(12px);
            @include fontFamily('Liberty-Nus Medium');
            color: #242424;
            padding-left: 8px;
            padding-right: 4px;
          }
        }
      }

      .switch-rail {
        width: 48px;
        height: 30px;
        border-radius: 15px;
        background-color: #e5e5e5;
        &.active {
          background-color: #2ba15e;
          .switch-rail-dot {
            background-color: #fff;
          }
        }
        .switch-rail-dot {
          width: 26px;
          height: 26px;
          top: 2px;
          right: 19px;
        }
      }
    }
  }
}

//new transactions designs
.print-block{
  position: absolute;
  right: 32px;
}
.transaction-details-footer {
  padding: 32px 32px 24px 32px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

 .tr-btn {
    width: fit-content;
    background-color: #eaeaeb;
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 9px 16px;
    color: #242424;
    @include fontSize(12px);
    @include fontFamily('Liberty-Nus Medium');
    cursor: pointer;
    margin-right: 24px;
    margin-bottom: 8px;
    &:last-child {
      margin-right: 0;
    }
    .icn {
      width: 20px;
      height: 20px;
    }
  }
}

.border-l {
  width: 1px;
  height: 35px;
  background-color: #e9e9e9;
}
/* ==========  transactions Details popup End========== */

.lb-popup {
  overflow-y: auto;
}
.current-tasks-slider {
  position: absolute !important;
  top: 70px;
  margin-bottom: 20px;
}

.payroll-transfer {
  .block-filter {
    @include fontSize(14px);
    font-weight: bold;
    .icn-dark-circle {
      background: #e3e3e3;
      border-radius: 50%;
      color: #242424;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-right: 11px;
      padding: 0;
    }
    .icn-delet {
      svg {
        width: 10px;
        height: 10px;
      }

      &:hover {
        background: #242424;

        svg {
          color: #fff;
        }
      }
    }
    .icn-edit {
      svg {
        width: auto !important;
        height: 14px !important;
        padding-left: 5px;
      }

      &:hover {
        background: #242424;

        svg {
          color: #fff;
        }
      }
    }
    .icn-block-edit {
      &:hover {
        .icn-dark-circle {
          background: #242424;
          svg {
            color: #fff;
          }
        }
      }
    }
    .icn-block-delet {
      &:hover {
        .icn-dark-circle {
          background: #242424;
          svg {
            color: #fff;
          }
        }
      }
    }
  }
  .lb-block-row {
    .loader-out {
      .loader {
        margin-left: auto;
      }
    }
  }
}


