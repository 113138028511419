@import '../variables';

/* =========== Select Styles ========== */
// .Select-input {
//   display: none !important;
// }

//  wrapper for error and loading handling
.select-wrap {
  position: relative;
  width: 100%;
  // z-index: 4;
}

.lb-select {
  text-align: left;
  border-radius: $borderRadius;
  outline: none;
  &.bank-select {
    .Select-menu-outer {
      max-height: 170px;
    }
  }
  .Select-menu-outer {
    max-height: 260px;
  }

  // this class is needed when we want to hide input when typing (e.g. when dropdown is over input)
  &.hide-input .Select-input input {
    width: 0 !important;
    padding: 0 !important;
  }

  // hide input caret on edge (and everywhere)
  // this is a hack
  &:not(.no-arrow):not(.is-open) .Select-input input {
    position: absolute;
    top: -1000px;
    left: -1000px;
  }

  // classes for option elements
  .sl-large-bold {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(14px);
    color: $basic-grey;
  }

  .sl-info-light {
    @include fontFamily('Liberty-Nus');
    @include fontSize(12px);
    color: $txt-light-grey;
  }

  .sl-info {
    @include fontFamily('Liberty-Nus');
    @include fontSize(12px);
    color: $basic-grey;
  }

  .sl-info-m {
    @include fontFamily('Liberty-Nus Medium');
    @include fontSize(12px);
    color: $basic-grey;
  }

  .sl-small-bold {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(12px);
    color: $basic-grey;
  }

  &.select-transparent,
  &.select-transparent-full {
    .Select-value-label {
      .sl-large-bold,
      .sl-info-light,
      .sl-info,
      .sl-info-m,
      .sl-small-bold {
        color: $white;
      }
    }
  }

  .disabled-text {
    line-height: normal;
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.2s;
    white-space: normal;
  }

  .disabled {
    cursor: default;

    & > img,
    & > div,
    & > i {
      opacity: 0.6;
    }

    .icn.toggle-favorite:hover {
      color: #242424 !important;
    }

    .lb-tooltip {
      display: none;
    }
  }

  .disabled:hover {
    .template-details {
      opacity: 0 !important;
    }

    .disabled-text {
      opacity: 1;
      z-index: 0;
    }

    .month-details {
      opacity: 0;
      transition: 0.2s;
    }
  }

  .Select-arrow-zone {
    // z-index: 2;
  }

  .Select-value {
    display: flex;
    align-items: center;
  }

  .Select-value-label {
    width: 100%;
  }

  .option-element {
    line-height: normal;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    &.sl-large-bold {
      margin-bottom: 2px;
    }
  }

  // value!!!
  .selected-option {
    line-height: normal;

    .value-element {
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }

    .transfer-img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      //background: $icon-red;
      color: #fff;
      margin-right: 0.9375rem;
    }

    .icn-star {
      width: 10px;
      height: 10px;
    }
  }

  .Select-option-group-label {
    @include fontFamily('Liberty-MT');
    @include fontSize(12px);
    background: #eff1f4;
    color: $txt-light-grey;
  }

  .Select-control {
    height: 100%;
    border-radius: $borderRadius;
    box-shadow: none !important;
  }

  .Select-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    @include fontSize(14px);
    @include fontFamily('Liberty-Nus');
    cursor: pointer;

    .flex-space {
      height: 100%;
    }
  }

  .Select-option,
  .Select-option.is-focused {
    color: $basic-grey;
  }
  .Select-option.is-selected {
    background-color: transparent;
  }

  .Select-input {
    width: 100%;
  }

  &.is-disabled {
    .Select-value-label {
      color: #c9cacc !important;
      @include fontFamily('Liberty-Nus');
    }

    .Select-control {
      background: #fff !important;
    }
  }

  &.bank-select.is-disabled {
    .Select-value {
      background: #eff1f4;
      z-index: 5;
    }

    .Select-value-label {
      color: #828282 !important;
    }
  }

  &.is-open {
    .Select-control {
      background: #fafafa;
    }

    .Select-value {
      //background: #FAFAFA;
    }
    .Select-arrow-zone .icn-arrow-down {
      transform: rotate(180deg);
      position: relative;
      top: -2px;
    }

    .Select-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .Select-placeholder {
      font-family: 'Liberty-Nus', Arial, sans-serif !important;
      color: $basic-grey !important;
    }

    // DEFAULT RED BORDERS!
    &:not(.no-border) {
      .Select-control {
        // box-shadow: 0 0 10px rgba(0,0,0,0.1) !important;
        // border-top: 1px solid $icon-red !important;
        // border-left: 1px solid $icon-red !important;
        // border-right: 1px solid $icon-red !important;
        border-top: 1px solid #a5a5b1 !important;
        border-left: 1px solid #a5a5b1 !important;
        border-right: 1px solid #a5a5b1 !important;
        border-bottom: 1px solid #a5a5b1 !important;
        border-bottom-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .Select-menu-outer {
        border-top: 0 !important;
        top: calc(100% - 2px);

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 1px;
          width: calc(100% - 2px);
          height: 1px;
          background: $label-grey;
        }
      }

      .lb-scrollbar {
        // border-bottom: 1px solid $icon-red;
        // border-left: 1px solid $icon-red;
        // border-right: 1px solid $icon-red;
        border-bottom: 1px solid $grey-input-border;
        border-left: 1px solid $grey-input-border;
        border-right: 1px solid $grey-input-border;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    // DEFAULT RED BORDERS! end
  }

  .Select-menu-outer {
    border-radius: $borderRadius;
    border: 0;
    box-shadow: none;
  }

  .Select-menu {
    outline: none;
    max-height: 300px;
    border-radius: $borderRadius;
    box-shadow: 0 10px 30px rgba(70, 74, 78, 0.1);
  }

  .select-option {
    display: flex;
    align-items: center;
  }

  .select-option,
  .select-option.is-focused {
    background: #fff;
  }

  .select-option:hover {
    background: $icon-red;
    color: #fff;
  }

  &.account-select .select-option:hover,
  &.no-border .select-option:hover {
    background: #fafafa;
    color: $basic-grey;

    .icn-add-person {
      background: $icon-red;
      color: #fff;
    }
  }

  &.is-open {
    .Select-control {
      .Select-arrow-zone {
        display: none;
      }
    }
  }

  .Select-arrow-zone {
    font-size: 0;
    padding-right: 1rem !important;

    .icn-arrow-down {
      height: 7px;
      width: 13px;
      color: $darker-grey;
      position: relative;
      top: 2px;
      transition: 0.2s;
    }
  }

  .Select-arrow {
    border: 0;
  }

  // ---------------------  ACCOUNT SELECT ----------------- //
  &.account-select {
    .Select-input {
      // height: auto !important;
      background: transparent !important;
    }

    .account-img-out {
      position: relative;
      width: 5rem;
      height: 3.125rem;
      margin-right: 0.625rem;

      .account-img {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      // if there are 2 or more cards
      &.lb-image-2 {
        .account-img {
          width: 70%;
          height: auto;
          position: absolute;

          &:first-child {
            top: 0;
            left: 0;
          }

          &:last-child {
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    .account-img {
      width: 5rem;
      height: 3.125rem;
      margin-right: 0.625rem;
      // background: #A8102C; // remove later
    }

    .Select-noresults {
      display: none;
    }

    .Select-arrow-zone {
      z-index: 1;
    }

    .Select-control {
      outline: none;
      border: 0;
    }

    .Select-menu-outer {
      left: -1px;
      width: calc(100% + 2px);
      top: 0;
      z-index: 2;
      // border: 1px solid transparent;
    }

    .lb-scrollbar {
      border-radius: $borderRadius;
    }

    .Select-input input {
      opacity: 0;
    }

    .Select-option-group-label {
      padding: 0.5rem 0.9375rem;
    }

    .select-option {
      padding: 0.625rem 0.9375rem;

      .account-detailes {
        // .account-friendlyname {
        //     @include fontSize(14px);
        //     color: $basic-grey;
        // }

        // .account-grey {
        //     @include fontSize(12px);
        //     color: $txt-light-grey;
        // }

        // .account_amount {
        //     @include fontSize(12px);
        // }
      }

      .icn.toggle-favorite {
        align-self: center;
        font-size: 0;
        margin-left: auto;
        width: 14px;
        height: 14px;
      }
    }

    .select-option:not(:last-child) {
      border-bottom: 1px solid $border-grey-2;
    }

    // in transfers
    &.is-disabled {
      .Select-value .Select-value-label {
        color: #c9cacc !important;
      }
    }
  }

  // product select - detail pages
  &.product-select {
    //border: 1px solid $label-grey;
    width: 100%;
    max-width: 360px;
    // height: 3.25rem; // 52px;

    .Select-placeholder {
      color: #5d6163;
      padding: 0 2rem 0 1rem;
    }

    .select-option {
      .account_amount {
        color: $icon-red;
      }
    }

    .icn.toggle-favorite {
      color: $icon-red;
    }
  }

  &.is-open .simple-select-option {
    .select-option-label {
      padding: 0 1.25rem;
      @include fontSize(14px);
      @include fontFamily('Liberty-Nus Medium');
    }
  }

  .simple-select-option .Select-value-label {
    width: 95%;
    outline: none;
  }

  // template select - second default select
  &.grey-border {
    .Select-control {
      border-color: #e4e6ea;
      border: 1px solid #d9d9e6;
    }

    .Select-menu {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .select-option {
      min-height: 50px;
      border-left: 2px solid #fff;
      &:hover {
        background: #fafafa;
        color: #5d6163;
        border-left: 2px solid #db0011;
      }
    }
  }

  .add-person {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(14px);
  }
}

// ---------------------  Transfers SELECT ----------------- //

// transfer select - default styles for transfer page

.transfer-select {
  .template-details {
    transition: 0.2s;
  }

  .select-option.transfer-to {
    padding: 1.25rem 0.9375rem;

    // &:first-child {
    //   border-bottom: 0;
    // }

    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      color: #fff;
      margin-right: 0.9375rem;
      border: 1px solid #949494;
      // padding: 4px;
    }

    .icn-add-person {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      color: $basic-grey;
      margin-right: 0.9375rem;
      background: $nav-grey;
      transition: 0.3s;

      svg {
        width: 13px;
        height: 13px;
      }
    }
  }

  &.is-open {
    .Select-value {
      display: none;
    }
  }

  // .Select-clear-zone{
  //     display: none;
  // }
}

.transfers-block {
  .trasfarents-disabled.is-disabled .Select-control {
    background: transparent !important;
    border: 1px solid $transparent-disabled !important;
    .Select-placeholder {
      color: #61686e;
    }
  }
  .transfer-select {
    margin-left: auto;
    margin-right: auto;

    .Select-control {
      background: transparent !important;
      border: 1px solid #e4e6ea !important;
    }

    // .select-option{
    //     padding-left: 28px;
    // }

    .Select-placeholder,
    .Select-input {
      padding: 0 2rem 0 1.6875rem;
    }

    .account_amount {
      color: $basic-grey;
    }

    .icn.toggle-favorite {
      color: #242424;
    }
  }

  .transfer-select:not(.type-select) {
    &.is-focused .Select-control {
      background: transparent !important;
      border: 1px solid #e4e6ea !important ;
    }
  }
}

// if transfer-block is active!!!!
.transfers-block.active {
  .Select-control {
    border: 1px solid #fff;
  }

  .Select-arrow-zone .icn-arrow-down {
    color: #fff;
  }

  .Select-placeholder {
    color: #fff;
    @include fontFamily('Liberty-Nus Bold');
  }

  h3 {
    @include fontFamily('Liberty-MT Bold');
  }
}

// select with search
.type-select {
  .Select-control {
    border: 1px solid $label-grey;
    outline: none;
  }
  &.focus-red {
    height: 50px;
  }
  &.focus-red.is-focused {
    // border: 1px solid #DB0011;
    background: #eff1f4 !important;
  }

  &.focus-red.is-focused:not(.is-open) > .Select-control {
    border-color: transparent;
  }

  .Select-input {
    // these two lines must be tested in every select
    position: relative;
    z-index: 1;
    height: 100%;

    input {
      padding: 14px 0;
      font-size: 0.875rem;
      line-height: 1.5;
      //height: 100%;
      width: 100% !important;
      opacity: 1;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      //cursor: pointer;
    }
  }

  .Select-menu-outer {
    top: calc(100% + 1px);
    z-index: 2;
    // border-top: 1px solid $label-grey;
  }

  .icn-search {
    display: none;
    color: #b7b6b8;
  }

  &.is-open {
    .Select-control {
      background: #fff !important;
    }

    .Select-placeholder {
      color: #969899;
      @include fontFamily('Liberty-Nus');
      @include fontSize(14px);
      background: #f3f3f7;
    }

    .icn-arrow-down {
      display: none;
    }

    .Select-menu-outer {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .icn-search {
      display: block;
    }
  }

  .transfer-to:not(:last-child) {
    border-bottom: 1px solid $border-grey-2;
  }

  .tax-PayerName {
    color: $txt-light-grey;
    @include fontFamily('Liberty-Nus');
  }

  .Select-clear-zone {
    display: none;
  }

  .Select-option {
    .no-templates {
      cursor: auto !important;
      margin: auto;
    }
  }
}

// transparent background
.select-transparent {
  .Select-control {
    background: transparent;
  }

  .select-option {
    padding-left: 0.9375rem;
  }
  .Select-menu {
    border-radius: 0;
  }

  &.is-focused,
  &.has-value {
    background: #fff;

    .Select-arrow-zone .icn-arrow-down {
      color: #717073;
    }
  }
}

// ALWAYS transparent background
.select-transparent-full {
  .Select-control,
  &.account-select .Select-control {
    background: transparent !important;
  }
}

// no elements
.no-arrow {
  .Select-arrow-zone {
    display: none;
  }

  .Select-placeholder,
  .Select-input,
  .Select-value {
    width: 100%;
    padding: 0 1.25rem;
  }

  .Select-loading-zone,
  .Select-clear-zone {
    right: 1rem;
  }

  .Select-control {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }

  .Select-menu-outer {
    top: calc(100% - 4px);
  }
}

// default - menu top
.default-select {
  .Select-control {
    outline: none;
    border: 0;
  }

  .Select-menu-outer {
    left: -1px;
    width: calc(100% + 2px);
    top: 0 !important;
  }

  .Select-input input {
    opacity: 0;
  }

  .select-option {
    padding: 0.625rem 0.9375rem;
  }

  .icn-search {
    display: none;
  }

  &.border {
    .Select-control {
      border: 1px solid $grey-input-border;
    }
  }

  // is must open on top and have red borders
  &.red-top-select {
    &.is-open:not(.no-border) .lb-scrollbar {
      border-radius: $borderRadius;
      // not needed on exchange page - if smth is wrong on other pages, need correction
      // border-top: 2px solid #A8102C !important;
    }
  }
}

.lb-popup-row .lb-select {
  .Select-control {
    height: 3.125rem;
    border-radius: $borderRadius;
  }

  &.is-focused .Select-control {
    border: 1px solid #a5a5b1 !important;
  }

  &.transcountry-select {
    &.has-error {
      &.is-focused .Select-control {
        border: 1px solid transparent !important;
      }
    }
    &.no-error {
      border: 1px solid transparent !important;
    }
  }
}

.no-options {
  &:hover {
    background: #fff !important;
    cursor: default;
  }
  .select-option-label {
    flex: 1;
  }
}

.lb-select.month-select,
.lb-select.status-select {
  @include fontFamily('Liberty-Nus');
}

.lb-select.custom-select .Select-menu-outer,
.lb-select.select-code .Select-menu-outer {
  top: 0 !important;
  z-index: 5;

  &:after {
    display: none !important;
  }

  .Select-option {
    &:first-child {
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
    }

    .is-selected {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.product-select-out {
  width: 100%;
  max-width: 360px;
  position: relative;

  .product-select,
  .card-select-out {
    width: 100%;
    max-width: none;
  }

  & + .lb-select {
    margin-left: 15px;
  }

  .select-wrap {
    z-index: 4;
  }
}

.statment-select-out {
  width: 360px;
  position: relative;

  .product-select {
    width: 100%;
    max-width: none;
  }

  & + .lb-select {
    margin-left: 15px;
  }
}

/* =========== ERRORS ========== */
// select error
.error-select {
  @include fontSize(14px);
  width: 85%;
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: $white;
  padding-top: 10px;
  margin-left: 45px;

  .icn.icn-warning {
    margin-top: 4px;
    color: $white;
    svg {
      width: 23px;
      height: 23px;
    }
  }
}

// select box type error
.select-wrap {
  .lb-select.bordered-error {
    .Select-control {
      border: 2px solid $icon-red !important;
    }
  }

  .lb-select.bordered-error.is-open {
    .Select-control {
      border: 3px solid #fff !important;
    }
  }

  .select-err-msg {
    @include fontSize(14px);
    padding: 8px 10px;
    text-align: left;
    margin-top: -3px;
    background: $icon-red;
    color: #fff;
  }
}
.lb-all-select {
  &.lb-select-error {
    .Select-control {
      border: 1px solid $icon-red !important;
    }
  }
}

.select-budget {
  .select-option {
    min-height: 50px;
    border-left: 2px solid #fff;
    &:hover {
      background: $nav-grey;
      color: $icon-red;
      border-left: 2px solid #db0011;
    }
  }

  .lb-scrollbar {
    border: none !important;
  }
}

// not transparent selects in transfers
.white-select {
  .Select-placeholder,
  .Select-arrow-zone .icn-arrow-down {
    color: $basic-grey !important;
  }
}

.larger-form-group {
  .white-select {
    .Select-control,
    &.is-open:not(.no-border) .Select-control {
      border: 0 !important;
    }

    .Select-placeholder {
      font-size: 14px;
    }

    .Select-option {
      padding: 0.625rem 1.0625rem;
      min-height: auto !important;
    }

    .Select-menu-outer:after {
      display: none;
    }

    .Select-value {
      background: $bg-light;
    }

    .lb-scrollbar {
      border: 0 !important;
    }
  }
}

.lb-select.select-counter {
  width: 100px;

  .Select-value {
    padding-left: 20px;
  }

  .select-option {
    padding-left: 20px;
  }
}

.country-select {
  z-index: 6;
  .Select-value:focus {
    outline: none;
  }
}

.icon-info {
  top: 48% !important;
  right: 15px;
  z-index: 6;

  svg {
    color: #b2b2b2;
    width: 20px;
    height: 20px;
    transition: 0.3s;
  }

  &:hover {
    svg {
      color: #242424;
    }
  }
}

.transfers-form .lb-tooltip {
  max-width: 328px;
}

.lb-tooltip-text {
  h6 {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(13px);
    padding-bottom: 8px;
  }
  p {
    @include fontSize(12px);
    margin-bottom: 16px;
  }
}
/* =========== Select Styles End ========== */

// utility providers select in standing orders component
.providers-select {
  display: flex;
  align-items: center;

  .select-option {
    .select-option-image {
      width: 50px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
}
