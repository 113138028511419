@import '../variables';

/* utility management */

.empty-utility-block {
  padding: 50px;
  color: #000;
  @include fontSize(16px);
  @include fontFamily('Liberty-Nus Medium');

  &.empty-utility-block-inside {
    padding: 0 50px;
  }
  .empty-utility {
    border: 1px dashed #dfdfdf;
    width: 100%;
    min-height: 330px;
    padding-top: 35px;
  }

  .empty-utility-bg {
    background-position: center center;
    background-repeat: no-repeat;
    height: 250px;

    .lb-btn {
      &:hover {
        .icn.icn-red-circle {
          svg {
            color: $white;
          }
        }
        span {
          color: $white;
        }
      }
    }
  }
}

.empty-utility-cart-block {
  padding: 0 0;
  color: #000;
  @include fontSize(16px);
  @include fontFamily('Liberty-Nus Medium');

  .empty-utility-cart {
    margin-bottom: 20px;
  }

  .empty-utility-cart-list {
    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #efefef;
      padding: 13px 5px;

      &:last-child {
        border-bottom: none;
      }
    }
    .icn {
      width: 32px;
      height: 32px;
    }
    span {
      @include fontSize(16px);
      @include fontFamily('Liberty-MT Medium');
      padding-left: 16px;
    }
  }
}

.loading-utility {
  position: absolute;
  bottom: -9px;
}

.utility-block {
  display: flex;
  justify-content: space-between;
  min-height: 700px;

  .utility-main {
    width: 100%;
    min-width: 830px;
    max-width: 945px;
    .lb-block-head {
      background-color: #fff;
      margin-bottom: 0;
      border-bottom: 1px solid #e4e6ea;
      height: 48px !important;
      min-height: 48px !important;
    }
  }

  .utility-cart {
    background-color: #e9e9e9;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    max-width: 375px;
    min-width: 350px;
    width: 100%;
    padding: 15px 22px;

    .utility-head-title {
      margin-bottom: 28px;
    }

    .utility-cart-basket {
      background-image: url(../../images/basket.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 200px 160px;
      min-height: 600px;
      &.fixed {
        position: fixed;
        top: 140px;
        width: 330px;
      }
    }

    // .utility-basket {
    //   &.fixed {
    //     position: fixed;
    //     top: 140px;
    //     width: 330px;
    //   }
    // }
  }

  .lb-select.utility-select {
    height: 90px;
    border: 1px solid #edeeee;

    .Select-value {
      padding: 0 2.5rem 0 1rem !important;
    }

    .Select-placeholder {
      padding: 0 2.5rem 0 1rem !important;
      color: #242424;
    }

    .Select-arrow-zone {
      padding-right: 1.2rem;
    }

    .selected-option .friendly-name {
      @include fontFamily('Liberty-MT Bold');
      @include fontSize(15px);
      display: inline-block;
    }

    .receiver-iban {
      @include fontFamily('Liberty-MT Bold');
      @include fontSize(11px);
      color: #74777a;
    }

    .balance-amount {
      @include fontFamily('Liberty-MT Bold');
      @include fontSize(11px);
      color: #464a4e;
    }

    .Select-placeholder {
      @include fontSize(16px);
      @include fontFamily('Liberty-Nus Medium');
      padding-left: 20px;
    }
    .account-detailes {
      .po-r {
        min-height: 31px;
      }
    }
  }

  .utility-head {
    padding: 0 10px 10px 38px;

    .lb-input-search {
      width: 50%;
      max-width: 380px;

      .lb-input-grey {
        background: $search-input;
        color: #000;
        transition: 0.3s;
        padding-right: 35px;

        .filled,
        &:focus {
          background: #f3f3f7 !important;
          color: $grey-select-option;
          color: #000;

          & + .icn-search {
            color: #1d262f;
          }
        }

        &::-webkit-input-placeholder {
          color: #000;
        }
        &::-moz-placeholder {
          color: #000;
        }
        &:-ms-input-placeholder {
          color: #000;
        }
        &:-moz-placeholder {
          color: #000;
        }
      }
    }
  }

  .template-add-box {
    @include fontSize(14px);
    color: #000;
    outline: none;
    @include fontFamily('Liberty-MT Medium');
    transition: 0.3s;
    cursor: pointer;
    .icn {
      margin-right: 2px;
      margin-top: 4px;
    }

    .icn-red-circle {
      svg {
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      color: $icon-red;
      svg {
        color: $icon-red;
      }
    }
  }

  .utility-head-title {
    @include fontFamily('Liberty-MT Medium');
    @include fontSize(16px);
    color: $dark;
    margin-bottom: 25px;
  }

  .utility-cart-info {
    @include fontFamily('Liberty-Nus');
    @include fontSize(14px);

    h3 {
      @include fontFamily('Liberty-Nus Bold');
      @include fontSize(16px);
      padding-bottom: 25px;
    }

    .error-box {
      @include fontSize(12px);
      .error-quick {
        i {
          margin-right: 10px;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  .draggable-utility-item {
    outline: none;
    //border-top: 1px solid #F0F0F0;

    &:last-child {
      //border-bottom: 1px solid #F0F0F0;
    }
  }

  .utility-block-row {
    padding-bottom: 55px;

    &.panel-bottom {
      padding-bottom: 0;
    }

    .checkAll-block {
      padding: 0 33px 15px 38px;
      .btn-allcheck {
        font-size: 12px;
        height: 30px;
        padding: 8px 17px;
        width: 132px;
        font-family: 'Liberty-MT', Arial, sans-serif;
      }
    }
  }
}

.utility-row {
  display: flex;
  padding: 3px 0;
  position: relative;
  min-height: 76px;
  background: #fff;
  border: 1.5px dashed transparent;

  &.utility-row-tag {
    border: 1.5px dashed #d9d9e6;
    border-radius: 2px;
  }

  & {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
  }
}

.draggable-utility-item:nth-child(odd) .utility-row {
  background: #fafafb;
  //border: 1.5px dashed transparent;
}

.utility-img-out {
  margin-left: 38px;
  margin-right: 25px;
  height: 70px;
  width: 100px;

  .utility-row-logo {
    width: 94px;
    height: 100%;
    object-fit: cover;
    max-width: none;
  }
}

.btn-block {
  width: 24%;
  padding-right: 33px;
  align-items: center;
  .lb-btn {
    width: 132px;
    height: 30px;
    color: $black;
    @include fontFamily('Liberty-MT');
    @include fontSize(12px);
  }
}

.utility-row-info {
  max-width: 470px;
  width: 100%;
  margin-right: 5px;
  padding-top: 0;
  padding-right: 25px;

  .valid-text {
    @include fontFamily('Liberty-Nus');
    @include fontSize(12px);
    color: #888888;
  }
  .template-name {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .__react_component_tooltip {
    z-index: 9;
  }

  .provider-name-block {
    max-width: 360px;
  }

  // .identifier {
  //   max-width: 135px;
  //   width: 100%;
  // }

  .icn-trust {
    width: auto;
    height: auto;
    svg {
      width: auto;
      height: auto;
    }
  }
}

// .provider-name-block {
//   max-width: 330px;
// }

.utility-row-price {
  @include fontFamily('Liberty-MT Medium');
  @include fontSize(16px);
  color: $green;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .option-provider-select {
    .Select-value {
      @include fontSize(12px);
    }

    .Select-menu {
      @include fontSize(12px);
    }

    .select-option {
      min-height: 30px;
    }
  }

  .provider-select-block {
    width: 137px;

    .icn.icn-arrow-down {
      right: -9px;
    }
  }
}

.Loading-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.utility-input-out {
  font-size: 0.75rem;
  width: 25%;
  align-items: center;

  .icn-close {
    width: 26px;
    height: 30px;
    margin: 0 0 0 2px;
    text-align: center;

    svg {
      width: 10px;
    }
  }
  .symbol-l {
    position: absolute;
    top: 7px;
    right: 18px;
    @include fontFamily('Liberty-Nus Bold');
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .min-amount {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(11px);
    color: $nav-red;
    text-align: right;
    padding: 0 36px 3px 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .lb-input-grey {
    background: $search-input;
    border: 1px solid #dddee2;
    width: 132px;
    height: 30px;
    @include fontFamily('Liberty-MT');
    padding-right: 42px;
    color: #000;
    transition: 0.3s;

    .filled,
    &:focus {
      background: #fff !important;
      color: #000 !important;
      box-shadow: 0 0 16px rgba(95, 95, 84, 0.16);
    }

    &::-webkit-input-placeholder {
      color: #000;
    }
    &::-moz-placeholder {
      color: #000;
    }
    &:-ms-input-placeholder {
      color: #000;
    }
    &:-moz-placeholder {
      color: #000;
    }
  }

  .lb-select.select-parking {
    width: 132px;

    .Select-input {
      height: 30px;
    }

    .Select-menu-outer {
      top: 0 !important;
    }
    .Select-arrow-zone {
      padding-right: 0 !important;
    }
    .Select-arrow-zone .icn-arrow-down {
      width: 10px;
    }

    &.is-focused .Select-control {
      border-color: #dddee2;
    }
  }
}

.utility-more-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.2rem;
  right: 15px;
  cursor: pointer;
}

.utility-more-info span {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: $dark;
}

.utility-more-info span + span {
  margin: 2px 0 0 0;
}

.utility-popup {
  .lb-popup-row {
    border-bottom: 0 !important;
  }

  .popup-total-block {
    border-top: 1px solid $border-grey-2;
    padding: 1.25rem 1.5625rem 0 1.5625rem;
  }

  .popup-block-border {
    border-bottom: 1px solid $border-grey-2 !important;
  }
}

// utility-template-page

.utility-template-page {
  .lb-template-subhead {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    padding-left: 2rem;

    .lb-back {
      display: flex;
      align-items: center;
      @include fontSize(14px);
      cursor: pointer;
      @include fontFamily('Liberty-Nus Medium');

      .icn {
        width: 4px;
        height: 7px;
        margin-right: 10px;
      }
    }
  }

  .template-head-title {
    width: 60%;
    @include fontFamily('Liberty-MT Bold');
  }

  .lb-block-body {
    margin: 35px 53px;
    padding-bottom: 100px;
  }
  .search-block {
    margin-bottom: 40px;
    .lb-input-search {
      display: inline-block;
      .lb-input {
        width: 420px;
        height: 50px;
        color: #000;

        .filled,
        &:focus {
          background: $search-input !important;
          color: $grey-select-option;
          color: #000;

          & + .icn-search {
            color: #1d262f;
          }
        }

        &::-webkit-input-placeholder {
          color: $placeholder-grey;
        }
        &::-moz-placeholder {
          color: $placeholder-grey;
        }
        &:-ms-input-placeholder {
          color: $placeholder-grey;
        }
        &:-moz-placeholder {
          color: $placeholder-grey;
        }
      }
    }
  }

  .lb-utility-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    .lb-utility-col {
      display: flex;
      align-items: center;
      width: 32%;
      margin: 0 20px 25px 0;
      height: 120px;
      color: #2a2a2a;
      //text-align: center;
      @include fontFamily('Liberty-MT Medium');
      @include fontSize(16px);
      border-radius: $borderRadius;

      &:nth-child(3n + 0) {
        margin-right: 0;
      }
    }

    /* utility-image Id for Specific icon  size  */

    .lb-utility-black {
      background: #fafafa;
      padding: 40px 30px 40px 30px;
      transition: 0.3s;
      align-items: center;
      border: 1px solid #e5e5e5;
      box-shadow: 0 0 8px rgba(243, 243, 247, 1);
      @include animatedUnderline(1px, $nav-red);
      display: flex;

      &:after {
        bottom: -0.1rem !important;
        height: 2px !important;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:hover {
        background: #f4f4f8;

        .utility-image:first-child {
          opacity: 0;
        }

        .utility-image:last-child {
          opacity: 1 !important;
        }
      }

      span {
        padding-left: 15px;
      }
      .category-image {
        position: relative;
        width: 55px;
        height: auto;
        margin-right: 15px;
        display: flex;
        align-items: center;

        .utility-image {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          transition: 0.3s;

          // &:last-child{
          //     opacity: 0;
          // }
        }

        // .utility-image-1 {
        //   width: 41px;
        //   // height: 45px;
        // }

        // .utility-image-7 {
        //   width: 32px;
        //   // height: 48px;
        // }

        // .utility-image-16 {
        //   width: 42px;
        //   // height: 40px;
        // }

        // .utility-image-20 {
        //   width: 40px;
        // }

        // .utility-image-21 {
        //   width: 41px;
        //   // height: 43px;
        // }
        // .utility-image-22 {
        //   width: 34px;
        //   // height: 54px;
        // }

        // .utility-image-23 {
        //   width: 45px;
        //   // height: 36px;
        // }

        // .utility-image-24 {
        //   width: 30px;
        //   // height: 49px;
        // }

        // .utility-image-25 {
        //   width: 42px;
        //   // height:26px;
        // }
      }
    }

    .lb-utility-white {
      background: #fafafa;
      border: 1px solid #d9d9e6;
      padding-right: 20px;
      transition: 0.3s;

      &:hover {
        background: #f4f4f8;
      }

      .logo-box {
        // width: 134px;
        // height: 96px;
        width: 125px;
        height: 110px;
        margin: 12px 25px 12px 12px;
        border: 1px solid #f2f2f2;
        border-radius: $borderRadius;
        .utility-logo {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $borderRadius;
        }
      }
      .provider-name {
        @include fontFamily('Liberty-Nus Medium');
        @include fontSize(15px);
        color: $black;
        text-align: left;
        line-height: 20px;
        //padding-top:15px;
      }
    }
  }

  .lb-white-box {
    background: #fafafa;
    border: 1px solid #d9d9e6;
    padding-right: 20px;
    max-width: 375px;
    width: 100%;
    @include fontFamily('Liberty-Nus Medium');
    @include fontSize(16px);
    border-radius: $borderRadius;
    color: $black;
    transition: 0.3s;
    cursor: default;

    &:hover {
      background: #f4f4f8;
    }

    .utility-logo {
      width: 134px;
      height: 96px;
      margin: 12px 40px 12px 12px;
      border: 1px solid #f2f2f2;
      border-radius: $borderRadius;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $borderRadius;
      }
    }

    .provider-name {
      @include fontFamily('Liberty-Nus Medium');
      @include fontSize(14px);
      color: $black;
      text-align: left;
    }
  }

  .small-form-group {
    max-width: 375px;

    .lb-label {
      @include fontFamily('Liberty-Nus Bold');
      color: #000;
    }

    .lb-input {
      background: #eff1f4;
      border: 1px solid #d9d9e6;
      color: #000;
      @include fontFamily('Liberty-Nus Medium');
      height: 65px;
      @include fontSize(16px);

      .filled,
      &:focus {
        border: 1px solid #a5a5b1 !important;
        color: #000;
      }

      &::-webkit-input-placeholder {
        color: #000;
        @include fontSize(12px);
      }
      &::-moz-placeholder {
        color: #000;
        @include fontSize(12px);
      }
      &:-ms-input-placeholder {
        color: #000;
        @include fontSize(12px);
      }
      &:-moz-placeholder {
        color: #000;
        @include fontSize(12px);
      }
    }

    .input-text {
      @include fontFamily('Liberty-Nus Bold');
      @include fontSize(12px);
      color: #c6c6c6;
      padding-top: 5px;
    }

    .Select-placeholder {
      color: #000;
    }

    .Select-value {
      @include fontFamily('Liberty-Nus Medium');
    }
  }

  .customer_info {
    border: 1px solid #dbdde0;
    border-radius: 4px;
    padding: 24px;

    .customer_info-line {
      div {
        padding-bottom: 12px;
        color: #626262;
        line-height: 18px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

/*tag block*/

.tag-name {
  border: 1px solid #e4e6ea;
  background: $white;
  border-radius: 4px;
  padding: 0.5rem 0.3125rem;
  @include fontFamily('Liberty-Nus Medium');
  @include fontSize(12px);
  margin: 0 5px 5px 0;
  transition: 0.3s;
  // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  &:before {
    content: '';
    background: #db0011;
    height: 32px;
    width: 1px;
    display: block;
    position: absolute;
    left: 0px;
    top: 2px;
    border-radius: 4px;
  }
  &:hover {
    background-color: #fdf0f1;
    border: 1px solid #f09097;
    cursor: pointer;
    color: #242424;

    &:before {
      content: none;
    }
  }

  &:focus {
    outline: none;
  }
  & {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
  }
}

.tag-block {
  .lb-input {
    height: 36px;
    width: 132px;
    line-height: 50px;
  }

  .lb-btn.btn-red {
    height: 36px;
    @include fontSize(13px);
    padding: 0 0;
    min-width: 34px;
    .icn {
      width: 13px;
      //height: 7px;
      svg {
        color: $white;
      }
    }
  }

  .tag-edit {
    margin: 0 5px 5px 0;
  }

  .tag-active {
    .tag-name {
      background-color: #fdf0f1;
      border: 1px solid #f09097;
      cursor: pointer;
      color: #242424;
      // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.12);

      &:before {
        content: none;
      }
    }
  }

  .tag-drag {
    &:focus {
      outline: none;
    }
    &.is-dragging {
      .tag-btn {
        transform: rotate(2deg);
      }
    }
  }

  .opacity {
    opacity: 0.4;
    cursor: default !important;
    &:hover {
      color: currentColor;
      background-color: #fff;
    }
    .tag-name:hover {
      color: currentColor;
    }
  }
  .tag-btn {
    text-align: center;
    //   box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.2);

    &:focus {
      outline: none;
    }

    .tag-name {
      width: 170px;
    }
  }

  .tag-grid {
    padding-top: 17px;
    div:nth-child(5) {
      .tag-name {
        margin: 0 0 5px 0;
      }
    }
  }

  .not-tagged {
    .tag-name {
      background-color: #fff;
      border: 1px solid #dfdfdf;
      width: 169px;

      &:before {
        content: '';
        background: #242424;
        height: 32px;
        width: 1px;
        display: block;
        position: absolute;
        left: 0px;
        top: 2px;
        border-radius: 4px;
      }
      &:hover {
        border: 1px solid #242424;
        cursor: pointer;
        color: #242424;
      }
    }

    .opacity {
      .tag-name {
        cursor: default !important;
      }
    }
  }

  .nottag-active {
    .tag-name {
      background-color: #f4f4f8;
      border: 1px solid #242424;

      cursor: pointer;
      color: #242424;

      &:before {
        content: none;
      }

      &:hover {
        border: 1px solid #242424;
      }
    }
  }

  .tag-control {
    position: absolute;
    right: 3px;
    top: -3px;
    transform: translateY(-50%);

    .icn-dark-circle {
      background: #e3e3e3;
      border-radius: 50%;
      color: #242424;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-right: 2px;
      transition: 0.3s;

      svg {
        width: 10px;
        height: 10px;
      }

      &:hover {
        background: #242424;

        svg {
          color: #fff;
        }
      }
    }

    .icn-edit {
      svg {
        width: 20px;
        height: 20px;
        padding-left: 4px;
      }
    }
  }
  .show-hide {
    // position: absolute;
    // right: -23px;
    // top: 93px;
    width: 15px;
    margin-left: auto;
    margin-top: 73px;
    height: 20px;

    .icn {
      width: 11px;
      color: #242424 !important;
    }
  }

  .show-newtag {
    height: 45px;
    padding: 30px 0 0 0;
  }

  .disable-addnewtag {
    .template-add-box {
      opacity: 0.4;

      &:hover {
        color: #242424;

        svg {
          color: #242424;
        }
      }
    }
  }
}

.current-tag {
  position: relative;
  background: #fff;
  border: 1px solid #db0011;
  border-radius: 4px;
  padding: 2px 5px;
  text-align: center;
  display: inline-flex;
  margin-top: 6px;
  color: #db0011;

  &:hover {
    color: $nav-red;
    cursor: pointer;
  }
}

.tag-delete {
  position: absolute;
  right: -10px;
  top: 0;
  transform: translateY(-50%);

  .icn-dark-circle {
    background: #e3e3e3;
    border-radius: 50%;
    color: #242424;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 2px;
    transition: 0.3s;

    svg {
      width: 5px;
      height: 5px;
    }

    &:hover {
      background: #242424;

      svg {
        color: #fff;
      }
    }
  }
}

.addtag-modal {
  overflow: visible !important;
}

/* @media query */

@media screen and (max-width: 1465px) {
  .utility-block {
    .utility-head {
      .tag-block {
        .tag-btn {
          .tag-name {
            width: 158px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .utility-block {
    .utility-block-row {
      .utility-row-info {
        max-width: 375px;
        padding-right: 37px;
        //margin-right: 20px;

        .identifier {
          width: 200px;
        }
      }
    }
  }

  .utility-row-info {
    max-width: 375px;
    padding-right: 47px;
    //margin-right: 20px;
  }

  .identifier {
    width: 150px;
  }
}

@media screen and (max-width: 1395px) {
  .utility-block {
    .utility-head {
      .tag-block {
        .tag-btn {
          .tag-name {
            width: 150px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .utility-block {
    .utility-block-row {
      .utility-row-info {
        max-width: 375px;

        //margin-right: 20px;

        .identifier {
          max-width: 160px;
          width: auto;
        }
      }
    }
  }

  .utility-row-info {
    max-width: 375px;
    //margin-right: 20px;
  }

  .identifier {
    width: 160px;
  }
}

@media screen and (max-width: 1366px) {
  .utility-block {
    .utility-head {
      .tag-block {
        .tag-btn {
          .tag-name {
            width: 150px;
          }
        }
      }
    }
  }
  .utility-row-info {
    .provider-name-block {
      max-width: 250px;
    }
  }
}

@media screen and (max-width: 1320px) {
  .utility-block {
    .utility-head {
      .tag-block {
        .tag-btn {
          .tag-name {
            width: 147px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .utility-block {
    .utility-cart {
      .utility-cart-basket {
        &.fixed {
          position: static;
          width: auto;
          top: 0;
        }
      }
    }
    .utility-head {
      .tag-block {
        .tag-btn {
          .tag-name {
            width: 147px;
          }
        }
      }
    }
  }
}

.symbol-l {
  position: absolute;
  top: 7px;
  right: 18px;
  @include fontFamily('Liberty-Nus Bold');
}
.lb-navbar {
  .lb-badge {
    top: 9px;
    right: -18px;
  }
}
.lb-bottom-header {
  .lb-badge {
    width: 18px;
    height: 18px;
    top: 24px;
    right: -14px;
  }
}
.lb-badge {
  background-color: #f7ddda;
  width: 21px;
  height: 21px;
  @include fontSize(12px);
  color: #d8433d;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 9px;
  right: -18px;
  transform: translateY(-50%) translateX(50%);
  padding: 4px 5px;
  @include fontFamily('Liberty-Nus Bold');
}
.lb-badge-new {
  width: auto;
  height: 22px;
  right: -26px !important;
  border-radius: 8px !important;
}
.opacity-75 {
  opacity: 0.75;
}
