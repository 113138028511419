/* ========== container ========== */
.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1320px;
  // padding-right: 17px;
}

.lb-container {
  min-height: 100vh;
  padding: 116px 0 5vh;
  // min-height: calc(100vh - 116px);
  // margin: 116px 0 0 0;
  overflow: hidden;
  position: relative;
}

// scrollbar!
@media (min-width: 1280px) and (max-height: 700px) {
  .lb-container {
    padding: 116px 0 150px;
  }
}
@media (min-width: 1280px) {
  .scrollbars {
    height: 100vh !important;
  }
}

.track-vertical {
  position: absolute;
  z-index: 10;
  width: 6px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}

.thumb-vertical {
  height: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/* ========== END of container ========== */

/* ========== inactive-header ========== */
.inactive-header {
  position: static !important;
}

.inactive-container {
  margin: 0;
  padding: 0;
  min-height: auto;
}

/* ========== END of inactive-header ========== */
/* ========== header ========== */
.lb-header {
  margin-bottom: 30px;

  &.scroll {
    box-shadow: 0 0 16px rgba(95, 95, 84, 0.16);
  }
}
.lb-header-in {
  margin-bottom: 0;
  position: fixed;
  z-index: 11;
  width: 100%;
  min-width: 1270px;
}
div.lb-top-header {
  // background: $dark;
  background: #000;
  color: #fff;
  width: 100%;
}

div.lb-top-header .container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 0;
}

div.lb-top-header .helpfull-links a {
  @include fontFamily('Liberty-MT Bold');
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  color: #fff;

  &:hover {
    color: #fff;
  }

  .icn-help {
    color: #fff;
  }
}
.lb-header-in .lb-top-header .container {
  padding: 0;
  height: 36px;
}
.lb-main-header {
  background-color: #fff;
  padding: 30px;
}
.lb-header-in .lb-main-header {
  padding: 0;
}
.lb-main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lb-header-in .lb-main-header .container {
  height: 80px;
  justify-content: flex-start;
}

.lb-bottom-header {
  background: #ffffff;
  margin-bottom: 3vh;
  position: relative;
  z-index: 4;

  &.open {
    & + .lb-dashboard {
      margin-top: calc(50px + 3vh);
    }
  }

  & > .container {
    padding: 0;
    height: 50px;
  }

  &.lb-header-dashboard {
    background-color: #e6e6e6;
    background-position: 100% 0;
    background-repeat: no-repeat;
    height: 50px;
  }

  &:not(.lb-header-dashboard) {
    box-shadow: 0 0 16px rgba(95, 95, 84, 0.16);
    border-top: 1px solid #e6e8eb;
  }
}

.lb-bottom-header .container {
  display: flex;
  align-items: center;
}

.lb-header-dashboard .container {
  padding: 10px 0;
}
/* =========== END of header =========== */

/* =========== Footer =========== */
.lb-footer {
  background: #ffffff;
  color: $dark;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
}

.lb-footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}
/* =========== END of Footer =========== */

// ----------------- FLEX GRID ------------------- //
.lb-dashboard .lb-block {
  // overflow: hidden;
  margin-bottom: 0;
}

.lb-block {
  border-radius: $borderRadius;
  background-color: #fff;
  margin-bottom: 30px;

  // block styles
  &.st-mixed {
    display: flex;
    & > :first-child {
      width: 30%;
    }

    & > :last-child {
      width: 70%;
    }

    .lb-block-row {
      padding: 0.9375rem 1.875rem 0.9375rem 1.875rem;
    }

    .account-list {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      .draggable-item {
        &:first-child {
          border-top-right-radius: 4px;
          .lb-block-row {
            border-top-right-radius: 4px;
          }
        }
      }
      .draggable-item {
        &:last-child {
          border-bottom-right-radius: 4px;
          .lb-block-row {
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }

  // block styles end

  // block modules styles
  .lb-subhead-statment {
    background: none !important;
    color: #464a4e !important;
    padding: 1.0625rem 1.875rem !important;

    .lb-select .Select-input {
      height: 40px;
    }
  }

  .lb-block-subhead {
    @include fontFamily('Liberty-Nus Bold');
    @include fontSize(13px);
    padding: 0.9375rem 1.5rem;
    border-bottom: 1px solid $border-grey-2;
    background: #f5f5f5;
    color: $title-grey;

    .is-card-title {
      @include fontSize(16px);
      @include fontFamily('Liberty-MT Bold');
      color: $basic-grey;
      text-transform: uppercase;
    }

    .total-debt {
      @include fontSize(13px);
      @include fontFamily('Liberty-MT');
      color: $basic-grey;
      text-transform: uppercase;
      margin-right: 20px;
    }
  }

  .lb-block-row,
  .lb-block-subhead {
    & > :not(:last-child) {
      padding-right: 15px;
    }
  }
}

.App.ka {
  .is-card-title,
  .total-debt {
    text-transform: none;
  }
}
.App.en-US {
  .is-card-title,
  .total-debt {
    text-transform: none;
  }
}

.lb-block-head {
  display: flex;
  min-height: 60px;
  height: 60px;
  padding: 0 1.875rem;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #e9e9f1;
  background-color: #fafafa;

  &.hidden-head {
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.lb-block-row,
.lb-block-subhead,
.lb-block-head {
  display: flex;
  align-items: center;
}

.lb-block-row {
  padding: 1rem 1.5rem;
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid $border-grey-2;
  }
  &.pointer-default {
    transition: 0.2s;

    .icn-arr-right {
      transition: 0.2s;
    }

    &:hover,
    &.active {
      background: rgba(243, 243, 243, 0.44);

      .icn-arr-right {
        color: $nav-red;
      }
    }
  }
  &.block-pointer {
    transition: 0.2s;

    .icn-arr-right {
      transition: 0.2s;
    }

    &:hover,
    &.active {
      background: rgba(243, 243, 243, 0.44);

      .icn-arr-right {
        color: $nav-red;
      }
    }
  }
}

.lb-block-row:last-child {
  border-bottom: none;
}

.lb-block:not(.st-mixed) .lb-summary-top {
  width: calc(35% - 1.875rem / 2);

  .lb-block-title {
    flex: 1;
  }

  .hidden-icon {
    margin-right: 20px;
  }
}

.lb-block:not(.st-mixed) .Other-Blocked {
  width: calc(40% - 1.875rem / 2);
}

@media screen and (max-width: 1366px) {
  .container {
    width: 92%;
  }

  .lb-header-in {
    margin-bottom: 0;
    position: fixed;
  }
}

@media screen and (max-width: 1024px) {
  .lb-header-in {
    position: relative;
    margin-bottom: 1px;
  }

  .lb-container {
    padding-top: 0;
  }
}

.welcome-screen-slider-enter {
  opacity: 0.01;
}
.welcome-screen-slider-enter-active {
  opacity: 1;
  -webkit-transition: opacity 0.9s ease-in-out;
  -moz-transition: opacity 0.9s ease-in-out;
  -ms-transition: opacity 0.9s ease-in-out;
  -o-transition: opacity 0.9s ease-in-out;
  transition: opacity 0.9s ease-in-out;
}
.welcome-screen-slider-exit {
  display: none;
}
.welcome-screen-slider-exit-active {
  display: none;
}

$margin-gen-list: 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 20 22 24 25 26
  28 30 32 34 36 35 40 42 44 45 46 48 50 56 58 60 64 65 70 75 76 80 85 90 95 96
  100 120 150 200;
@each $margin-gen in $margin-gen-list {
  .m-#{$margin-gen} {
    margin: #{$margin-gen}px;
  }
  .mt-#{$margin-gen} {
    margin-top: #{$margin-gen}px !important;
  }
  .mb-#{$margin-gen} {
    margin-bottom: #{$margin-gen}px;
  }
  .mr-#{$margin-gen} {
    margin-right: #{$margin-gen}px;
  }
  .ml-#{$margin-gen} {
    margin-left: #{$margin-gen}px !important;
  }
  .p-#{$margin-gen} {
    padding: #{$margin-gen}px;
  }
  .pt-#{$margin-gen} {
    padding-top: #{$margin-gen}px;
  }
  .pb-#{$margin-gen} {
    padding-bottom: #{$margin-gen}px;
  }
  .pr-#{$margin-gen} {
    padding-right: #{$margin-gen}px;
  }
  .pl-#{$margin-gen} {
    padding-left: #{$margin-gen}px;
  }
}
