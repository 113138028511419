// new variables
$white: #fff;
$black: #000;
$basic-grey: #242424;

$icn-dark: #242424;

$light-grey: #e1e1de;
$light-grey2: #737679;
$light-grey3: #bfbfbf;
$title-grey: #555c63;
$medium-grey: #8d9195;

$bg-light: #fafafa;
$bg-nav: #f6f6f6;
$bg-exchange: #0c0c0c;
$bg-modal: #f7f8f9;
$bg-drag-row: #dcdcdd;

$active-lang: #171827;
$lang-switcher-bg: #e3e3e3;

// old variables
$transparent-grey: #96999b;

$green: #34ac34;
$icn-green: #2db528;
$new-green: #3ab23a;

$border-grey: #dbdbdc;
$border-grey-2: #e9ecf2;
$border-grey-3: #dedede;
$border-grey-4: #e9e9f1;
$border-grey-5: #949494;
$border-grey-6: #343c44;

$disabled-grey: #8e9397;

$nav-grey: #e3e3e3;
$nav-border: #e9e9f0;

$dark-subnav: #1c252e;

$dark: #1d262f;
$hover-dark: #444c54;
$circleColor: #f2d2d5;
// palitra colors

$grey: #c9cacc;
$dark-grey: #909498;
$darker-grey: #717073;
$label-grey: #d9d9e6;
$grey-filled-input: #edeef3;

$light-red: #db0011;
// $nav-red: #C70039;
$nav-red: #db0011; // new color
$red: #a21c32;
$dark-red: #711213;
$darker-red: #4a0011;
$wrn-red: #e70d2f;
$icon-red: #db0011; // #A8102C;
$error-red: #c61e3f;
$hover-red: #c92e2f;
$simple-red: #db0011;

$delet-color: #a5a5b1;
$transfer-transparent: #d0d3d9;

//  placeholder
$placeholder-grey: #969899;
$icon-grey: #dcdee2;
$icon-grey-2: #7e848b;
$eye-icon-grey: #afb2b5;
$search-input: #fafafb;
$search-color: #d9d9e6;
$txt-light-grey: #74777a;
$txt-lightt: #717171;
$yellow: #fabf25;

$transparent-disabled: #61686e;

// additional colors
$grey-input-border: #e4e6ea;
$grey-input-bg: #f4f4f8;
$grey-input-bg2: #efeff3;
$grey-input-color: #6d6d6d;
$grey-select-option: #5d6163;
$icon-grey-bg: #e6e6e6;

// border default radius
$borderRadius: 4px;
$savings-grey: #9a9a9a;

.light-grey {
  color: #b8b8b8;
}
.txt-steelGrey {
  color: #777;
}
.txt-grey {
  color: #464a4e;
}
.txt-blue {
  color: #3b6a98;
}
.is-grey {
  color: #74777a;
}
.is-lightgrey {
  color: #717171;
}
.is-morelight {
  color: #464a4e;
  opacity: 0.5;
}
.more-grey {
  color: #919191;
}

.thelight-grey {
  color: #9a9a9a;
}

.lightgrey {
  color: #b4b4b4;
}

.dis-grey {
  color: #adadad !important;
  opacity: 0.5;
}

.basic-grey {
  color: #242424;
}
// convert px to rem
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  // font-size: $size;
  font-size: calculateRem($size);
}

@mixin iconSize($size) {
  // width: $size;
  width: calculateRem($size);

  // height: $size;
  height: calculateRem($size);
}

// END of convert px to rem

// animation on hover
@mixin animatedBorder($border) {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -1px;
    top: -$border;
    bottom: -$border;
    width: 1px;
    background: $nav-red;
    transform: scale(1, 0);
    transform-origin: 50% 50%;
    transition: 0.3s ease;
  }

  &:hover {
    &:after {
      transform: scale(1, 1);
    }
  }

  &.active {
    &:after {
      transform: scale(1, 1);
    }
  }
}

//Transition all
@mixin transitionAll($duration, $type) {
  transition: all $duration $type;
  -webkit-transition: all $duration $type;
  -moz-transition: all $duration $type;
  -ms-transition: all $duration $type;
  -o-transition: all $duration $type;
}

// add underline on hover
@mixin animatedUnderline($height, $color, $bottom: -0.3rem) {
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    bottom: $bottom;
    left: 0;
    right: 0;
    height: $height;
    background: $color;
    transform: scale(0, 1);
    transform-origin: 0% 50%;
    transition: 0.3s cubic-bezier(0.34, -0.13, 0, 1.06);
  }

  &:hover {
    &:after {
      transform: scale(1, 1);
    }
  }

  &.active {
    &:after {
      transform: scale(1, 1);
    }
  }
}

// remove underline on hover
@mixin animatedUnderlineOut($height, $color) {
  position: relative;
  display: inline-flex;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $height;
    background: $color;
    transform-origin: 100% 50%;
    transition: 0.3s cubic-bezier(0.34, -0.13, 0, 1.06);
  }

  &:hover {
    &:after {
      transform: scale(0, 1);
    }
  }

  &.active {
    &:after {
      transform: scale(0, 1);
    }
  }
}

@mixin progressBar() {
  @keyframes progress {
    from {
      width: 0%;
    }
    to {
      width: 70%;
    }
  }

  @-webkit-keyframes progress {
    from {
      width: 0%;
    }
    to {
      width: 70%;
    }
  }

  @keyframes progress-3 {
    // from {
    //   width: 3%;
    // }
    to {
      width: 100%;
    }
  }

  @-webkit-keyframes progress-3 {
    // from {
    //   width: 3%;
    // }
    to {
      width: 100%;
    }
  }
  /* animation */
}

@mixin fontFamily($font) {
  font-family: $font, Arial, sans-serif;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}
