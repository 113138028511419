@import '../variables';
@import '../component/utility.scss';
//@import '../component/transactions.scss';

.icn-export-wrap {
  .lb-export-btn {
    position: relative;

    ul {
      position: absolute;
      top: -3px;
      right: -2px;
      background: #fff;
      width: 80px;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
      height: auto;
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      // opacity: 0;
      // visibility: hidden;
      transition: all 0.2s;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;

      li {
        padding: 0 10px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-left: 2px solid transparent;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        color: #5d6163;
        font-size: 14px;

        &:hover {
          border-color: #db0516;
          background-color: #e4e4e4;
          color: #5d6163;
          transition: all 0.2s;
          -webkit-transition: all 0.2s;
          -moz-transition: all 0.2s;
          -ms-transition: all 0.2s;
          -o-transition: all 0.2s;
        }
      }
    }

    // &:hover {
    //   ul {
    //     opacity: 1;
    //     // visibility: visible;
    //     transition: all 0.2s;
    //     -webkit-transition: all 0.2s;
    //     -moz-transition: all 0.2s;
    //     -ms-transition: all 0.2s;
    //     -o-transition: all 0.2s;
    //   }
    // }
  }
  .lb-export-cont {
    .loader-out {
      position: absolute;
      z-index: 10;
      left: 11px;
      top: 12px;
    }
  }

  .lb-export-select {
    border: 1px solid #646464;
    width: 45px;
    .Select-arrow-zone {
      display: none;
    }
    .Select-placeholder {
      background-image: url('../../images/export.svg');
      background-position: center;
      background-repeat: no-repeat;
      text-indent: -99999px;
      background-size: 36%;
      padding: 0;
    }
    .Select-input {
      padding: 0 !important;
      width: 41px;
    }

    &.is-open:not(.no-border) .Select-menu-outer {
      min-width: 112px;
      left: -2px;
    }
  }

  .lb-pdf-select {
    border: 1px solid #646464;
    width: 45px;
    .Select-arrow-zone {
      display: none;
    }
    .Select-placeholder {
      background-image: url('../../images/print.svg');
      background-position: center;
      background-repeat: no-repeat;
      text-indent: -99999px;
      background-size: 36%;
      padding: 0;
    }
    .Select-input {
      padding: 0 !important;
      width: 41px;
    }

    &.is-open:not(.no-border) .Select-menu-outer {
      min-width: 112px;
      left: -2px;
    }
  }
}
.disabled {
  opacity: 0.6;
}
.transactions-page .corp-current-tasks,
.transactions-page .corp-batch-transfer {
  .lb-block-head {
    min-height: 75px;
  }

  .template-add-box {
    @include fontSize(14px);
    color: #000;
    outline: none;
    @include fontFamily('Liberty-MT Medium');
    transition: 0.3s;

    .icn {
      margin-right: 2px;
      margin-top: 5px;
    }

    .icn-red-circle {
      svg {
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      color: $icon-red;

      svg {
        color: $icon-red;
      }
    }
  }

  .period-input {
    .lb-input {
      background: $white !important;
    }
  }

  .amountRange-input {
    .lb-input {
      background: $white !important;
    }
  }

  .lb-btn {
    &:not(.btn-link) {
      //height: 2.5rem; // 40px
    }

    &.btn-add {
      padding: 0 0.875rem;
    }

    &.btn-dark,
    &.btn-white {
      width: auto;
    }

    &.btn-border {
      border: 1px solid $icn-dark;

      &:hover {
        border-color: transparent;
      }
    }

    &.btn-link {
      color: $icn-dark;
      @include fontFamily('Liberty-Nus Medium');

      &:hover {
        color: $nav-red;
      }
    }
  }

  .icn-info-new {
    transition: 0.3s;

    &:hover {
      color: #db0011;
    }
  }

  .icn-future-date {
    transition: 0.3s;

    &:hover {
      color: #db0011;
    }
  }

  .icn-corp-add {
    width: 13px;
    height: 13px;
    color: $nav-red;
  }

  .icn-corp-settings {
    width: 1.125rem;
    height: 1.125rem;
  }

  // filter
  .w-check {
    padding-right: 30px !important;
  }

  .lb-block-filter {
    padding: 1.25rem 3rem;

    .filter-details {
      .lb-form-group {
        &:first-child {
          width: 100%;
        }

        &:not(:nth-child(4)) {
          margin-right: 0;
        }
      }
    }

    .lb-form-group-footer {
      .lb-input {
        height: 2.8125rem !important;
      }

      .batch-details-btn {
        .sendcode-box {
          .lb-input {
            padding: 0;
          }
        }

        .lb-newsms-code {
          margin-top: 0 !important;
        }

        .loader-authoriz {
          top: -21px !important;
        }
      }
    }

    &.current-tasksfilter {
      .lb-search-group {
        height: 65px !important;
      }

      .lb-form-group {
        height: 100px;
      }

      .lb-select.is-open .Select-control {
        background: #f3f3f7;
      }

      .lb-select.is-open:not(.no-border) .Select-control {
        border-top: 1px solid #d9d9e6 !important;
        border-left: 1px solid #d9d9e6 !important;
        border-right: 1px solid #d9d9e6 !important;
        border-bottom: 1px solid #d9d9e6 !important;
      }
    }

    &.batch-filter {
      .lb-search-group {
        height: 65px !important;
      }

      .lb-form-group {
        height: 100px;

        .lbd-inputs {
          .datepicker-out {
            width: 100%;
          }
        }
      }

      .lb-select.is-open .Select-control {
        background: #f3f3f7;
      }

      .lb-select.is-open:not(.no-border) .Select-control {
        border-top: 1px solid #d9d9e6 !important;
        border-left: 1px solid #d9d9e6 !important;
        border-right: 1px solid #d9d9e6 !important;
        border-bottom: 1px solid #d9d9e6 !important;
      }
    }

    .country-select {
      z-index: 4;

      .Select-input {
        height: auto !important;
      }
    }
  }

  .lb-form-group-out {
    display: flex;
    align-items: center;

    .datepicker-out .lb-datepicker.lb-input {
      @include fontFamily('Liberty-Nus');
      @include fontSize(14px);
      padding: 0 0.875rem;
    }

    .lb-input-search .lb-input {
      @include fontSize(14px);
      line-height: 50px;
      padding-right: 35px;

      &::-webkit-input-placeholder {
        color: $grey-select-option;
      }

      &::-moz-placeholder {
        color: $grey-select-option;
      }

      &:-ms-input-placeholder {
        color: $grey-select-option;
      }

      &:-moz-placeholder {
        color: $grey-select-option;
      }
    }

    .checkbox-wrapper {
      @include fontSize(14px);
    }
  }

  .lb-form-group {
    width: 22%;

    &:first-child,
    &:nth-child(3) {
      width: 23%;
    }

    &:not(:nth-child(4)) {
      margin-right: 2.9%;
    }

    &:nth-child(4) {
      margin-left: auto;
    }
  }

  .lb-input-out {
    display: block;
  }

  .lb-datepicker {
    // width: auto;
    //max-width: 115px;
    //flex: 1;
  }

  .account-select-filter {
    width: auto;
    max-width: none;
  }

  .currency-select {
    width: auto;
    max-width: none;
  }

  .status-select {
    width: 100%;
  }

  .block-filter-footer {
    display: flex;
    align-items: center;

    .amountRange-input .lb-input {
      background: $white;
    }
  }

  .icn-corp-filter {
    position: relative;
    top: 2px;
  }

  .icn-calendar {
    // color: $nav-red;
    transition: 0.3s;

    svg {
      width: 19px;
      height: 17px;
    }
  }

  .lb-btn {
    font-family: 'Liberty-MT Medium', Arial, sans-serif;
    @include fontSize(14px);
  }

  .checkbox-wrapper .checkmark {
    width: 16px;
    height: 16px;
  }

  .lb-toolbar {
    padding: 32px;

    .lb-btn.btn-border.btn-white:disabled {
      color: #cbcbcb !important;
      background: #ececec !important;
      border: 1px solid transparent !important;
    }

    .lb-btn.btn-red:disabled {
      color: #cbcbcb !important;
      background: #ececec !important;
      border: 1px solid transparent !important;
    }

    .amount-range {
      width: 30%;
    }

    .lb-input.lb-input-grey {
      border: 1px solid $search-color;
    }
  }

  .icn-future-date {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    padding-right: 0 !important;
    height: 17px;
    width: 17px;
  }
  .icn-abso {
    position: absolute;
    top: 54%;
    left: 55px;
    transform: translateY(-50%);
    padding-right: 0 !important;
  }
  .lb-block-filter-result {
    display: flex;
    align-items: flex-start;
    background: $white;
  }

  // administer

  .icn.icn-admin {
    width: 21px;
    height: 21px;
  }

  .lb-manage-user {
    margin: 1.375rem 1.875rem;

    .lb-form-group {
      min-height: 102px;

      .lb-input {
        height: 50px;
        line-height: 50px;

        &::placeholder {
          color: #242424;
        }

        &.lb-input-error {
          border: 1px solid #db0011 !important;
        }
      }

      &.lb-username-group {
        width: auto;

        .lb-input {
          height: 50px;

          &.lb-username {
            width: 410px;
          }
        }

        .is-warrning {
          width: 410px;
          display: inline-block;
        }
      }

      .lb-select {
        height: 50px !important;
      }
    }
  }

  .lb-block-wizard {
    padding: 1.375rem 1.875rem;

    .nav {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;

      &.nav-wizard {
        position: relative;
        align-self: flex-start;
        background-color: #fff;
        overflow: hidden;
        width: 100%;

        .nav-item {
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          text-align: center;
          position: relative;
          display: flex;
          flex: 1 1 0;
          min-width: 120px;
          height: 60px;
          background: #fff;
          border: 1px solid #f0f0f0;
          border-right: 0;
          transition: 0.2s;

          &:first-child {
            padding-left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &:nth-child(1) {
            z-index: 4;

            &:before {
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(234, 234, 234, 0);
              border-width: 31px;
              margin-top: -31px;
              left: 100%;
              top: 50%;
              border-left-color: #f0f0f0;
              transition: 0.2s;
            }

            &:after {
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(255, 255, 255, 0);
              border-width: 29px;
              margin-top: -31px;
              top: calc(50% + 2px);
              left: calc(100%);
              border-left-color: white;
              transition: 0.2s;
            }

            .nav-link {
              &:first-child {
                padding-left: 25px;
              }
            }
          }

          &:nth-child(2) {
            margin: 0 15px;
            border-left: 0;
            z-index: 3;

            &:before {
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(234, 234, 234, 0);
              border-width: 31px;
              margin-top: -31px;
              left: 100%;
              top: 50%;
              border-left-color: #f0f0f0;
              transition: 0.2s;
            }

            &:after {
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(255, 255, 255, 0);
              border-width: 29px;
              margin-top: -31px;
              top: calc(50% + 2px);
              left: 100%;
              border-left-color: white;
              transition: 0.2s;
            }

            .nav-link {
              &:before {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(234, 234, 234, 0);
                border-width: 31px;
                margin-top: -31px;
                left: 0;
                top: 50%;
                border-left-color: #f0f0f0;
              }

              &:after {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-width: 29px;
                margin-top: -31px;
                top: calc(50% + 2px);
                left: 0;
                border-left-color: white;
              }
            }
          }

          &:nth-child(3) {
            border-left: none;
            z-index: 2;
          }

          &:last-child {
            padding-right: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-right: 1px solid #eaeaea;

            &:before {
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(234, 234, 234, 0);
              border-width: 31px;
              margin-top: -31px;
              left: 0;
              top: 50%;
              border-left-color: #f0f0f0;
            }

            &:after {
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(255, 255, 255, 0);
              border-width: 29px;
              margin-top: -31px;
              top: calc(50% + 2px);
              left: 0;
              border-left-color: white;
            }

            &.checked {
              position: relative;
              background: #eeeeef;

              &:before {
                border-left-color: #fff;
              }

              &:after {
                border-left-color: #fff;
              }
            }

            &.active {
              position: relative;
              background: #dcdddf;

              &:before {
                border-left-color: #fff;
              }

              &:after {
                border-left-color: #fff;
              }
            }

            &:hover {
              position: relative;
              background: #eeeeef;

              &:before {
                border-left-color: #fff;
              }

              &:after {
                border-left-color: #fff;
              }
            }
          }

          &.checked {
            position: relative;
            background: #eeeeef;

            &:before {
              border-left-color: #eeeeef;
            }

            &:after {
              border-left-color: #eeeeef;
            }
          }

          &.active {
            position: relative;
            background: #dcdddf;

            &:before {
              border-left-color: #dcdddf;
            }

            &:after {
              border-left-color: #dcdddf;
            }
          }

          &:hover {
            position: relative;
            background: #eeeeef;

            &:before {
              border-left-color: #eeeeef;
            }

            &:after {
              border-left-color: #eeeeef;
            }
          }

          .nav-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
            padding-left: 45px;
            color: #242424;

            .nav-item-step {
              @include fontSize(13px);
              @include fontFamily('Liberty-MT Medium');
            }

            .nav-item-title {
              @include fontSize(10px);
              @include fontFamily('Liberty-Nus Medium');
            }
          }
        }
      }
    }
  }
}

.reason-cancel-modal {
  min-height: 300px;

  .lb-grid {
    .lb-block-row {
      padding: 0.8125rem 0.9375rem;

      &:not(.p-0) > :nth-last-child(2) {
        padding-right: 30px;
      }
    }
  }
}

.save-template-modal {
  .lb-avatar-upload {
    .avatar-round {
      width: 95px;
      height: 95px;
    }

    &.corp-avatar-upload {
      padding: 25px 0;
    }
  }

  .checkbox-wrapper {
    .checkmark {
      width: 14px;
      height: 14px;
    }
  }
}

.batch-tasks-modal {
  .transaction-details-col {
    font-family: 'Liberty-Nus Medium' !important;
    padding-bottom: 10px;
  }
}

.settings-details {
  overflow: hidden;

  .corp-manage-user {
    #label-OpenBanking,
    #label-marketingTemplate {
      span {
        white-space: normal;
      }

      .checkmark {
        min-width: 20px;
      }
      a {
        color: #eb293e;
        text-decoration: underline;
      }
    }

    .loader-container {
      min-height: 40vh;
    }

    .role-footer {
      .profilename-input {
        width: 365px;
        height: 45px;
        line-height: 45px;
      }
    }

    .lb-btn {
      &:not(.btn-link) {
      }

      &.btn-add {
        padding: 0 0.875rem;
        position: absolute;
        top: -50px;
        right: 1.4%;

        &:hover {
          color: #db0011;
        }
      }

      &.btn-dark,
      &.btn-white {
        width: auto;
      }

      &.btn-border {
        border: 1px solid $icn-dark;

        &:hover {
          border-color: transparent;
        }
      }

      &.btn-link {
        color: $icn-dark;
        @include fontFamily('Liberty-Nus Medium');

        &:hover {
          color: $nav-red;
        }
      }
    }

    .checkmark {
      margin-left: 0 !important;
    }

    .checkbox-wrapper {
      span {
        order: 2 !important;
      }

      .checkmark {
        order: 1 !important;
      }
    }

    .icn-corp-add {
      width: 0.75rem;
      height: 0.75rem;
      // color: $nav-red;
    }

    .icn-corp-settings {
      width: 1.125rem;
      height: 1.125rem;
    }

    .lb-block {
      padding-bottom: 1vh;
    }

    .lb-user-head {
      min-height: 60px;
      height: 60px;
      padding: 0 1.875rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 1px solid #e9e9f1;
      background-color: #fff;
    }

    .lb-block-subhead {
      background: #e6e6e6;
    }

    .admin-row {
      background: #f5f5f5;
    }

    // filter
    .lb-block-filter {
      padding: 1.25rem 1.875rem;
    }

    .lb-form-group-out {
      display: flex;
      align-items: center;

      .datepicker-out .lb-datepicker.lb-input {
        @include fontFamily('Liberty-Nus');
        @include fontSize(14px);
        padding: 0 1rem;
      }

      .lb-input-search .lb-input {
        @include fontSize(14px);

        &::-webkit-input-placeholder {
          color: $grey-select-option;
        }

        &::-moz-placeholder {
          color: $grey-select-option;
        }

        &:-ms-input-placeholder {
          color: $grey-select-option;
        }

        &:-moz-placeholder {
          color: $grey-select-option;
        }
      }

      .checkbox-wrapper {
        @include fontSize(14px);
      }
    }

    .lb-form-group {
      &:nth-child(4) {
        margin-left: auto;
      }
    }

    .lb-input-out {
      display: block;
    }

    .lb-datepicker {
      width: auto;
      max-width: 115px;
    }

    .account-select-filter {
      width: auto;
      max-width: none;
    }

    .currency-select {
      width: auto;
      max-width: none;
    }

    .status-select {
      width: 100%;
    }

    .block-filter-footer {
      display: flex;
      align-items: center;
    }

    .icn-corp-filter {
      position: relative;
      top: 2px;
    }

    .icn-calendar {
      color: $nav-red;

      svg {
        width: 19px;
        height: 17px;
      }
    }

    .lb-btn {
      font-family: 'Liberty-MT Medium', Arial, sans-serif;
      @include fontSize(14px);
    }

    .lb-toolbar {
      padding: 32px;

      .lb-btn.btn-border.btn-white:disabled {
        color: #cbcbcb !important;
        background: #ececec !important;
      }

      .lb-btn.btn-red:disabled {
        color: #cbcbcb !important;
        background: #ececec !important;
        border: 1px solid transparent !important;
      }
    }

    .icn-future-date {
      padding-right: 35px;
    }

    .lb-block-filter-result {
      display: flex;
      align-items: flex-start;
      background: $white;
    }

    // administer

    .icn.icn-admin {
      width: 21px;
      height: 21px;
    }

    .lb-manage-user {
      margin: 1.375rem 1.875rem;

      .lb-form-group {
        min-height: 107px;
        margin-bottom: 0.3125rem;

        .lb-input {
          height: 50px;
          line-height: 50px;

          &::placeholder {
            color: #242424;
          }

          &.lb-input-error {
            border: 1px solid #db0011 !important;
          }
        }

        &.lb-username-group {
          .lb-input {
            height: 50px;

            &.lb-username {
              //width: 383px;
              text-transform: uppercase;
            }

            &.pr-120 {
              padding-right: 120px;
            }

            &.pr-40 {
              padding-right: 40px;
            }
          }

          .is-warrning {
            display: inline-block;
          }

          .icn-check-box {
            position: absolute;
            right: 10px;
            top: 10px;

            .btn-check {
              background: #1bb964;
              color: $white;
              width: 104px;
              height: 30px;
              font-size: 13px !important;
            }
          }

          .tooltip-parent {
            display: block !important;
          }
        }

        .lb-select {
          height: 50px !important;
        }
      }

      .lb-datepicker {
        width: 100% !important;
        max-width: inherit !important;
      }

      .lb-passw-block {
        padding: 0;

        .lb-select-out {
          max-width: none;
          width: 100%;

          .lb-select-numbrcode {
            width: 89px !important;

            .Select-control {
              height: 50px;
              width: 89px !important;
            }
          }

          .lb-phone-number {
            //width: 290px;
            height: 50px;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    .lb-block-wizard {
      padding: 1.375rem 1.875rem;

      .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;

        &.nav-wizard {
          position: relative;
          align-self: flex-start;
          background-color: #fff;
          overflow: hidden;
          width: 100%;

          .nav-item {
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            text-align: center;
            position: relative;
            display: flex;
            flex: 1 1 0;
            min-width: 120px;
            height: 60px;
            background: #fff;
            border: 1px solid #f0f0f0;
            border-right: 0;
            transition: 0.2s;

            &:first-child {
              padding-left: 0;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            &:nth-child(1) {
              z-index: 4;

              &:before {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(234, 234, 234, 0);
                border-width: 31px;
                margin-top: -31px;
                left: 100%;
                top: 50%;
                border-left-color: #f0f0f0;
                transition: 0.2s;
              }

              &:after {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-width: 29px;
                margin-top: -31px;
                top: calc(50% + 2px);
                left: calc(100%);
                border-left-color: white;
                transition: 0.2s;
              }

              .nav-link {
                &:first-child {
                  padding-left: 25px;
                }
              }
            }

            &:nth-child(2) {
              margin: 0 15px;
              border-left: 0;
              z-index: 3;

              &:before {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(234, 234, 234, 0);
                border-width: 31px;
                margin-top: -31px;
                left: 100%;
                top: 50%;
                border-left-color: #f0f0f0;
                transition: 0.2s;
              }

              &:after {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-width: 29px;
                margin-top: -31px;
                top: calc(50% + 2px);
                left: 100%;
                border-left-color: white;
                transition: 0.2s;
              }

              .nav-link {
                &:before {
                  border: solid transparent;
                  content: ' ';
                  height: 0;
                  width: 0;
                  position: absolute;
                  pointer-events: none;
                  border-color: rgba(234, 234, 234, 0);
                  border-width: 31px;
                  margin-top: -31px;
                  left: 0;
                  top: 50%;
                  border-left-color: #f0f0f0;
                }

                &:after {
                  border: solid transparent;
                  content: ' ';
                  height: 0;
                  width: 0;
                  position: absolute;
                  pointer-events: none;
                  border-color: rgba(255, 255, 255, 0);
                  border-width: 29px;
                  margin-top: -31px;
                  top: calc(50% + 2px);
                  left: 0;
                  border-left-color: white;
                }
              }
            }

            &:nth-child(3) {
              border-left: none;
              z-index: 2;
            }

            &:last-child {
              padding-right: 0;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              border-right: 1px solid #eaeaea;

              &:before {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(234, 234, 234, 0);
                border-width: 31px;
                margin-top: -31px;
                left: 0;
                top: 50%;
                border-left-color: #f0f0f0;
              }

              &:after {
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-width: 29px;
                margin-top: -31px;
                top: calc(50% + 2px);
                left: 0;
                border-left-color: white;
              }

              &.checked {
                position: relative;
                background: #eeeeef;

                &:before {
                  border-left-color: #fff;
                }

                &:after {
                  border-left-color: #fff;
                }
              }

              &.active {
                position: relative;
                background: #dcdddf;

                &:before {
                  border-left-color: #fff;
                }

                &:after {
                  border-left-color: #fff;
                }
              }

              &:hover {
                position: relative;
                background: #eeeeef;

                &:before {
                  border-left-color: #fff;
                }

                &:after {
                  border-left-color: #fff;
                }
              }
            }

            &.checked {
              position: relative;
              background: #eeeeef;

              &:before {
                border-left-color: #eeeeef;
              }

              &:after {
                border-left-color: #eeeeef;
              }
            }

            &.active {
              position: relative;
              background: #dcdddf;

              &:before {
                border-left-color: #dcdddf;
              }

              &:after {
                border-left-color: #dcdddf;
              }
            }

            &:hover {
              position: relative;
              background: #eeeeef;

              &:before {
                border-left-color: #eeeeef;
              }

              &:after {
                border-left-color: #eeeeef;
              }
            }

            .nav-link {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 100%;
              position: relative;
              padding-left: 45px;
              color: #242424;

              .nav-item-step {
                @include fontSize(13px);
                @include fontFamily('Liberty-MT Medium');
              }

              .nav-item-title {
                @include fontSize(10px);
                @include fontFamily('Liberty-Nus Medium');
              }
            }
          }
        }
      }
    }

    .lb-hint {
      background: #f8f8f8;
      border: 1px #bebebe dashed;
      padding: 0.625rem 1.25rem;
      @include fontSize(13px);
      margin: 1.375rem 1.875rem 0;
      border-radius: 4px;
      position: relative;

      div {
        padding: 0 0 5px 15px;

        &:before {
          content: '';
          background: #585858;
          width: 4px;
          height: 4px;
          position: absolute;
          border-radius: 50%;
          left: 20px;
          margin-top: 8px;
        }
      }
    }
  }

  .userroles-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 600px;

    .checkrole-warrning {
      transition: 0.3s;

      &:hover {
        .icn-warning {
          color: #242424;
        }
      }
    }
  }

  .lb-tooltip-reason {
    h4 {
      color: #919395;
      margin-bottom: 15px;
      @include fontSize(13px);
      @include fontFamily('Liberty-MT Medium');
      font-weight: normal;
    }

    ul {
      li {
        @include fontSize(12px);
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
      }
    }
  }
}

.lb-hint {
  background: #eff1f4;
  padding: 1.5625rem 1.25rem;
  @include fontSize(13px);
  margin: 1.375rem 1.875rem;
  border-radius: 4px;
  position: relative;

  div {
    padding: 0 0 5px 15px;

    &:before {
      content: '';
      background: #585858;
      width: 4px;
      height: 4px;
      position: absolute;
      border-radius: 50%;
      left: 20px;
    }
  }
}

.lb-user-rights {
  padding: 0 1.875rem 0;

  .lb-addnew {
    transition: 0.3s;
    cursor: pointer;
    position: absolute;
    top: -50px;
    right: 1.4%;

    &:hover {
      color: #db0011;
    }
  }

  //slider

  .lb-rights-slider {
    width: 100%;
    border-radius: 4px;
    position: relative;

    .lb-slider-prev,
    .lb-slider-next {
      position: absolute;
      // top: 50%;
      cursor: pointer;
      color: #a5a5b1;
      width: 28px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      z-index: 10;

      svg {
        width: 5px;
        height: 10px;
      }
    }

    .lb-slider-next {
      right: 0;

      &:hover {
        color: #db0011;
      }
    }

    .lb-slider-prev {
      left: 0;

      &:hover {
        color: #db0011;
      }
    }

    .lb-slider-overflow {
      background: #e6e6e6;
      width: 100%;
      height: 85px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      padding: 0 30px;

      .lb-rights-block {
        background: $white;
        border: 1px solid transparent;
        border-radius: 6px;
        width: 215px;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          border: 1px solid #ccc;

          // box-shadow: 0px 3px 8px 6px #e9e9e9;
          span {
            color: #db0011;
          }
        }

        &.active {
          border: 1px solid #969899;

          // box-shadow: 0px 3px 8px 6px #e9e9e9;
          span {
            color: #db0011;
          }
        }

        .icn {
          margin-right: 8px;

          svg {
            width: 15px;
          }
        }
      }
    }

    .slick-list {
      // overflow: visible;
      height: 85px;
      padding-top: 15px;
      width: 100%;
    }

    .slick-track {
      height: 56px;
      display: flex;
      overflow: hidden;

      .slick-slide {
        //  width: 220px;

        div:focus {
          outline: none !important;
        }
      }
    }
  }
}

//accordion
$line-color: rgba(34, 36, 38, 0.35);

.accordion {
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  overflow-y: auto;
}

.fold {
  .fold_trigger {
    &:before {
      font-family: FontAwesome;
      content: '\f107';
      display: block;
      float: left;
      padding-right: 1rem;
      transition: transform 400ms;
      transform-origin: 20%;
      color: $line-color;
    }

    text-align: start;
    width: 100%;
    padding: 1rem;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    border-bottom: 1px solid $line-color;

    &.open {
      &:before {
        transform: rotateZ(-180deg);
      }
    }
  }

  .fold_content {
    display: none;
    max-height: 0;
    opacity: 0;
    transition: max-height 400ms linear;

    &.open {
      display: block;
      max-height: 400px;
      opacity: 1;
    }
  }

  border-bottom: 1px solid $line-color;
}

.lb-check-out {
  padding: 0.625rem 1.875rem;

  .lb-select-check {
    width: 126px;
  }
}

.chek-select-all {
  .checkbox-wrapper .checkmark {
    width: 1rem;
    height: 1rem;
  }

  .checkbox-wrapper input:checked ~ .checkmark {
    background-color: $white !important;
    border-color: #242424 !important;

    svg {
      color: #242424 !important;
    }
  }

  .checkbox-wrapper .checkmark {
    border: 1px solid #242424;
  }
}

.lb-accordion {
  padding: 0 1.875rem;

  .lb-accordion-block {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 1px;

    .lb-accordion-header {
      margin-bottom: 1px;
      background-color: #e6e6e6;
      height: 40px;

      &:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
      }

      .lb-btn-accord {
        background: transparent;
        height: 40px !important;
        padding: 0 0.6875rem !important;
      }

      .icn {
        width: 12px;
        height: 12px;
      }
    }

    .checkbox-wrapper input:checked ~ .checkmark {
      border-color: #242424 !important;
      background-color: $white !important;
    }

    .lb-accordion-body {
      flex: 1 1 auto;

      .lb-checkbox-label {
        border-bottom: 1px solid #e5e5e5;
        padding: 5px 4.375rem 0;

        &.checked {
          background: #f9f9f9;
        }

        &.notchecked {
          background: #fdfdfd;
        }

        &:last-child {
          margin-bottom: 4px;
        }
      }
    }

    .checkbox-wrapper {
      @include fontSize(14px);
      @include fontFamily('Liberty-Nus Medium');

      &.check-minus {
        svg {
          //padding-top: 2px;
        }
      }

      .checkmark {
        width: 1rem;
        height: 1rem;
        border: 1px solid #242424;

        svg {
          color: #242424;
        }
      }
    }
  }
}

.lb-profile-group {
  min-height: 100px;

  .lb-input {
    height: 50px !important;
    width: 440px;

    &.lb-input-error {
      border: 1px solid #db0011 !important;
    }
  }
}

//end administer

// open banking

.lb-openbank-block {
  background-color: #f8f8f8;
  border: 2px dashed $icon-grey-bg;
  border-radius: $borderRadius;
  padding: 19px 16px;
  @include fontSize(12px);
  @include fontFamily('Liberty-Nus Medium');
  color: #242424;
  margin: 0 1.875rem 0.9375rem;

  .switch-rail {
    width: 37px;
    height: 19px;
    border-radius: 11px;

    .switch-rail-dot {
      width: 20px;
      height: 20px;
      background: $white;
      border: 1px solid #d1d1d1;
      box-shadow: 0px 1px 2px #00000040;
      top: -2px;
    }
    &.not-active {
      background: #f2f2f2;
      border: 1px solid #e5e5e5;
    }
    &.active {
      background: #db0011;
      border: 1px solid #9a121c;
    }
    &.active .switch-rail-dot {
      transform: translateX(20px);
    }
    &.not-active .switch-rail-dot {
      transform: translateX(0);
    }
  }
}

.filter-item {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.filter-manage {
  display: flex;
  align-items: center;
  margin: 5px 0 0 10px;

  .icn {
    border-radius: 50%;
    height: 1.3125rem;
    width: 1.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.icn-pen {
      margin-left: 0;

      svg {
        height: 12px;
        width: 15px;
        position: relative;
        right: -3px;
      }
    }

    &.icn-trash {
      margin-left: 0;

      svg {
        height: 11px;
        width: 9px;
      }
    }
  }

  .icn-dark-circle {
    background: #e3e3e3;
    border-radius: 50%;
    color: #242424;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;

    svg {
      width: 10px;
      height: 10px;
    }

    &:hover {
      background: #242424;

      svg {
        color: #fff;
      }
    }
  }

  div {
    &:hover {
      .icn-dark-circle {
        background: #242424;

        svg {
          color: #fff;
        }
      }
    }
  }
}

/*popup*/

.icn-warning-new {
  &:hover {
    color: $nav-red;
  }
}

// .lb-popup-row:not(:last-child) {
//   border-bottom: none !important;
// }

.lb-grid {
  padding: 22px 7px;

  .lb-block-subhead {
    @include fontSize(13px);
    font-family: 'Liberty-MT Medium';
    color: $black;
    padding: 0.375rem 1.875rem;

    & > :last-child {
      padding-right: 22px;
    }
  }

  &.history-grid {
    .lb-block-row {
      margin-bottom: 15px;
    }
  }

  .lb-block-row {
    background: #efefef;
    border-radius: 4px;
    margin-bottom: 2px;
    margin: 0 15px 2px 15px;
    padding: 1rem 0.9375rem;

    .is-title {
      @include fontSize(13px);
      color: #242424;
      font-family: 'Liberty-Nus Medium';
    }

    .is-name {
      @include fontSize(13px);
      color: #242424;
    }

    .is-subname {
      @include fontSize(13px);
      color: #bfbfbf;
    }

    .is-subtext {
      @include fontSize(13px);
      color: #242424;
      font-family: 'Liberty-Nus Bold';
    }

    .is-amount {
      @include fontSize(13px);
      color: #242424;
      font-family: 'Liberty-MT Medium';

      span {
        font-family: 'Liberty-MT Medium';
      }
    }

    &:not(.p-0) > :nth-last-child(2) {
      padding-right: 22px;
    }

    .icn-grey-circle {
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background: #242424;
      border-radius: 50%;
      color: $white;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      transition: 0.3s;

      svg {
        width: 5px;
        height: 5px;
      }
    }

    &:hover {
      background: #efefef;

      .icn-grey-circle {
        background: #db0011;
        color: #fff;
      }
    }
  }
}

.lb-popup-footer {
  .lb-trans-info {
    color: #242424;
    transition: 0.3s;

    &:hover {
      .icn-create-copy {
        color: $nav-red;
      }

      span {
        color: $nav-red;
      }

      .icn-link {
        span {
          color: #242424;
        }
      }
    }
  }

  .canceled-reason {
    width: 100%;
    max-width: 314px;

    .lb-input-grey {
      background: #edeef3;
      height: 45px;
      @include fontSize(13px);
    }

    span {
      color: #242424;
    }
  }

  .details-canceled-reason {
    width: 100%;
    max-width: 515px;

    .lb-input-grey {
      background: #edeef3;
      height: 45px;
      @include fontSize(13px);
    }

    span {
      color: #242424;
    }
  }

  .icn.icn-bg {
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $icon-grey-bg;
    transition: 0.3s;
    cursor: pointer;

    &.icn-pen {
      color: #242424;
      margin-left: 0;

      svg {
        height: 12px;
        width: 15px;
        position: relative;
        right: -3px;
      }
    }

    &.icn-trash {
      color: #242424;
      margin-left: 0;

      svg {
        height: 11px;
        width: 9px;
      }
    }
  }

  .icn-link {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      .icn.icn-bg {
        background: $icn-dark;
        color: $white;
      }
    }
  }
}

.transaction-details-links {
  padding: 2rem 1.8125rem 0 1.8125rem;
  color: #242424;
  @include fontSize(14px);
  @include fontFamily('Liberty-Nus Medium');

  .btn-link {
    margin-right: 7.8%;

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: right;
      margin-right: 0;
    }

    span {
      cursor: pointer;

      &:hover {
        color: #db0011;
      }
    }
  }
}

.transaction-details-warning {
  background: $white;
  border: 2px dashed #e6e6e6;
  border-radius: 4px;
  padding: 10px 15px;
  //max-width: 415px;
  width: auto;
  margin: 0.3125rem 1.8125rem;
}

.lb-popup-slider {
  position: relative;
  overflow: inherit;

  .slider-block {
    min-height: 300px;
  }

  .lb-block {
    margin-bottom: 0;
  }

  .lb-slider-prev,
  .lb-slider-next {
    position: absolute;
    top: 40%;
    cursor: pointer;
    background: #e3e3e3;
    border-radius: 50%;
    color: #fff;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    &:not(.disabled):hover {
      background: $nav-red;

      svg {
        color: $white;
      }
    }

    svg {
      max-width: 0.625rem;
      max-height: 50%;
      position: relative;
      bottom: -1px;
      color: #242424;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  .lb-slider-prev {
    transform: translateY(-50%) rotate(90deg);
    right: 97.5%;
  }

  .lb-slider-next {
    transform: translateY(-50%) rotate(-90deg);
    left: 97.5%;
  }
}

.lb-passw-block {
  //padding: 1.5625rem 1.25rem 1.5625rem;

  &.addnew-password {
    padding: 1.5625rem 1.875rem 0;
  }

  .lb-passw-hint {
    padding: 0 0;
    @include fontSize(12px);
    color: $icn-dark;
    border-radius: 4px;
    position: relative;

    div {
      padding: 0 0 5px 15px;

      &:before {
        content: '';
        background: #585858;
        width: 4px;
        height: 4px;
        position: absolute;
        border-radius: 50%;
        left: 0;
      }
    }
  }

  .lb-input-passw {
    max-width: 139px;
    width: 100%;
    height: 46px;
    background: #ececec;
    border: 1px dashed #d5d5d5;
    color: #db0011 !important;
    @include fontSize(16px);
    @include fontFamily('Liberty-Nus Medium');

    &::placeholder {
      color: #db0011;
    }

    &:focus {
      color: #db0011 !important;
    }
  }

  .lb-select-out {
    max-width: 300px;
    width: 100%;

    .lb-select-numbrcode {
      width: 80px;

      .Select-control {
        height: 40px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #d9d9e6;
      }

      .Select-placeholder {
        @include fontSize(12px);
        @include fontFamily('Liberty-Nus');
        color: $icn-dark;
      }

      .Select-arrow-zone .icn-arrow-down {
        width: 10px;
        top: 0;
        color: #bebebe;
      }
    }

    .lb-phone-number {
      width: 215px;
      height: 40px;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &::placeholder {
        color: #585858;
      }
    }
  }
}

.user-details {
  padding: 1.875rem;

  .user-details-row {
    width: 100%;

    .user-details-col-group {
      display: flex;
      align-items: flex-start;
      padding-bottom: 0.625rem;

      .user-details-col-lable {
        @include fontSize(14px);
        @include fontFamily('Liberty-Nus Medium');
      }

      .user-details-col {
        @include fontSize(13px);
        @include fontFamily('Liberty-Nus Medium');
      }
    }
  }
}

.reset-password {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: $nav-red;
  }
}

.lb-passw-block {
  .lb-select-numbrcode {
    .Select-control {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    .Select-menu-outer {
      max-height: 100px !important;
    }

    .Select-menu {
      max-height: 100px !important;
    }

    .select-option {
      min-height: 10px !important;
    }
  }

  .lb-phone {
    .lb-phone-number {
      border-left: 1px solid #d9d9e6 !important;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;

      &.has-error {
        border-left: 1px solid #db0011 !important;
      }
    }
  }
}

/*popup end*/

.table-management {
  padding: 1.25rem 1.5625rem 1.875rem;
  background: $bg-modal;

  h3 {
    @include fontFamily('Liberty-MT Medium');
    text-align: center;
    margin-bottom: 5px;
  }

  .table-manage {
    width: 250px;
    position: relative;

    &.table-manage-left {
      width: 264px;

      .table-manage-tbl {
        border: 1px dashed $bg-drag-row;
        padding-right: 10px;

        .table-manage-row {
          .rotated-element {
            background: $bg-drag-row;
            color: $black;
            @include fontSize(12px);
          }

          &.is-dragging {
            .rotated-element {
              transform: rotate(2deg);
            }
          }
        }
      }
    }

    &.table-manage-right {
      .table-manage-tbl {
        border: 1px solid $bg-drag-row;

        .amount-row {
          background: $nav-red;
          color: $white;
          min-height: 30px;
          border-radius: $borderRadius;
          padding: 0.28rem 0.7rem;
        }

        .table-manage-row {
          .rotated-element {
            background: $nav-red;
            color: $white;
            @include fontSize(12px);
          }

          &.is-dragging {
            .rotated-element {
              transform: rotate(-2deg);
            }
          }
        }
      }
    }

    .table-manage-tbl {
      padding: 1rem 0.875rem;
      border-radius: $borderRadius;
      height: 216px;
      overflow: hidden;

      .lb-scrollbar {
        .table-manage-row {
          margin-right: 20px;
        }
      }

      .amount-row-out {
        height: 250px;
      }

      .table-manage-row {
        user-select: none;
        outline: none;
        margin-bottom: 8px;

        .rotated-element {
          min-height: 30px;
          border-radius: $borderRadius;
          padding: 0.28rem 0.6rem;

          .icn-close {
            height: 6px;
            width: 6px;
            color: $white;
            opacity: 0;
            transition: 0.2s;
          }

          &:hover {
            .icn-close {
              opacity: 1;
            }
          }
        }

        &.translate {
          transition: transform 0.2s;
          transform: translateY(38px);
        }
      }
    }
  }

  .table-manage-icon {
    align-self: center;
    text-align: center;
    opacity: 0.3;

    .icn-drag {
      width: 47px;
      height: 47px;
    }
  }

  .lb-btn:not(.btn-link) {
    height: 40px;
  }
}

/*Batch transfer*/

.corp-batch-transfer {
  .lb-block-filter {
    .icn.icn-file {
      width: 26px;
      height: 30px;
    }
  }

  .icn-warning-new {
    svg {
      width: 16px;
    }
  }

  .batch-details-col-group {
    margin-bottom: 8px;

    .batch-details-col-lable {
      color: #969899;
      @include fontSize(13px);
      @include fontFamily('Liberty-Nus Medium');
      width: 200px;
    }

    .batch-details-col {
      color: black;
      @include fontSize(14px);
      @include fontFamily('Liberty-Nus Medium');
    }
  }

  .sms-input {
    padding: 0;
  }

  .lb-input-reason {
    width: 500px;
  }

  .lb-list-type {
    div {
      position: relative;
      padding-left: 10px;
      margin: 8px 0;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 5px;
        height: 5px;
        background: $black;
        border-radius: 50%;
      }
    }
  }

  .lb-upload-block {
    padding: 26px;

    .lb-input {
      height: 2.5rem;
    }

    .lb-select {
      height: 2.5rem !important;
    }

    .lb-upload-file {
      width: 940px;

      min-height: 557px;
      background: #f5f5f5;
      padding: 35px 30px;
      position: relative;

      .upload-box {
        background: #fff;
        border: 2px dashed #e6e6e6;
        border-radius: 4px;
        height: 40px;
      }

      .lb-input-upload {
        .lb-input {
          width: 205px;
        }

        i.icn-close {
          right: 0.9375rem;
          width: 10px;
          height: 10px;
          font-size: 0;
        }
      }

      .lb-input-out {
        .lb-input {
          padding-right: 25px;
          width: 205px;

          &::-webkit-input-placeholder {
            @include fontSize(12px);
          }

          &::-moz-placeholder {
            @include fontSize(12px);
          }

          &:-ms-input-placeholder {
            @include fontSize(12px);
          }

          &:-moz-placeholder {
            @include fontSize(12px);
          }
        }

        i.icn-close {
          right: 0.9375rem;
          width: 10px;
          height: 10px;
          font-size: 0;

          &:hover {
            color: #db0011;
          }
        }
      }

      .icn-upload-block {
        background: $white;
        border: 1px dashed #d9d9e6;
        padding: 9px 5px;
        border-radius: 4px;
        width: 210px;
        transition: 0.3s;
        justify-content: center;
        margin-left: 10px;

        &:hover {
          color: $nav-red;
        }

        .icn-upload {
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .lb-form-group {
        &:first-child {
          width: 48%;
        }

        &:nth-child(2) {
          width: 48%;
        }

        .lb-input {
          line-height: 40px;
        }
      }

      .format-file {
        background: #fff;
        border: 2px dashed #e6e6e6;
        border-radius: 4px;
        padding: 15px;
        width: 100%;
      }

      .lb-change-block {
        .lb-btn-change {
          margin-top: -7px;
        }

        .icn.icn-upload {
          width: 18px;
          height: 17px;
        }
      }

      .lb-group-date {
        width: 43.3% !important;

        .icn-calendar {
          transition: 0.3s;
          cursor: pointer;

          &:hover {
            color: #db0011;
          }
        }

        .lb-datepicker:disabled,
        .lb-datepicker.filled:disabled {
          color: #5d6163 !important;
        }
      }

      .upload-btn {
        position: absolute;
        bottom: 36px;
        right: 51px;
      }

      .lb-input-grey {
        background: #fff !important;
        border: 1px solid #d9d9e6;
      }

      .lb-select .disabled:hover {
        .template-details {
          opacity: 1;
        }
      }
    }

    .lb-upload-resources {
      padding: 30px 20px 30px 40px;
      width: 500px;

      .file-list-block {
        .file-list {
          padding-bottom: 25px;
          cursor: pointer;

          .title-xml {
            &:hover {
              color: #db0011;
            }
          }

          .icn.icn-xls {
            height: 19px;
          }
        }
      }

      .loader-file {
        position: absolute;
        left: -2px;
      }
    }
  }

  .lb-toolbar {
    .checkbox-wrapper:not(:last-child) {
      margin-right: 2rem;
    }
  }

  .lb-input-search {
    .lb-input {
      padding-right: 2.375rem;
    }
  }

  // file upload progress
  .progress-bar {
    &__track {
      width: 124px;
      height: 4px;
      background-color: #d9d9e6;
      position: relative;
      border-radius: 2px;
    }

    &__bar {
      height: 4px;
      background-color: $nav-red;
      position: absolute;
      border-radius: 2px;
    }
  }
}

// popup edit fileds

.lb-edit-block {
  padding: 10px 22px 0;

  + .lb-popup-footer {
    padding-top: 0;
  }

  .lb-note {
    background: #efefef;
    border: 2px dashed #bcbcbc;
    border-radius: 4px;
    padding: 7px 22px 0;
    width: 100%;
  }

  .lb-select.product-select .Select-value {
    padding: 0 2rem 0 1rem !important;
  }

  .country-select,
  .bank-select {
    &.is-focused,
    &.has-value {
      background: #fff;
      border: 1px solid #d9d9e6;
    }

    .Select-arrow-zone .icn-arrow-down {
      color: #717073;
    }

    .Select-control .Select-value .Select-value-label {
      padding: 0 10px;
    }
  }

  .lb-select.bank-select .Select-menu-outer {
    z-index: 9;
  }

  .lb-select.bank-select .Select-menu-outer {
    z-index: 9;
  }

  .lb-form-group {
    width: 22%;
    height: 70px;

    .lb-input {
      background: #fff;
      border: 1px solid #d9d9e6;
      height: 45px;
      line-height: 45px;
      padding: 0 1.25rem 0 0.5625rem;

      &.lb-input-error {
        border: 1px solid #db0011;
      }
    }

    &:first-child,
    &:nth-child(2) {
      width: 50%;
    }

    &:first-child {
      margin-right: 3%;
    }

    .type-select {
      .Select-control {
        border: none;
      }

      &.focus-red {
        height: 45px;
      }

      .Select-input {
        input {
          padding: 9px 0;
        }
      }
    }

    .lb-select {
      height: 45px;
    }
  }

  .lb-table-trans {
    border-collapse: separate;

    .lb-table-tr {
      @include fontSize(12px);
      color: #242424;
      border-top: 1px solid #707070;
      border-bottom: none;
      border-left: 1px solid #707070;
      border-right: 1px solid #707070;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      &:last-child {
        border-bottom: 1px solid #707070;
      }

      .lb-table-td {
        &:last-child {
          border-left: none;
          border-right: none;
        }

        &:first-child {
          border-left: none;
          border-right: none;
        }

        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
        padding: 8px 13px;
      }
    }
  }

  .amount-block {
    .lb-form-group {
      width: 48.2%;
    }

    .lb-input-out {
      .lb-input {
        width: 165px;
      }
    }

    .lb-amount-block {
      margin: -25px 0 0 23px;

      & > div {
        margin-bottom: 0;
      }

      .radio-label {
        &:first-child {
          padding-right: 20px;
        }
      }

      .currency-info-block {
        // position: absolute;
        // top: 80px;
        // left: 0;
        // width: 300px;
        // transform: translateY(50% 50%);
        width: 160px;
        margin-top: -5px;

        & > div {
          span:first-child {
            display: block;
          }
        }
      }
    }
  }
}

.edit-block-modal {
  .loader-out {
    &.full-size {
      z-index: 7;
    }
  }

  .country-select {
    &.lb-select.is-open:not(.no-border) .Select-control {
      border-top: none !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
    }
  }
}

//exchange-page

.exchange-page.container {
  // .corp-row {
  //   .lb-block-row {
  //     &.custom-row {
  //       &:after {
  //         content: none;
  //       }
  //     }
  //     &:nth-child(2):after {
  //       content: '';
  //       position: absolute;
  //       bottom: 0;
  //       height: 1px;
  //       left: 2.1875rem;
  //       width: calc(100% - 2.1875rem * 2);
  //       background: $border-grey-4;
  //     }

  //     &:nth-child(2):after {
  //       content: none !important;
  //     }

  //     &:hover,
  //     &.active {
  //       background: none;
  //     }
  //   }
  // }
  .chart-wrap {
    padding: 5vh 1.875rem 10vh;

    &.chart-block {
      padding: 0;
    }

    .recharts-surface {
      padding: 0 1.25rem 1.5rem 0;
      box-sizing: content-box;
    }

    .axis-x-label {
      @include fontFamily('Liberty-MT Medium');
      @include fontSize(13px);
      text-align: center;
    }

    .axis-y-label {
      @include fontFamily('Liberty-MT Medium');
      font-size: 1.1rem;
    }

    .chart-tooltip {
      background: rgba(29, 38, 47, 0.85);
      border-radius: $borderRadius;
      color: $white;
      @include fontSize(13px);
      padding: 0.5rem 0.8rem;
    }

    circle {
      fill: rgba(29, 38, 47, 0.85) !important;
      width: 10px;
      height: 10px;
      stroke-width: 0;
    }

    .chart-filters {
      .lb-select {
        height: 2.8125rem !important;
      }
    }
  }
}

// My Functions dashboard

.my-functions {
  .lb-block-row {
    //padding: 1rem !important;
    border-left: 2px solid transparent;

    .icn {
      width: 40px;
    }

    &:hover {
      background: rgba(243, 243, 243, 0.44);
      border-left: 2px solid #db0011;
      color: #db0011;
    }

    .lb-notification {
      background: #db0011;
      color: $white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      text-align: center;
      padding-top: 3px;
      padding-right: 0 !important;
    }
  }

  .all-myfunctions {
    span {
      @include animatedUnderline(1px, $nav-red, 0);
    }

    .icn.icn-arr-right {
      height: 10px;
    }

    &:hover {
      color: #db0011;

      .icn.icn-arr-right {
        color: #db0011;
      }
    }
  }
}

.current-tasks-slider {
  .lb-slider-prev,
  .lb-slider-next {
    z-index: 5;
  }

  .lb-slider-prev,
  .lb-slider-next {
    svg {
      max-width: 0.625rem;
      max-height: 50%;
      position: relative;
      bottom: -1px;
    }
  }

  .lb-tooltip {
    max-width: 400px;
  }
}

// corporate mobile

.corp-mobile {
  color: #000;
  @include fontSize(16px);
  @include fontFamily('Liberty-Nus Medium');

  .empty-mobile-block {
    padding: 50px;

    .empty-mobile {
      border: 1px dashed #dfdfdf;
      width: 100%;
      min-height: 400px;
      padding-top: 35px;
    }

    .empty-mobile-bg {
      background-position: center center;
      background-repeat: no-repeat;
      height: 350px;

      .lb-btn {
        &:hover {
          .icn.icn-red-circle {
            svg {
              color: $white;
            }
          }

          span {
            color: $white;
          }
        }
      }
    }

    .template-add-box {
      @include fontSize(14px);
      color: #000;
      outline: none;
      @include fontFamily('Liberty-MT Medium');
      transition: 0.3s;
      width: 100% !important;
      cursor: pointer;

      .icn {
        margin-right: 2px;
        margin-top: 4px;
      }

      .icn-red-circle {
        svg {
          width: 10px;
          height: 10px;
        }
      }

      &:hover {
        color: $icon-red;

        svg {
          color: $icon-red;
        }
      }
    }
  }

  .loader-mobile {
    .loader-out {
      left: 83%;
      background: transparent;
    }
  }

  .option-provider-select {
    .Select-value {
      @include fontSize(12px);

      .Select-value-label {
        & > div {
          padding-right: 8px;
        }
      }
    }

    .Select-menu {
      @include fontSize(12px);
    }

    .select-option {
      min-height: 30px !important;
    }
  }

  .utility-block {
    .utility-input-out {
      .lb-input-grey {
        height: 36px !important;
      }

      .symbol-l {
        top: 10px !important;
      }

      .icn-close {
        height: 36px !important;
      }
    }

    .utility-block-row {
      .utility-row-info {
        max-width: 324px;
      }

      .w-315 {
        width: 315px;
      }
    }
  }
}

.mobil-row-info {
  max-width: 324px !important;
}

@media screen and (max-width: 1366px) {
  .corp-mobile {
    .utility-block {
      .utility-block-row {
        .utility-row-info {
          max-width: 260px;
          //  margin-right: 20px;
        }
      }
    }
  }
}

.add-newnumber {
  padding: 25px 25px 0 25px;

  .enter-number {
    &.lb-input-err {
      margin-top: -14px;
      margin-bottom: 10px;
    }
  }

  .lb-form-group {
    margin-bottom: 1rem;

    .lb-label {
      @include fontFamily('Liberty-Nus Medium');
    }
  }

  .lb-select {
    height: 3.125rem;
  }

  .service-select-out {
    width: 100%;
    max-width: 220px;
  }

  .lb-input {
    &.lb-input-error {
      border: 1px solid $nav-red !important;

      & + .lb-input-err {
        display: block;
        color: $nav-red;
      }
    }
  }
}

// lb-step wizard

.lb-steps-head {
  justify-content: center !important;
}

.lb-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a3a3a3;

  li {
    display: flex;
    align-items: center;
    font-family: 'Liberty-Nus Medium';
    @include fontSize(13px);
    transition: 0.3s;

    &:after {
      content: '';
      height: 1px;
      width: 120px;
      background: #a5a5a5;
    }

    .icn-step {
      color: #a5a5a5;
      margin: 0 15px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    span {
      padding-right: 15px;
    }
  }

  .is-checked {
    cursor: pointer;

    &:after {
      background: #242424;
    }

    .icn-step {
      color: #242424;
    }

    span {
      color: #242424;
    }

    &:hover {
      .icn-step {
        color: #db0011;
      }

      span {
        color: #db0011;
      }
    }
  }

  .is-active {
    .icn-step {
      color: #db0011;
    }

    span {
      color: #db0011;
    }
  }
}

//future transfer

.future-block {
  .lb-form-group {
    margin-bottom: 0;
  }

  .future-text {
    margin-top: auto;
    height: 45px;
    color: #e3e3e3;
    text-align: left;
  }

  .icn.icn-calendar {
    width: 19px;
    height: 17px;
  }

  .medium-grey {
    color: $white !important;
  }

  .lb-group-date {
    .lb-datepicker.lb-input {
      max-width: 208px;
      width: 100%;
    }
  }

  .future-date {
    margin-left: 30px;
    position: relative;
    width: 100%;

    .lb-group-date {
      position: absolute;
      left: 0;
      top: -42px;
      width: 100%;
    }

    .lb-datepicker.lb-input {
      max-width: 236px;
      width: 100%;
    }
  }
}

//progress circle bar

.progrees-c {
  border: 2px dashed #e6e6e6;
  background-color: $white;
  padding: 0.8125rem 1.25rem;
  margin: 2.1875rem 1.125rem;

  svg {
    width: 48px;
    height: 48px;
  }
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #f2d2d5;
}

.circle-progress {
  stroke: #db0011;
  stroke-linecap: round;
  stroke-linejoin: round;
}

//salary transfer

.calc-block {
  .lb-form-group {
    width: auto !important;
    margin-bottom: 20px !important;

    &:nth-child(2) {
      margin-right: 0 !important;
    }
  }
}

.payroll-details {
  .batch-details-col-group {
    .batch-details-col-lable {
      width: 280px;
    }
  }

  .warrning-block {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.lb-payroll-block {
  .filter-details {
    .lb-form-group:first-child {
      width: auto !important;
    }
  }
  .lb-btn:not(.btn-link) {
    height: 40px !important;
  }

  .lb-form-group {
    width: auto !important;
  }

  .lb-total-amount {
    width: 400px;
  }

  .lb-total-commission {
    width: 400px;
  }

  .amount-sum {
    .batch-details-col {
      font-size: 25px;
      font-family: 'Liberty-Nus Bold';
    }
  }
  .batch-detail-left {
    .batch-details-col {
      .icn {
        width: 13px;
        height: 13px;
      }
    }
  }
  .batch-detail-right {
    .batch-details-col-group {
      margin-bottom: 15px;

      .lb-input {
        height: 40px !important;
        color: #242424 !important;

        &.lb-input-fill {
          background-color: #ececec;
        }
      }
    }
    .lb-input-disable {
      opacity: 0.5;
    }
  }
}

.lb-employee-filter {
  .datepicker-out {
    .lb-datepicker {
      &.lb-input {
        width: 108px;
      }
    }
  }
}

.payroll-footer {
  .btn-wide-35 {
    margin-right: 0 !important;
  }
}

.lb-project-filter {
  .datepicker-out {
    .lb-datepicker {
      &.lb-input {
        width: 108px;
      }
    }
  }
}
.lb-project-toolbar {
  padding: 20px !important;
  .icn.icn-dark-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
    }
    &.icn-trash {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
  .lb-employee-info {
    background: #fafafa;
    border: 2px dashed #e5e5e5;
    border-radius: 4px;
    padding: 10px 15px;
    width: auto;
  }
}
.lb-employee-toolbar {
  padding: 20px !important;
  .icn.icn-dark-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
    }
    &.icn-trash {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  .loader-container {
    min-height: auto !important;
  }
}

.lb-employee-info {
  background: #fafafa;
  border: 2px dashed #e5e5e5;
  border-radius: 4px;
  padding: 0 15px 6px;
  width: 320px;
}

.lb-employee-add {
  padding: 30px 45px 0;
  .lb-form-group-out {
    .lb-form-group {
      width: 31% !important;
      &:nth-child(3) {
        width: 31% !important;
      }
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  .nowrap {
    white-space: normal !important;
    flex: 1 1;
  }

  .lb-btn.btn-white {
    height: 45px !important;
  }
}

.lb-appsend-form {
  .lb-form-group {
    width: 100% !important;
  }
}

// media

@media (min-width: 1280px) and (max-height: 650px) {
  .edit-block-modal {
    .lb-edit-block {
      overflow-y: scroll;
      height: 450px;
    }
  }

  .corp-batch-transfer {
    .lb-block-row.block-pointer > :nth-last-child(2) {
      // padding-right: 22px;
    }
  }

  .payroll-transfer .corp-batch-transfer {
    .lb-block-row.block-pointer > :nth-last-child(2) {
      padding-right: 35px;
    }
  }
}

@media (max-width: 1280px) {
  .corp-batch-transfer {
    .lb-block-row.block-pointer > :nth-last-child(2) {
      padding-right: 22px;
    }
  }
}
