.lb-header-dashboard:not(.template-panel) {
  .widget-item:not(.icn-add) .icn-widget {
    &:hover {
      //color: #C61E3F;
      background: #fff;
      border: 1px solid $icon-red;

      svg {
        // hide border (background) on hover
        //transform: scale(1.05);
      }
    }
  }

  .pay-abbr {
    transition: 0.1s;
    &:hover {
      color: #c61e3f;
      background: #fff;
      // border: 2px solid #c9213f;
      box-shadow: 0px 0 0px 0.05px #fff;
    }
  }
}

// download link hover
.dwn-hover {
  transition: 0.2s;

  &:hover {
    color: $icon-red;

    i {
      color: $icon-red;
    }
  }
  .loader-out {
    &.full-size {
      left: inherit;
    }
  }
}

.lb-popup-row.pointer {
  transition: 0.2s;

  &:hover {
    background: rgba(243, 243, 243, 0.44);

    .icn-arr-right,
    .arrow-right {
      color: $light-red !important;
    }
  }
}

// link hovers
.link-default {
  cursor: pointer;
  display: inline-flex !important;
  align-items: center;

  &:not(.link-back) {
    @include animatedUnderline(1px, $nav-red);
  }

  &.link-back {
    @include animatedUnderline(1px, $nav-red, 0);
  }

  .icn {
    transition: 0.3s;
  }

  &:hover {
    color: $nav-red;

    .icn {
      color: $nav-red;
    }
  }
}

.link-line {
  cursor: pointer;
  @include animatedUnderlineOut(1px, currentColor);
}

// .link-line{
//     text-decoration: underline;
//     transition: 0.3s;
//     color: $white;

//     &:hover{
//         text-decoration: none;
//     }
// }
// link hovers end

// footer
.helpfull-links .pointer {
  transition: 0.3s;

  &:hover {
    color: $nav-red;
  }
}

.lb-lang-switcher li {
  transition: 0.3s;

  &:hover {
    color: $nav-red;
  }
}
// footer end

// select star hover

.lb-select {
  .can-select-favorites {
    .toggle-favorite {
      transition: 0.3s;

      &:hover {
        color: $icon-red !important;
      }
    }
  }
}
.details-block {
  .lb-select {
    .toggle-favorite {
      transition: 0.3s;

      &:hover {
        color: #242424 !important;
      }
    }
  }
}
// select star hover end

// settings page
.set-trigger.link-line {
  transition: 0.3s;

  &:hover {
    color: $light-red;
  }
}

//  utility page
.template-add-box {
  transition: color 0.3s;

  &:hover {
    opacity: color 0.7;
  }
}

.utility-input-out {
  .icn-close {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: $icon-red;
    }
  }
}
//  utility page end

.calendar-arrow {
  transition: 0.2s;

  &:hover {
    //opacity: 0.7;
    color: $icon-red;
  }
}

// templates
.template-btn {
  .icn {
    transition: 0.2s;

    &:hover {
      background: $icon-red;
    }
  }
}
